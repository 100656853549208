import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';

import { filterBy } from '@progress/kendo-data-query';
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
  } from "@progress/kendo-react-dropdowns";
import Loading from './loading';
import Header  from './header';
import AstByMrktCapitalGrid from './astByMrktCapitalGrid';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
const AstByMrktCapitalRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem('token'));
    const [astByMrktCapitalData, setAstByMrktCapitalData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const[flagAst, setflagAst] = React.useState(false);
    const [isColumnSave, setIsColumnSave] = useState(false);
    const [session,setSession]=useState("");
    
    const [reportData, setReportData] = useState([]);
    const [enableCombo,setEnableCombo]=useState(false);
    const navigate = useNavigate();
    useEffect(() => {
      
        const fetchData = async () => {
             setLoading(true);
             setEnableCombo(true);
            try {

                GetAstByMrktCapitalData();
                getColumnStateDb();
                
            } catch (error) {
                console.error(error.message);
            }
    
        }
        fetchData();
    }, [])
    const refreshToken = async () => {
      let token = JSON.parse(localStorage.getItem('token'));
      let AccessToken = token.token;
      let RefreshToken = token.refreshToken;
      const postData = { AccessToken, RefreshToken };
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      await axios.post('/token/Refresh',
        postData
  
      )
        .then((response) => {
          
          tempToken = response.data;
          localStorage.setItem('token', JSON.stringify(response.data));
  
          GetAstByMrktCapitalData();
  
  
        })
        .catch((error) => {
          // 
          if(error.code==="ERR_BAD_REQUEST"){
            setSession("Session Expired");
          }
          console.log("my error is " + error);
        })
  
    }

    const GetAstByMrktCapitalData = async () => {
        
        setLoading(true);
        setEnableCombo(true);
        let token=tempToken;

         //let token = JSON.parse(localStorage.getItem('token'));
         let AsOfId = JSON.parse(localStorage.getItem('userId'));
         let AcctId= JSON.parse(localStorage.getItem('AcctSelected')).acctId;
         let PageId = 1;
         const postData = {AsOfId,AcctId, PageId};
         const config = {
            headers: {
              'authorization': `Bearer ${token.token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          
      };
        await axios.post('/RTAssetsMarketCapital',
            postData,
           config
        )
            .then(response => {
              
            //    const rowData = Enumerable.from(response.data.ocPortFolioHoldingsMainOutPut).where(w => w.totMarket !== 0)
            //     .toArray();
    
                const rowData = response.data;
                setAstByMrktCapitalData(rowData.t1);
                setReportData(rowData)
                getColumnStateDb();
                setflagAst(true);
                setLoading(false);
                setEnableCombo(false);
      
            })
            .catch((error) => {
              if (error.response.status === 401) {
                refreshToken();
      
              }
            });
      
      }
      const filterData = (filter) => {
   
        // const dataAcct = selAcctData.slice();
         return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
       };
      const filterChange = (event) => {
     
        setSelAcctData(filterData(event.filter));
      };
      const handleChange = (event) => {
        
          if (event.target.value === null) {
            SetselAcct(JSON.parse(localStorage.getItem('acctData'))[0]);
            //GetUpdatedAccountProfile(0);
            localStorage.setItem('IsAcctSelected', false);
            localStorage.setItem('AcctSelected',JSON.stringify(JSON.parse(localStorage.getItem('acctData'))[0]));
            }
          else {
            SetselAcct(event.target.value);
            localStorage.setItem('IsAcctSelected', true);
            localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));
            console.log(event.target.value);
            GetAstByMrktCapitalData();
            //GetUpdatedAccountProfile(event.target.value.acctId);
          }
            
        };
        const getColumnStateDb = async () =>{
          //Storing column settings in DB
          
            let token = JSON.parse(localStorage.getItem('token'));
            let UserId = JSON.parse(localStorage.getItem('userId'));
            let GridId = 27;
            
            const postData = {UserId, GridId};
            const config = {
              headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
          
            };
            await axios.post('/RTGetGridColumn/Index',
              postData,
              config
            )
              .then(response => {
                
                console.log('save in db');
                console.log(response);
                  const rowData = response.data;
                  
                  if(rowData!==""){
                    setIsColumnSave(true);
                    localStorage.setItem('gridColumns', rowData);
                  }
                  else{
                    setIsColumnSave(false);
                  }
              })
              .catch((error) => {
                console.log('error in save db '+error);
                
                  return error;
              });
          }
          const signOut = () => {
    
            navigate("/");
            let token = JSON.parse(localStorage.getItem('token'));
            const postData = {};
            const config = {
              headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              }
        
            };
            axios.post('/token/revoke',
            postData,
            config
            )
              .then((response) => {
              // 
              // localStorage.setItem('token', '');
               //console.log(response);
              // navigate("/");
                  
                // if (response.statusText === '') {
                 
        
        
                // }
        
               
              })
              .catch((error) => {
                // 
                console.log("my error is " + error);
              })
        
              // let tokenNew={token:''};
              // localStorage.setItem('token',JSON.stringify(tokenNew));
              // localStorage.setItem("AcctSelected",null);
              localStorage.removeItem('token');
              localStorage.clear();
            //firebaseApp.auth.signOut();
        
          }
        
        if (loading) {
  
          return(
            <>
          {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
              <Header></Header>
              <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                <div className='subheader text-end col-md-1'> &nbsp; Account(s):</div>
                <div className='col-md-4 text-start'>
                  <ComboBox
                    style={{
                      width: "350px",
                    }}
                    data={selAcctData}
                    textField="extrnlAcctId"
                    dataItemKey="acctId"
                    filterable={true}
                    value={selAcct}
                    onChange={handleChange}
                    onFilterChange={filterChange}
                    disabled={enableCombo}
                  />
                </div>
                {/* <div className='col-md-2'>
                  <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
                </div> */}
                </div>
            <Loading />
            </div>}</>
          )
         
       
      
      }
        return (
            <div>
              <Header></Header>
              <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                    <ComboBox
                        style={{
                            width: "350px",
                        }}
                        data={selAcctData}
                        textField="extrnlAcctId"
                        dataItemKey="acctId"
                        filterable={true}
                        value={selAcct}
                        onChange={handleChange}
                        onFilterChange={filterChange}
                        disabled={enableCombo}
                    />
                </div>  
                </div>
                
                <div>
                {flagAst?<AstByMrktCapitalGrid data={astByMrktCapitalData} reportData= {reportData} flag={isColumnSave} accntSelected={selAcct}/>:<></> }
                </div>
              
            </div>
          )
}
export default AstByMrktCapitalRpt;