import React from 'react';
import { useState, useEffect, useRef } from 'react';
import "@grapecity/activereports/styles/ar-js-ui.css";
import "@grapecity/activereports/styles/ar-js-designer.css";
import axios from 'axios';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import Moment from 'react-moment';
import { formatNumber, formatDate, parseNumber } from '@telerik/kendo-intl';
import { Designer, Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
import { PageReport } from "@grapecity/activereports/core";
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { Button, DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { CustomColumnMenuNoGrpChkBox } from './customColumnMenuNoGrpChkBox';
import { CustomColumnMenu } from './customColumnMenu';
import {exportDocument as pdfExport } from "@grapecity/activereports/pdfexport";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";

import { textAlign } from '@mui/system';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { GridPDFExport,PDFExport } from "@progress/kendo-react-pdf";
import Enumerable from 'linq';
import Modal from 'react-bootstrap/Modal';
import BankLogoPage from './bankLogoPage';
let gridDtata="";
const PageTemplate = (props) => {
  return (
      <div>
          <div
              style={{
                  position: "absolute",
                  top: "1px",

                  width:"98%",
                  borderBottom: "1px solid #bce8f1"
                 
              }}
          >
             <div className='row d-flex mx-3'>
              <div className='col text-start'>
              <a className='px-2'  ><BankLogoPage /></a>

              </div>
              <div className='col text-end px-5 py-2'>
              <h2 className='fw-bold text-fitek'  >Account Sectors Comparison Report</h2>

              </div>


             </div>
              
         
           
              
              {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
          </div>
                 
          <div
              style={{
                  position: "absolute",
                  bottom: "10px",
                  right: "10px",
                //  borderTop: "1px solid #bce8f1"
              }}
          >
              Page {props.pageNum} of {props.totalPages}
          </div>
      </div>
  );
};

const aggregates = [
  {
    field: "marketPercent",
    aggregate: "sum",
  },
  {
    field: "bmPcnt",
    aggregate: "sum",
  },
  {
    field: "varBMPcnt",
    aggregate: "sum",
  },
  {
    field: "pcntOfBMPcnt",
    aggregate: "sum",
  },

];


const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const AcctSectBenchRptGrid = ({ data,data1 }) => {
  const _export = React.useRef(null);

  const [showModal, setShowModal] = React.useState(false);
    const viewerRef = React.useRef();
    async function loadReport() {

        // load report definition from the file
        
        const reportResponse = await fetch(
            "AcctSectorComparison.rdlx-json"
        );
        
        const report = await reportResponse.json();
        return report;
    }
    async function openReport() {
        
        const report = await loadReport();
    //     
      for(var i=0;i<data1.ocAcctSectT2.length;i++)
      {
      data1.ocAcctSectT2[i].bmPcnt=parseNumber(formatNumber(data1.ocAcctSectT2[i].bmPcnt,"n2"));
      data1.ocAcctSectT2[i].account = data1.ocAcctSectT2[i].account.split(":")[0];
    }
    // 
        report.DataSources[0].ConnectionProperties.ConnectString =
            "jsondata=" + JSON.stringify(data1);
        
        viewerRef.current.Viewer.open(report);
    }
 
  let allPDFExport;
  const excelExport = () => {

    setShowModal(true);
    openReport();
    // if (_export.current !== null) {
    //   _export.current.save(data);
    // }
  };

  const showpreview =async () => {
    setShow(true);
  }
  
    const printPDF =async () => {
      
      
       if (allPDFExport !== null) {
         allPDFExport.save();
       }
    };
  

  const [locked, setLocked] = React.useState(false);
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);

  const handleCloseModal = () => setShowModal(false);

  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || '';
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={props.style}>
        {formatNumber(total, '##,#.00')}
      </td>
    );
  };

   const RightNameHeader = (props) => {
    return (
      <a className="k-link" style={{
        float: "right",
      }} onClick={props.onClick}>
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={{
            // color: "#53d2fa",
          }}
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };

  const columns = [
    // {
    //   title: 'Account',
    //   field: 'account',
    //   Width: "200",
    //   show: true,
    //   filter: 'text',
    //   locked: true,
    // },
    {
      title: 'Description',
      field: 'groupHeader',
      Width: "300",
      show: true,
      filter: 'text',
      locked: true,
      
    },
    {
      title: 'Portfolio Weighting (%)',
      field: 'marketPercent',
      Width: "120",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName:'rightHeader',
      filterable:true,
      multi:true
    },
    {
      title: 'Model Weighting (%)',
      field: 'bmPcnt',
      Width: "120",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName:'rightHeader',
      filterable:true,
      multi:true
    },
    {
      title: 'Variance to Model (%)',
      field: 'varBMPcnt',
      Width: "120",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName:'rightHeader' 
    },
    {
      title: '% of Model Weighting',
      field: 'pcntOfBMPcnt',
      Width: "120",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName:'rightHeader'
    },

  ];


  const [row, setRow] = useState(data);
  
  gridDtata=data;
  
  const createDataState = (dataState) => {
    return {
      result: process(data.slice(0), dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 20,
    skip: 0,
    // group: [
    //   {
    //     field: 'groupHeader',
    //   },
    // ],
  });

  let initialStateExcel = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
  });

  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  const [resultExcel, setResultExcel] = React.useState(
    processWithGroups(data, initialStateExcel.dataState)
  );


  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [stateColumns, setStateColumns] = React.useState(columns);
  console.log("Check");
  console.log(stateColumns);
  const [currentColumns, setCurrentColumns] = React.useState(columns);
  const [chartData, setSelectedData] = React.useState(data);
  let pageSize = 20;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
  
    });
    setResult(processWithGroups(data, updatedState.dataState));
    setResultExcel(processWithGroups(data, updatedStateExcel.dataState));
    
    setDataState(updatedState.dataState);
    localStorage.setItem("sectorCmprsnDataState",JSON.stringify(updatedState.dataState));
    console.log(JSON.parse(localStorage.getItem("sectorCmprsnDataState")));

  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const handleColumnLockToggle = (columnField, state) => {
    let newColumns = currentColumns.map((column) => {
      if (column.field === columnField) {
        column.locked = state;
      }

      return column;
    });
    setCurrentColumns(newColumns);
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  const getCells = (columns, cellProps) => {
    
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: 'right' }}>
            {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
          </td>
        );
      } else {
        // if(cellProps.field ==='account')
        // {
        // cells.push(<td colSpan={2}>&nbsp;</td>);
        // }
        // else
        // {
        cells.push(<td>&nbsp;</td>);
        // }
      }
    });
    return cells;
  };

  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === 'groupHeader' &&
      tdElement &&
      tdElement.props.role != 'presentation'
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.
      const columns = [
        { field: 'account' },
        { field: 'groupHeader' },

        { field: 'marketPercent', aggregate: 'sum' },
        { field: 'bmPcnt', aggregate: 'sum' },
        { field: 'varBMPcnt', aggregate: 'sum' },
        { field: 'pcntOfBMPcnt', aggregate: 'sum' },
      ];

      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }

    if (cellProps.rowType === 'groupFooter') {
      if (cellProps.field === 'marketPercent') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }}>

            {formatNumber(cellProps.dataItem.aggregates.marketPercent.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === 'bmPcnt') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }}>
            {formatNumber(cellProps.dataItem.aggregates.bmPcnt.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === 'varBMPcnt') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }}>
            {formatNumber(cellProps.dataItem.aggregates.varBMPcnt.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === 'pcntOfBMPcnt') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'} style={{ textAlign: 'right' }}>
            {formatNumber(cellProps.dataItem.aggregates.pcntOfBMPcnt.sum, "##,#.00")}
          </td>
        );
      }
    }

    if (cellProps.rowType === "data") {


      if (cellProps.field === "marketPercent") {
        return (

          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "bmPcnt") {
        return (

          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "varBMPcnt") {
        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "pcntOfBMPcnt") {
        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }



    }

    return tdElement;
  };


  const pageChange = (event) => {
    setPage(event.page);
  };

  const onRowClick = e => {
    
    var actId = e.dataItem.acctId;

    var acctData = Enumerable.from(data).where(w => w.acctId === actId)
      .toArray();

    setSelectedData(acctData);

    setResult(processWithGroups(data, dataState));
    setDataState(dataState);
  };
  const clearAcctSectorChart = () => {
    let FiIntData=JSON.parse(localStorage.getItem("AcctSctrInitialData"));
    setResult(processWithGroups(FiIntData, dataState));
    setSelectedData(FiIntData);
  };

  const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  const labelContent = (e) => `${e.value.toFixed(2)}`;
 
  return (

    <div>
      <div className="row d-flex justify-content-between align-items-center mx-1 px-2 mt-2">
        <div className="col-md">
          <p className="reportheading fs-6">Account Sectors Comparison Report</p>
        </div>
        <div className='subheader col'>Due to rounding, percentage may not equal 100.</div>
        <div className="col text-end">
        <button
                  className='btn btn-outline-primary btn-sm'
                  onClick={excelExport}>Preview</button>
                                {/* <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                                    <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
                                    <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

                                </DropdownButton> */}


                            </div>
        
        {/* <div className="col">
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={excelExport}
          >
            Export to Excel
          </button>
          &nbsp;
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={showpreview}
          >
          Preview
          </button>
        </div> */}
      </div>
      <Modal show={showModal} onHide={handleCloseModal} fullscreen={true} size="xl">

<Modal.Body>
    <div id="viewer-host">

        <Viewer ref={viewerRef}

        />
    </div>
</Modal.Body>
<Modal.Footer>



    <button className="btn btn-primary  btn-sm" onClick={handleCloseModal}>
        Close
    </button>


</Modal.Footer>

</Modal>


      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Header><div className="row w-100 tableheader">
          <div className='col'>
          <Modal.Title>Account Sectors Comparison Report - Pdf Preview</Modal.Title>
          </div>
          <div className='col text-end'>
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={printPDF}
          >
          Print
          </button> 
          <button className="btn btn-outline-danger  btn-sm" onClick={handleClose}>
                                Close
                                

                            </button>
          </div>
          
          
          </div>
        </Modal.Header>
        <Modal.Body>
        <PDFExport 
         margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
         pageTemplate={PageTemplate}
         forcePageBreak=".page-break"
         paperSize="A4"
         scale={0.5}
         allPages={true} 
         //margin="1cm"
         landscape={true}
         repeatHeaders={true}

        ref={(pdfExport) => (allPDFExport = pdfExport)}>
          
        <Grid className='accsctrgrd'
              style={{ height: '390px' }}
              data={resultExcel}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              onExpandChange={expandChange}
              cellRender={cellRender}

              onRowClick={onRowClick}
              sortable={true}
             // resizable={false}
             // reorderable={false}
              pageable={false}
            
              groupable={{
                footer: 'visible',
                enabled:false
              }}
            >

              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      footerCell={column.footerCell}
                      headerClassName={column.headerClassName}
                      
                     
                      //cell={NumberCell}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenu
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}
            </Grid>
            <br />
            <span className="page-break"></span>
            <Chart style={{ height: "550px" }}>
                    {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
                    <ChartLegend position="top" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        labels={{
                          visible: true,
                          rotation: 85,
                          format: "d",
                        }}
                      //  categories={categoryAxis} 
                      />
                    </ChartCategoryAxis>
                    <ChartTooltip render={defaultTooltipRender} />
                    <ChartSeries>
                      <ChartSeriesItem
                        name='Portfolio Weighting'
                        type="column"
                        data={chartData}
                        categoryField="groupHeader"
                        field="marketPercent"
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                      <ChartSeriesItem
                        name='Model Weight'
                        type="column"
                        data={chartData}
                        categoryField="groupHeader"
                        field="bmPcnt"
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                    </ChartSeries>
                  </Chart>
                  </PDFExport>
        </Modal.Body>
        <Modal.Footer>  
        
          
        
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        
         
        </Modal.Footer>
        
      </Modal>

      <div className="card-body">
        <div className="mx-1 px-1 my-1 py-1">

          <ExcelExport data={data} ref={_export}>

          <ExcelExportColumnGroup
              title={"Account Number: " + JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId + "  Processing Date: " + localStorage.getItem("processingDate")}
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn field="groupHeader" title="Description" width={250} />
              <ExcelExportColumn field="marketPercent" title="Portfolio Weighting (%)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }}/>
              <ExcelExportColumn field="bmPcnt" title="Model Weighting (%)" width={200} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
              <ExcelExportColumn field="varBMPcnt" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Variance to Model (%)" />
              <ExcelExportColumn field="pcntOfBMPcnt" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="% of Model Weighting" />
              
            </ExcelExportColumnGroup>
          </ExcelExport>
          
            <Grid className='accsctrgrd'
              style={{ height: '390px' }}
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              onExpandChange={expandChange}
              cellRender={cellRender}

              onRowClick={onRowClick}
              sortable={true}
              //resizable={true}
              //reorderable={true}
              // pageable={true}
              pageSize={20}
              //skip={page.skip}
              pageable={true}
              //pageSize={page.take}
              total={data.length}
              groupable={{
                footer: 'visible',
                enabled:false
              }}
            >

              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      footerCell={column.footerCell}
                      headerClassName={column.headerClassName}
                      
                      //columnMenu={ColumnMenuCheckboxFilter}
                      //cell={NumberCell}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenu
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}
            </Grid>
            <div className='row py-2'>
              <div className='card'>
                <div className='card-body'>
                  <Chart style={{ height: "550px" }}>
                    {/* <ChartTitle text="Maturity Date Vs Call/Put Date" /> */}
                    <ChartLegend position="top" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        labels={{
                          visible: true,
                          rotation: 85,
                          format: "d",
                        }}
                      //  categories={categoryAxis} 
                      />
                    </ChartCategoryAxis>
                    <ChartTooltip render={defaultTooltipRender} />
                    <ChartSeries>
                      <ChartSeriesItem
                        name='Portfolio Weighting'
                        type="column"
                        data={chartData}
                        categoryField="groupHeader"
                        field="marketPercent"
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                      <ChartSeriesItem
                        name='Model Weighting'
                        type="column"
                        data={chartData}
                        categoryField="groupHeader"
                        field="bmPcnt"
                        labels={{
                          visible: true,
                          content: labelContent,
                        }}
                      />
                    </ChartSeries>
                  </Chart>
                </div>
              </div>

            </div>

     



        </div>
      </div>
    </div>
  )
}

export default AcctSectBenchRptGrid
export {gridDtata}