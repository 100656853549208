import React from "react";
import * as ReactDOM from "react-dom";
import { useState, useEffect, useRef } from "react";

import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { process } from "@progress/kendo-data-query";
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export";
import {
  formatNumber,
  formatDate,
  numberSymbols,
  parseNumber,
} from "@telerik/kendo-intl";
import { CustomColumnMenuNoGrp } from "./customColumnMenuNoGrp";
import { drawDOM, exportPDF } from "@progress/kendo-drawing";
import { FaFileExcel, FaFilePdf, FaPlus, FaMinus } from "react-icons/fa";
import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import axios from "axios";
import { ExcelExportColumnGroup } from "@progress/kendo-react-excel-export";

import Modal from "react-bootstrap/Modal";
import {
  Grid,
  GridColumn as Column,
  GridToolbar,
} from "@progress/kendo-react-grid";

import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Moment from "react-moment";
import Enumerable from "linq";
import "hammerjs";
import { Button, Card } from "@mui/material";
import BankLogoPage from "./bankLogoPage";

import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";

const CustomGroupHeader = (props) => {
  
  return `${props.value}`;
};
const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "1px",

          width: "98%",
          borderBottom: "1px solid #bce8f1",
        }}
      >
        <div className="row d-flex mx-3">
          <div className="col text-start">
            <a className="px-2">
              <BankLogoPage />
            </a>
          </div>
          <div className="col text-end px-5 py-2">
            <h2 className="fw-bold text-fitek">Time Weighted Return Report </h2>
          </div>
        </div>

        {/* <div className='fw-bold  text-center'>
                  Account Profile</div> */}
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
          //  borderTop: "1px solid #bce8f1"
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};

// const aggregates = [
//   {
//     field: "initialVal",
//     aggregate: "sum",
//   },
// ];

const initialGroup = [
  {
    field: "accountConsNm",
  },
  {
    field: "categoryNm",
  },
  {
    field: "benchmarkNm",
  },
  //   {
  //     field: "cdId",
  //   },
];

const processWithGroups = (data, dataState) => {
  
  const groups = dataState.group;
  //console.log("groups:", groups);
  //   if (groups) {
  //     groups.map((group) => (group.aggregates = aggregates));
  //   }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  console.log("data", newDataState.data);
  
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const PerformanceRiskMetricsGrid = ({ data, flag, reportData }) => {
  // call active report
  const [show, setShow] = React.useState(false);
  const viewerRef = React.useRef();
  var d = data;
  console.log(d);
  
  async function loadReport() {
    // load report definition from the file
    
    const reportResponse = await fetch("PerformanceRiskMetrics.rdlx-json");
    
    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    
    const report = await loadReport();
    console.log("reportData", reportData);
    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify(reportData);
    
    viewerRef.current.Viewer.open(report);
  }

  const _export = React.useRef(null);
  
  const [expandedState, setExpandedState] = React.useState(true);
  const dataExport = process(data, {
    group: initialGroup,
  }).data;
  const [locked, setLocked] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const columnLocked = () => {
    setLocked(!locked);
  };

  const RightNameHeader = (props) => {
    return (
      <a
        className="k-link"
        style={{
          float: "right",
        }}
        onClick={props.onClick}
      >
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={
            {
              // color: "#53d2fa",
            }
          }
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };

  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : "",
        };
      } else {
        return { ...col, show: false };
      }
    });
    return newColumnsState;
  };

  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };

  const defaultColumns = [
    {
      title: "Metrics",
      field: "metrics",
      minWidth: "450",
      show: true,
      //filter: "text",
      //locked: true,
    },
    {
      title: "Entity Metrics",
      field: "categoryNm",
      minWidth: "300",
      show: true,
      //filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
    {
      title: "Benchmark Metrics",
      field: "benchmarkNm",
      minWidth: "300",
      show: true,
      //filter: "numeric",
      locked: false,
      headerClassName: "rightHeader",
    },
  ];
  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 22,
    skip: 0,
    //sort: [{ field: "account"}],
    group: [
      {
        field: "accountConsNm",
      },

      {
        field: "benchmarkNm",
      },
      {
        field: "categoryNm",
      },


      //   {
      //     field: "cdId",
      //   },
    ],
  });

  let initialStateExcel = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    //sort: [{ field: "account" }],
    group: [
      {
        field: "accountConsNm",
      },
      {
        field: "categoryNm",
      },
      {
        field: "benchmarkNm",
      },
      //   {
      //     field: "cdId",
      //   },
    ],
  });
  
  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );

  const [resultExcel, setResultExcel] = React.useState(
    processWithGroups(data, initialStateExcel.dataState)
  );

  const excelExport = () => {
    
    // setShow(true);
    // openReport();
    // // if (_export.current !== null) {
    //   _export.current.save();
    // }
  };

  const [dataState, setDataState] = React.useState(initialState.dataState);
  let loadedColumns = localStorage.getItem("gridColumns");
  const GridColumns = defaultColumns; //flag ? JSON.parse(loadedColumns) : defaultColumns;
  const [stateColumns, setStateColumns] = React.useState(GridColumns);

  let pageSize = 20;
  const [page, setPage] = React.useState({
    skip: 0,
    take: pageSize,
  });

  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 33; //let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem("gridColumns");
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    
    await axios
      .post("/RTSaveGridColumn/Index", postData, config)
      .then((response) => {
        console.log(response);
        const rowData = response.data;
      })
      .catch((error) => {
        if (error.response.status === 401) {
          
          refreshToken();
        }
        return error;
      });
  };
  const refreshToken = async () => {
    
    let token = JSON.parse(localStorage.getItem("token"));
    tempToken = token;
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        // saveColumnStateDb();
      })
      .catch((error) => {

        console.log("my error is " + error);
      });
  };
  const saveColumnsState = (columns) => {
    console.log("save called!!");

    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem("gridColumns", currentColumnsState);

    saveColumnStateDb();
  };

  // useEffect(() => {
  //   saveColumnsState(stateColumns);
  // }, [stateColumns]);

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    let updatedStateExcel = createDataState({
      take: Number.MAX_VALUE,
      skip: 0,
      sort: event.dataState.sort,
      group: event.dataState.group,
    });
    
    setResult(processWithGroups(data, updatedState.dataState));
    setResultExcel(processWithGroups(data, updatedStateExcel.dataState));

    setDataState(updatedState.dataState);
  };

  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };

  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };

  //   const getCells = (columns, cellProps) => {
  //     let cells = [];
  //     columns.forEach((column) => {
  //       if (column.aggregate) {
  //         cells.push(
  //           <td style={{ textAlign: "right" }}>
  //             {formatNumber(
  //               cellProps.dataItem.aggregates[column.field][column.aggregate],
  //               "##,#.00"
  //             )}
  //           </td>
  //         );
  //       } else {
  //         cells.push(<td>&nbsp;</td>);
  //       }
  //     });
  //     return cells;
  //   };
  const handleColapse = (event) => {
    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = false;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(false);
    setResult({
      ...result,
      data: [...result.data],
    });
  };

  const handleExpand = (event) => {
    

    result.data.map((item) => {
      // let index = this.expandedValues.indexOf(event.dataItem.value);
      item.expanded = true;
      return item;
      // if (index === -1) {
      //   item.expanded = false
      //   return item
      // }
      // return item
    });
    setExpandedState(true);
    setResult({
      ...result,
      data: [...result.data],
    });
  };
  const cellRender = (tdElement, cellProps) => {
    if (cellProps.rowType === "data") {
      if (cellProps.field === "metrics") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "left" }}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "categoryNm") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem.entityMetricsValue, "##,#.00")}
          </td>
        );
      }

      if (cellProps.field === "benchmarkNm") {
        return (
          <td
            aria-colindex={cellProps.columnIndex}
            style={{ textAlign: "right" }}
            role={"gridcell"}
          >
            {formatNumber(cellProps.dataItem.benchmkMetricsValue, "##,#.00")}
          </td>
        );
      }
    }

    return tdElement;
  };

  const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  const labelContent = (e) => `${e.value.toFixed(2)}`;
  let allPDFExport;
  let gridPDFExport = [];
  let gridPDFExportcopy = [];

  const showpreview = async (e) => {
    e.preventDefault();
    setShow(true);
    openReport();
  };

  const printPDF = async () => {
    

    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };

  const exportPDFWithMethod = () => {
    
    let gridElement = document.querySelector(".k-grid");
    drawDOM(gridElement, {
      paperSize: "A4",
    })
      .then((group) => {
        return exportPDF(group);
      })
      .then((dataUri) => {
        
        console.log(dataUri.split(";base64,")[1]);
        gridPDFExport[0] = dataUri;
      });
  };

  const grid = (
    <Grid
      style={{ height: "500px" }}
      data={result}
      {...dataState}
      onDataStateChange={dataStateChange}
      onColumnReorder={onColumnReorder}
      onColumnResize={onColumnResize}
      expandField="expanded"
      onExpandChange={expandChange}
      sortable={true}
      resizable={true}
      reorderable={true}
      pageable={true}
      pageSize={20}
      //group={[{field:'groupHeader'}]}
      // skip={page.skip}
      // pageable={{
      //   pageSizes: true,
      // }}
      // pageSize={page.take}
      // total={data.length}
      groupable={{
        footer: "visible",
        enabled: false,
      }}
      cellRender={cellRender}
    >
      <GridToolbar>
        {expandedState ? (
          <>
            {" "}
            <Button
              sx={{ textTransform: "none", minWidth: "50px" }}
              size="small"
              variant="outlined"
              onClick={handleColapse}
            >
              <FaMinus className="mx-2"></FaMinus>{" "}
            </Button>
          </>
        ) : (
          <>
            {" "}
            <Button
              sx={{ textTransform: "none", minWidth: "50px" }}
              size="small"
              variant="outlined"
              onClick={handleExpand}
            >
              <FaPlus className="mx-2"></FaPlus>{" "}
            </Button>
          </>
        )}
      </GridToolbar>
      {stateColumns.map(
        (column, idx) =>
          column.show && (
            <Column
              width={setWidth(column.minWidth)}
              locked={column.locked}
              key={idx}
              field={column.field}
              title={column.title}
              //filter={column.filter}
              footerCell={column.footerCell}
              // cell={NumberCell}
              // headerCell={column.headerCell}
              headerClassName={column.headerClassName}
              {...column}
            //   columnMenu={(props) => (
            //     <CustomColumnMenuNoGrp
            //       {...props}
            //       columns={stateColumns}
            //       onColumnsSubmit={onColumnsSubmit}
            //     />
            //   )}
            />
          )
      )}
    </Grid>
  );

  //const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  const [btnStatus, setBtnStatus] = React.useState(true);
  return (
    <div>
      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
      <div className="card-header row d-flex justify-content-between align-items-center my-2">
        <div className="col">
          <p className="tableheader h6">Performance Risk Metrics</p>
        </div>

        <div className="col text-end">
          {/* <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
            <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
            <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

          </DropdownButton> */}
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={showpreview}
          >
            Preview
          </button>
        </div>
        {/* <div className="col">
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={excelExport}
          >
            Export to Excel
          </button>
          &nbsp;
          <button
            className="btn btn-outline-primary btn-sm"
            onClick={showpreview}
          >
          Preview
          </button>
         
        </div> */}
      </div>

      <div className="card-body">
        <div className="mx-1 px-1 my-1 py-1">
          <ExcelExport
            data={dataExport}
            group={initialGroup}
            ref={_export}
            fileName={
              JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
              "_TimeWeightedReturn.xlsx"
            }
          >
            <ExcelExportColumnGroup
              title={
                "Account Number: " +
                JSON.parse(localStorage.getItem("AcctSelected")).extrnlAcctId +
                "  Processing Date: " +
                localStorage.getItem("processingDate")
              }
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn
                field="groupHeader"
                hidden={true}
                groupHeader={CustomGroupHeader}
              />
              <ExcelExportColumn
                field="detailedLine"
                title="Details"
                width={140}
              />
              <ExcelExportColumn
                field="monthToDate"
                title="Month to date (%)"
                width={150}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="quarterToDate"
                title="Quarter to date (%)"
                width={200}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="yearToDate"
                title="Year to date (%)"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="oneYear"
                title="Last 1 year (%)"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="threeYear"
                title="Last 3 years (%)"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="fiveYear"
                title="Last 5 years (%)"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="tenYear"
                title="Last 10 years (%)"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
              <ExcelExportColumn
                field="inception"
                title="Since inception (%)"
                width={130}
                headerCellOptions={{ textAlign: "right" }}
                cellOptions={{ format: "#,##0.00" }}
              />
            </ExcelExportColumnGroup>
          </ExcelExport>
          {grid}
        </div>
      </div>

      <br />
    </div>
  );
};

export default PerformanceRiskMetricsGrid;
