import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import Loading from './loading';
import Header from './header';
import Enumerable from 'linq';
import AcctSectBenchRptGrid from './AccountSectBenchGrid';
import "@progress/kendo-theme-material/dist/all.css";
import { filterBy } from '@progress/kendo-data-query';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

const AcctSectBenchRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem('token'));
  localStorage.setItem("acctSectmodelId", JSON.stringify({modelDesc:"" ,modelId:-1}));
  
  const [AcctSectBenchRptData1, populateAcctSectBenchRptData1] = useState([]);
  const [AcctSectBenchRptData2, populateAcctSectBenchRptData2] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
    //const [selAcct, SetselAcct] = useState(localStorage.getItem('IsAcctSelected')? JSON.parse(localStorage.getItem('AcctSelected')):JSON.parse(localStorage.getItem('acctData'))[0]);
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [selectedAcct,setSelectedAcct]=useState(0);
  const [loading, setLoading] = useState(true);
  //const []
  const [selModel,setSelModel]=useState({modelDesc:"" ,modelId:-1});
  const [modellist,setModellist]=useState(JSON.parse(localStorage.getItem("modelList")));
  const [session,setSession]=useState("");
  const [enableCombo,setEnableCombo]=useState(false);
  const navigate = useNavigate();
  useEffect(() => {

    const fetchData = async () => {
      // 
      setLoading(true);
      setEnableCombo(true);
      try {


        let asOfId = JSON.parse(localStorage.getItem('userId'));// data.Email;


        GetAcctSectBenchRptData();


      } catch (error) {
        console.error(error.message);
      }

    }
    fetchData();
  }, [])
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        GetAcctSectBenchRptData();


      })
      .catch((error) => {
        // 
        if(error.code==="ERR_BAD_REQUEST"){
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      })

  }
  const GetAcctSectBenchRptData = async () => {
    
    setLoading(true);
    setEnableCombo(true);

    //let token = JSON.parse(localStorage.getItem('token'));
    let token=tempToken;
    let mAsOfId = JSON.parse(localStorage.getItem('userId'));
    let lsSelectedAcct = JSON.parse(localStorage.getItem("ASectSelectedAcct"));
    let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }

    let pageId = 1;
    let mBenchMark =localStorage.getItem("acctSectmodelId")!=="undefined"?JSON.parse(localStorage.getItem("acctSectmodelId")).modelId:-1;
    
    const postData = { mAsOfId, AcctId, pageId, mBenchMark };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/AccountSectorsBenchmark',
      postData,
      config
    )
      .then(response => {

        //  console.log(response);
        
        const rowData = response.data;
        populateAcctSectBenchRptData1(rowData);
        populateAcctSectBenchRptData2(rowData.ocAcctSectT2);
        
        //Check
        localStorage.setItem("filterData",JSON.stringify(rowData.ocAcctSectT2) );
        populateAccountListData(rowData.ocAcctSectT3)
        //setSelAcctData(rowData.ocAcctSectT3);
        // if (lsSelectedAcct !== null) {
        //   //SetselAcct(lsSelectedAcct);
        //   //setSelectedAcct(lsSelectedAcct.acctId);
        // }
        // else {
        //   //SetselAcct(rowData.ocAcctSectT3[0]);
        //   //setSelectedAcct(rowData.ocAcctSectT3[0].acctId);
        // }
        let selAC = JSON.parse(localStorage.getItem('AcctSelected'));
        localStorage.setItem("AcctSctrInitialData", JSON.stringify(rowData.ocAcctSectT2));
        setLoading(false);
        setEnableCombo(false);
        setSelModel(Enumerable.from(modellist).where(w => w.modelId === rowData.benchmark).toArray()[0])
            
        for(let i=0;i<rowData.ocAcctSectT3.length;i++){
          rowData.ocAcctSectT3[i].shrtNm=selAC.extrnlAcctId;
        }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();


        }
        //return error;
      });

  }
  const modelChange = (event) => {
    
      if (event.target.value === null) {
        
        setSelModel(selModel);
        }
      else {
        
        setSelModel(event.target.value);
        localStorage.setItem("acctSectmodelId", JSON.stringify(event.target.value));
        GetAcctSectBenchRptData();
        //GetUpdatedAccountProfile(event.target.value.acctId);
      }
       
    };
    const filterData = (filter) => {
   
      // const dataAcct = selAcctData.slice();
       return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
     };
    const filterChange = (event) => {
     
      setSelAcctData(filterData(event.filter));
    };
   const handleChange = (event) => {
    
    if (event.target.value === null|| loading) {
      //SetselAcct('');
      SetselAcct(selAcct);
      //SetselAcct(JSON.parse(localStorage.getItem('acctData'))[0]);
      //SetselAcct(selAcct);
      localStorage.setItem('IsAcctSelected', false);
      localStorage.setItem('AcctSelected',JSON.stringify(selAcct));
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    }
    else {
      SetselAcct(event.target.value);
      localStorage.setItem('IsAcctSelected', true);
      localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));
      GetAcctSectBenchRptData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }



  };
  const signOut = () => {
    
    navigate("/");
    let token = JSON.parse(localStorage.getItem('token'));
    const postData = {};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    axios.post('/token/revoke',
    postData,
    config
    )
      .then((response) => {
      // 
      // localStorage.setItem('token', '');
       //console.log(response);
      // navigate("/");
          
        // if (response.statusText === '') {
         


        // }

       
      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      })

      // let tokenNew={token:''};
      // localStorage.setItem('token',JSON.stringify(tokenNew));
      // localStorage.setItem("AcctSelected",null);
      localStorage.removeItem('token');
      localStorage.clear();
    //firebaseApp.auth.signOut();

  }


  if (loading) {
    return (
      <>
       {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
        <Header></Header>
        <div className='my-1'>
        {/* <div className="rounded"></div> */}
        <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
            <span className='px-2'>Account(s)</span>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
            value={selAcct}
            onChange={handleChange}
            onFilterChange={filterChange}
            disabled={enableCombo}
            />
          </div>
          <div className='col'>
        <div>Model:</div>
      
      <ComboBox
          style={{
            width: "300px",
          }}
          data={modellist}
          textField="modelDesc"
          dataItemKey="modelId"
          filterable={true}
          value={selModel}
          onChange={modelChange}
          disabled={enableCombo}
        />

        </div>
          </div>
      </div>
        <Loading />
        </div>}</>
    )
  }
  return (
    <div>
      <Header></Header>
      <div className='my-1'>
        {/* <div className="rounded"></div> */}
        <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
            <span className='px-2'>Account(s)</span>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
            value={selAcct}
            onChange={handleChange}
            onFilterChange={filterChange}
            />
          </div>
          <div className='col'>
        <div>Model:</div>
      
      <ComboBox
          style={{
            width: "300px",
          }}
          data={modellist}
          textField="modelDesc"
          dataItemKey="modelId"
          filterable={true}
          value={selModel}
          onChange={modelChange}
          
        />

        </div>
          </div>
      </div>
      <AcctSectBenchRptGrid data={AcctSectBenchRptData2} data1={AcctSectBenchRptData1} /> : <></>


    </div>
  )
}

export default AcctSectBenchRpt


