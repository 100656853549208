import React from 'react';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { CustomColumnMenu } from './customColumnMenu';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";

import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import {
    setGroupIds,
    getGroupIds,
    setExpandedState,
} from '@progress/kendo-react-data-tools';
import Moment from 'react-moment';
import Modal from 'react-bootstrap/Modal';
import { ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesLabels,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartArea,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import Enumerable from 'linq';

import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
const aggregates = [
    {
        field: "marketCur",
        aggregate: "sum",
    },
    {
        field: "marketSpc",
        aggregate: "sum",
    }
];

const processWithGroups = (data, dataState) => {
    // const groups = dataState.group;

    // if (groups) {
    //     groups.map((group) => (group.aggregates = aggregates));
    // }

    // dataState.group = groups;
    const newDataState = process(data, dataState);
    // setGroupIds({
    //     data: newDataState.data,
    //     group: dataState.group,
    // });
    return newDataState;
};

const PageTemplate = (props) => {
    return (
        <div>
            <div
                style={{
                    position: "absolute",
                    top: "10px",
                    textAlign: "center",
                    backgroundColor: "#f4f4f2",
                }}
            >
                <div className='fw-bold page-header table-header'>Demo Bank</div>
            </div>

            <div
                style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                }}
            >
                Page {props.pageNum} of {props.totalPages}
            </div>
        </div>
    );
};
const PrtflioSnpshtComprsnGrd = ({ data, astColorModel, flag, selAstClass, dispAssetClass}) => {
    
  // call active report
 // const [show, setShow] = React.useState(false);
 const viewerRef = React.useRef();
 async function loadReport() {

 
   
   // load report definition from the file
   
   const reportResponse = await fetch(
     "PortfolioSnapshotComparisonReport.rdlx-json"
   );
   
   const report = await reportResponse.json();
   return report;
 }
 
 async function openReport() {
   
   const parameters=[
    {Name: 'pPieDonut', Value: [mjrPie]},
    {Name: 'pCurrDate', Value: [frmatDateCstm(2)]},
    {Name: 'pSnapDate', Value: [frmatDateCstm(1)]}
  ]

   const report = await loadReport();
   
   report.DataSources[0].ConnectionProperties.ConnectString =
     "jsondata=" + JSON.stringify(psCmprsnFinalData);
     
   viewerRef.current.Viewer.open(report,{ ReportParams: parameters });
   
 }

 //
    const psCmprsnFinalData = selAstClass == 0 ? Enumerable.from(data)
        .join(
            astColorModel,
            pk => pk.groupId,
            fk => fk.mjrAstTypeId,
            (left, right) => ({ ...left, ...right })
        )
        .toArray() : data;
// for(var i=0;i<psCmprsnFinalData.length;i++){
//     psCmprsnFinalData[i].marketPercentSpc=psCmprsnFinalData[i].marketPercentSpc<0?"("+(psCmprsnFinalData[i].marketPercentSpc*-1)+")":psCmprsnFinalData[i].marketPercentSpc;
// }

    const gridRef = useRef();
    const _export = React.useRef(null);
    const excelExport = () => {
        setShow(true);
        openReport();
        // if (_export.current !== null) {
        //     _export.current.save(data);
        // }
    };
    const [locked, setLocked] = React.useState(false);
    let allPDFExport;
    const columnLocked = () => {
        setLocked(!locked);
    };
    const totalSum = (props) => {
        const field = props.field || '';
        const total = data
            .reduce((acc, current) => acc + current[field], 0)
            .toFixed(2);
        return (
            <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
                {formatNumber(total, '##,#.00')}
            </td>
        );
    };

    // const PortfolioSnapshotHeader = (props) => {
    //     //        
    //     return (
    //         <td colSpan={props.colSpan}>
    //             Portfolio Snapshot({frmatDate()})
    //         </td>
    //     );
    //   };
    const frmatDateCstm = (cs) => {
        // 
        let d;
        if (cs === 1)
            d = new Date(JSON.parse(localStorage.getItem('psEndDt')));
        if (cs === 2)
            d = new Date(JSON.parse(localStorage.getItem('psStrtDt')));
        const months = {
            0: 'Jan',
            1: 'Feb',
            2: 'Mar',
            3: 'Apr',
            4: 'May',
            5: 'Jun',
            6: 'Jul',
            7: 'Aug',
            8: 'Sept',
            9: 'Oct',
            10: 'Nov',
            11: 'Dec',
        }
        // const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
        // const d = newDt
        const year = d.getFullYear()
        // const date = d.getDate()
        // const monthIndex = d.getMonth()
        const monthName = months[d.getMonth()]
        // const dayName = days[d.getDay()] // Thu
        const day = d.getDate().toString().padStart(2, '0')
        // const formatted = `${dayName}, ${date} ${monthName} ${year}`
        const formatted = `${day}-${monthName}-${year}`
        return formatted.toString()
    }
    const tilePortfolioSnapshotCustom = "Portfolio Snapshot(" + frmatDateCstm(1) + ")";
    let defaultColumns = [
        {
            title: 'Description',
            field: 'groupName',
            minWidth: 340,
            show: true,
            filter: 'text',
            locked: false,

        },
        {
            title: 'Current Portfolio',
            field: 'marketCur',
            minWidth: 180,
            show: true,
            filter: 'numeric',
            locked: false,
            headerClassName: 'rightHeader',
        },
        {
            title: 'Current Portfolio Weighting %',
            field: 'marketPercentCur',
            minWidth: 240,
            show: true,
            filter: 'numeric',
            locked: false,
            headerClassName: 'rightHeader',
        },
        {
            title: '',
            field: 'marketSpc',
            minWidth: 270,
            show: true,
            filter: 'numeric',
            locked: false,
            headerClassName: 'rightHeader',
        },
        {
            title: 'Portfolio Snapshot Weighting %',
            field: 'marketPercentSpc',
            minWidth: 250,
            show: true,
            filter: 'numeric',
            locked: false,
            headerClassName: 'rightHeader',
        },
        {
            title: 'Variance %',
            field: 'variance',
            minWidth: 140,
            show: true,
            filter: 'numeric',
            locked: false,
            headerClassName: 'rightHeader',
        },
    ];
    //lelocalStorage.setItem('gridColumns', JSON.stringify(defaultColumns));
    let loadedColumns = localStorage.getItem('gridColumns');
    const GridColumns =defaultColumns;//? JSON.parse(loadedColumns) : defaultColumns;
    //GridColumns[0].footerCell="Total";
    GridColumns[1].footerCell = totalSum;
    GridColumns[3].footerCell = totalSum;
    GridColumns[3].title = tilePortfolioSnapshotCustom;
    const [row, setRow] = useState(psCmprsnFinalData);
    const createDataState = (dataState) => {
        return {
            result: process(psCmprsnFinalData, dataState),
            dataState: dataState,
        };
    };
    let initialState = createDataState({
        take: Number.MAX_VALUE,
        skip: 0,
    });

    const [result, setResult] = React.useState(
        processWithGroups(psCmprsnFinalData, initialState.dataState)
    );

    // const [result, setResult] = useState(initialState.result);
    const [dataState, setDataState] = useState(initialState.dataState);
    const [stateColumns, setStateColumns] = useState(GridColumns);
    const [mjrRadioStat, setMjrRadioStat] = useState('checked');
    const [mjrPie, setMjrPie] = useState(1);

    const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        // setResult(updatedState.result);
        setResult(processWithGroups(psCmprsnFinalData, updatedState.dataState));
        setDataState(updatedState.dataState);
    };

    //GRID REORDER/RESIZE INIT SETTING
    const onColumnReorder = (props) => {
        setStateColumns(addHiddenColumns(props.columns));
    };

    const onColumnResize = (props) => {
        setStateColumns(addHiddenColumns(props.columns));
    };

    const addHiddenColumns = (columns) => {
        let newColumnsState = defaultColumns.map((col) => {
            let _col = columns.filter((c) => c.field == col.field);
            if (_col.length > 0) {
                return {
                    ...col,
                    orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
                    width: _col[0].width ? _col[0].width : '',
                };
            } else {
                return { ...col, show: false };
            }
        });
        newColumnsState[1].footerCell = totalSum;
        newColumnsState[3].footerCell = totalSum;
        newColumnsState[3].title = tilePortfolioSnapshotCustom;
        return newColumnsState;
    };
    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };
    const expandChange = (event) => {
        const isExpanded =
            event.dataItem.expanded === undefined
                ? event.dataItem.aggregates
                : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
    };
    const showpreview = async () => {
        //setDataState({ ...dataState,  skip: 0,take: 1000 });
        //setResult(data);
        setShow(true);
    }

    const printPDF = async () => {


        if (allPDFExport !== null) {

            allPDFExport.save();

        }
    };
    const saveColumnStateDb = async () => {
        //Storing column settings in DB
        // 
        let token = JSON.parse(localStorage.getItem('token'));
        let UserId = JSON.parse(localStorage.getItem('userId'));
        let GridId = 5;//let 5 for Portfolio Snapshot Comparison Report
        let ColumnSettings = localStorage.getItem('gridColumns');
        const postData = { UserId, GridId, ColumnSettings };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/RTSaveGridColumn/Index',
            postData,
            config
        )
            .then(response => {

                console.log(response);
                const rowData = response.data;
            })
            .catch((error) => {

                return error;
            });
    }
    const saveColumnsState = (columns) => {
        console.log('save called!!');
        //console.log(columns);
        let currentColumnsState = JSON.stringify(columns);
        localStorage.setItem('gridColumns', currentColumnsState);

        saveColumnStateDb();

    };

    // useEffect(() => {
    //     saveColumnsState(stateColumns);
    // }, [stateColumns]);

    const [mnrRadioStat, setMnrRadioStat] = useState('checked');
    const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
    const labelContent = (e) => `${e.value.toFixed(2)}%`;

    const handleClick = () => {
        setLocked(!locked);
    };

    const _grid = React.useRef();

    const [gridChartCheck, setgridChartCheck] = useState('checked');

    const [collapsedState, setCollapsedState] = React.useState([]);
    const cellRender = (tdElement, cellProps) => {

        if (cellProps.rowType === 'groupFooter') {
        }
        if (cellProps.rowType === "data") {
            if (cellProps.field === "marketCur") {

                return (
                    <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                    </td>
                );
            }
            if (cellProps.field === "marketPercentCur") {

                return (
                    <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                    </td>
                );
            }
            if (cellProps.field === "marketSpc") {

                return (
                    <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                    </td>
                );
            }
            if (cellProps.field === "marketPercentSpc") {
if(cellProps.dataItem[cellProps.field]<0){
    return (
        <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {"("+formatNumber(cellProps.dataItem[cellProps.field]*-1, "##,#.00")+")"}
        </td>
    );
}
                return (
                    <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                    </td>
                );
            }
            if (cellProps.field === "variance") {

                return (
                    <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                        {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
                    </td>
                );
            }
        }
        return tdElement;
    };
    const setWidth = (minWidth) => {
        let width = minWidth;
        return width;
    };
    const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const FormatLongNumber = ({ value }) => {

        if (value === 0) {
            return 0;
        }
        else {
            // for testing
            //value = Math.floor(Math.random()*1001);

            // hundreds
            if (value <= 999) {
                return value;
            }
            // thousands
            else if (value >= 1000 && value <= 999999) {
                return (value / 1000) + 'K';
            }
            // millions
            else if (value >= 1000000 && value <= 999999999) {
                return (value / 1000000) + 'M';
            }
            // billions
            else if (value >= 1000000000 && value <= 999999999999) {
                return (value / 1000000000) + 'B';
            }
            else
                return value;
        }
    }
    const handleSetPieMjr = () => {
        setMjrPie(1);
        setMjrRadioStat('checked');
    }
    const handleSetDonutMjr = () => {
        setMjrPie(0);
        setMjrRadioStat('');
    }

    
    console.log("data:",result)
    return (

        <div>
               <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">

<Modal.Body>
  <div id="viewer-host">

    <Viewer ref={viewerRef}

    />
  </div>
</Modal.Body>
<Modal.Footer>



  <button className="btn btn-primary  btn-sm" onClick={handleClose}>
    Close
  </button>


</Modal.Footer>

</Modal>
            {

                <div className='card-header row d-flex justify-content-between align-items-center my-2'>

                    <div className='col'>
                        <p className='tableheader h6'>Portfolio Snapshot Comparison Report</p>
                        {/* <p>Portfolio Snapshot({frmatDate()})</p> */}
                        {/* <p>processingDate : {localStorage.getItem("processingDate")}</p>
         <p>processingDate : {localStorage.getItem('psEndDt')}</p> */}
                    </div>
                    <div className='subheader col-lg text-end'>Due to rounding, percentage may not equal 100.</div>
                    <div className="col text-end">
                        {/* <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                            <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
                            <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

                        </DropdownButton> */}
                        <button
                  className='btn btn-outline-primary btn-sm'
                  onClick={excelExport}>Preview</button>


                    </div>

                    {/* <div className='col'>
                        <button
                            className='btn btn-outline-primary btn-sm'
                            onClick={excelExport}>Export to Excel</button>
                        &nbsp;
                        <button
                            className="btn btn-outline-primary btn-sm"
                            onClick={showpreview}
                        >
                            Preview
                        </button>
                    </div> */}

                </div>

            }

            <div className="card-body">
                <div className="mx-1 my-1 py-1">
                    <ExcelExport data={psCmprsnFinalData} ref={_export}>
                        <ExcelExportColumnGroup
                            title={"Account Number: " + JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId + "  Processing Date: " + localStorage.getItem("processingDate")}
                            headerCellOptions={{
                                textAlign: "left",
                            }}
                        >
                            <ExcelExportColumn field="groupName" title="Description" width={250} />
                            <ExcelExportColumn field="marketCur" title="Current Portfolio" width={140} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                            <ExcelExportColumn field="marketPercentCur" title="Current Portfolio Weighting%" width={100} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                            <ExcelExportColumn field="marketSpc" width={110} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title={tilePortfolioSnapshotCustom} />
                            <ExcelExportColumn field="marketPercentSpc" width={200} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="Portfolio Snapshot Weighting%" />
                            <ExcelExportColumn field="variance" width={150} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} title="PVariance%" />
                        </ExcelExportColumnGroup>
                    </ExcelExport>
                    <Grid
                        style={{ width: '100%', height: "100%" }}
                        data={result}
                        {...dataState}
                        onDataStateChange={dataStateChange}
                        expandField="expanded"
                        onExpandChange={expandChange}
                        cellRender={cellRender}
                        sortable={true}
                        scrollable={true}
                       // resizable={true}
                       // reorderable={true}
                        //pageable={true}
                        //pageSize={10}
                        groupable={{
                            enabled: false,
                            footer: 'visible',
                        }}
                        //
                        ref={gridRef.current}
                       // onColumnReorder={onColumnReorder}
                       // onColumnResize={onColumnResize}
                    //
                    >
                        {stateColumns.map(
                            (column, idx) =>
                                column.show && (
                                    <Column
                                        width={setWidth(column.minWidth)}
                                        key={idx}
                                        field={column.field}
                                        title={column.title}
                                        footerCell={column.footerCell}
                                        headerClassName={column.headerClassName}
                                        {...column}
                                        // columnMenu={(props) => (
                                        //     <CustomColumnMenuNoGrp
                                        //         {...props}
                                        //         columns={stateColumns}
                                        //         onColumnsSubmit={onColumnsSubmit}
                                        //     />
                                        // )}
                                    />
                                )
                        )}
                    </Grid>

                </div>
                {dispAssetClass}
                <div className="form-check mt-1 k-text-center py-2 mb-2">
                    <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                        <input type="radio" defaultChecked={mjrRadioStat} className="btn-check form-check-input" name="btnradioMnr" id="radio1Mjr" onClick={handleSetPieMjr} />
                        <label className="btn btn-outline-primary btn-sm" htmlFor="radio1Mjr">Pie Chart</label>

                        <input type="radio" className="btn-check form-check-input" name="btnradioMnr" id="radio2Mjr" onClick={handleSetDonutMjr} />
                        <label className="btn btn-outline-primary btn-sm" htmlFor="radio2Mjr">Doughnut Chart</label>
                    </div>
                </div>
                <div className='row mx-1 my-1 py-1'>
                    <div className='card my-2 col-md-6 col-lg-6 col-sm-6'>
                        <div className="card-header tableheader h6">Current Portfolio ({frmatDateCstm(2)})</div>
                       {/* <div className="card-header tableheader h6">Current Portfolio ({currDtChart.charCurrDt})</div> */}
                        <Chart style={{ height: "350px" }}>
                            <ChartLegend position="bottom" />
                            <ChartSeries>
                                <ChartSeriesItem
                                    type={mjrPie === 1 ? "pie" : "donut"}
                                    data={psCmprsnFinalData.filter(x => x.marketPercentCur > 0)}
                                    field="marketPercentCur"
                                    categoryField="groupName"
                                    autoFit={true}
                                    colorField="chartColorCOde"
                                    labels={{
                                        visible: true,
                                        content: labelContent,
                                    }}
                                >
                                    {(
                                        <ChartSeriesLabels
                                            position="outsideEnd"
                                            background="none"
                                            content={labelContent}
                                        />
                                    )}
                                </ChartSeriesItem>
                            </ChartSeries>
                        </Chart>
                    </div>
                    <div className="card my-2 col-md-6 col-lg-6 col-sm-6">
                        <div className="card-header tableheader h6">Portfolio Snapshot ({frmatDateCstm(1)})</div>
                        {/* <div className="card-header tableheader h6">Portfolio Snapshot ({snpDateChart.CharSnpDate})</div> */}
                        <Chart style={{ height: "350px" }}>
                            <ChartLegend position="bottom" />
                            <ChartSeries>
                                <ChartSeriesItem
                                    type={mjrPie === 1 ? "pie" : "donut"}
                                    data={psCmprsnFinalData.filter(x => x.marketPercentSpc > 0)}
                                    field="marketPercentSpc"
                                    categoryField="groupName"
                                    autoFit={true}
                                    colorField="chartColorCOde"
                                    labels={{
                                        visible: true,
                                        content: labelContent,
                                    }}
                                >
                                    {(
                                        <ChartSeriesLabels
                                            position="outsideEnd"
                                            background="none"
                                            content={labelContent}
                                        />
                                    )}
                                </ChartSeriesItem>
                            </ChartSeries>
                        </Chart>
                    </div>
                </div>
                <div className='row mx-1 my-1 py-1'>
                    <div className="card my-2 col-md-12 col-lg-12 col-sm-12">
                        <div className="card-header tableheader h6">Current Holdings vs Portfolio Holdings</div>
                        <Chart style={{ height: "400px" }}>
                            <ChartLegend position="bottom" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem
                                    labels={{
                                        visible: true,
                                        rotation: 85,
                                        format: "d",
                                    }}
                                //  categories={categoryAxis} 
                                />
                            </ChartCategoryAxis>
                            <ChartTooltip render={defaultTooltipRender} />
                            <ChartValueAxis>
                                <ChartValueAxisItem
                                    // title={{
                                    //     text: "Percentage",
                                    // }}
                                    min={0}
                                    labels={{
                                        visible: true,
                                        content: FormatLongNumber

                                    }}
                                    max={120}
                                />
                            </ChartValueAxis>
                            <ChartSeries>
                                <ChartSeriesItem
                                    type="column"
                                    data={psCmprsnFinalData.filter(x => x.marketPercentCur != 0 || x.marketPercentSpc != 0)}
                                    categoryField="groupName"
                                    field="marketPercentCur"
                                    labels={{
                                        visible: true,
                                        content: FormatLongNumber,
                                    }}
                                    name='Current'
                                />
                                <ChartSeriesItem
                                    type="column"
                                    data={psCmprsnFinalData.filter(x => x.marketPercentCur != 0 || x.marketPercentSpc != 0)}
                                    categoryField="groupName"
                                    field="marketPercentSpc"
                                    labels={{
                                        visible: true,
                                        content: FormatLongNumber,
                                    }}
                                    name='Snapshot'
                                />
                            </ChartSeries>
                        </Chart>
                    </div>
                </div>
            </div>

          

        </div>
    )
}

export default PrtflioSnpshtComprsnGrd
