import React from 'react'
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ColumnMenu,ColumnMenuCheckboxFilter } from "./columnMenu";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { process } from "@progress/kendo-data-query";
import { orderBy } from "@progress/kendo-data-query";
import {snapshotCmprsnDatavar} from './cstmRptMain';
import { ResponsiveContainer } from 'recharts'



const PortfolioSnpshotGridWidget = () => {
  
  const [brkdwnData, setbrkdwnData] = useState(snapshotCmprsnDatavar);//change Data!!!!!!!!!!!
  //const [astModelData, setastModelData] = useState(assetVsModelFinalDatavar);
  const createDataState = (dataState) => {
    return {
      result: process(brkdwnData.slice(0), dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: 20,
    skip: 0,
  });
  const [result, setResult] = React.useState(initialState.result);
  const [dataState, setDataState] = React.useState(initialState.dataState);
  const [sort, setSort] = React.useState([]);
  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };
  const frmatDateCstm = (cs) => {
    // 
    let d;
    if (cs === 1)
        d = new Date(JSON.parse(localStorage.getItem('psEndDt')));
    if (cs === 2)
        d = new Date(JSON.parse(localStorage.getItem('psStrtDt')));
    const months = {
        0: 'Jan',
        1: 'Feb',
        2: 'Mar',
        3: 'Apr',
        4: 'May',
        5: 'Jun',
        6: 'Jul',
        7: 'Aug',
        8: 'Sept',
        9: 'Oct',
        10: 'Nov',
        11: 'Dec',
    }
    // const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat']
    // const d = newDt
    const year = d.getFullYear()
    // const date = d.getDate()
    // const monthIndex = d.getMonth()
    const monthName = months[d.getMonth()]
    // const dayName = days[d.getDay()] // Thu
    const day = d.getDate().toString().padStart(2, '0')
    // const formatted = `${dayName}, ${date} ${monthName} ${year}`
    const formatted = `${day}-${monthName}-${year}`
    return formatted.toString()
}
const tilePortfolioSnapshotCustom = "Portfolio Snapshot(" + frmatDateCstm(1) + ")";
const NumberCell = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
    )
}
const NumberCellSixDecimal = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.000000")}
        </td>
    )
}

const totalAcVMdl = (props) => {
  const field = props.field || "";
  const total = brkdwnData.reduce((acc, current) => acc + current[field], 0);
  return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
          {formatNumber(total, "##,#.00")}
      </td>
  );
};
const menuWithExcelCheckOnly = (props) => {
  

  const props1 = {...props};
  

  
  return (
    <div>

      <ColumnMenuCheckboxFilter
        {...props1}

       
        data={brkdwnData}
      
      />
    </div>)
}
  return (
    <ResponsiveContainer>
     <div><h5 className='text-fitek p-1'>Portfolio Snapshot Comparison</h5>
    <Grid style={{width:"100%" ,height: "auto" }}
    id='tileGrid'
                                    data={result}
                                    onDataStateChange={dataStateChange}
                                    {...dataState}
                                sortable={true}
                                sort={sort}
                                //onSortChange={(e) => {
                                // setSort(e.sort);
                                //}}
                                className='page-break'
                                >

                                    <Column field="groupName"  menu={true} title="Description" columnMenu={menuWithExcelCheckOnly} width="auto" />
                                    <Column field="marketCur"  menu={true} title="Current Portfolio" width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu}  cell={NumberCell} headerClassName='rightHeader'/>
                                    <Column field="marketPercentCur"  menu={true} title="Current Portfolio Weighting%" width="auto" columnMenu={ColumnMenu} footerCell={totalAcVMdl}  cell={NumberCell}  headerClassName='rightHeader'/>
                                    <Column field="marketSpc"  menu={true} title={tilePortfolioSnapshotCustom} width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader'/>
                                    <Column field="marketPercentSpc"  menu={true} title="Portfolio Snapshot Weighting%" width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader'/>
                                    <Column field="variance"  menu={true} title="Variance" width="auto" footerCell={totalAcVMdl} columnMenu={ColumnMenu} cell={NumberCell} headerClassName='rightHeader' />
                                    
                                    </Grid>
                                    </div>
                                    </ResponsiveContainer>
     
  )
}





export default PortfolioSnpshotGridWidget