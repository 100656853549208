import * as React from "react";
import { Dialog } from "@progress/kendo-react-dialogs";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input, NumericTextBox, TextArea } from "@progress/kendo-react-inputs";
import { Label, Hint, Error } from "@progress/kendo-react-labels";
const TmpltNmRegex = new RegExp(/^[A-Za-z0-9 ]+$/);
const TmpltNmValidator = (val) =>
{
  
  let isValid=TmpltNmRegex.test(val);
  let valdMsg="";
  if(!isValid)
    valdMsg="Please Enter a Valid Template Name.";
  else if(val.length === 0)
    valdMsg="Please Enter Template Name.";
  return valdMsg;
}
const TmpltNmInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
const TmpltDescValidator = (val) =>
  (typeof val === 'string' && val.length === 0) ?"Please Enter Template Description.":""; 

const TmpltDescInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextArea {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const EditCstmRprtForm = (props) => {
  
  let a=TmpltNmValidator;
  let b=TmpltDescValidator;  
  const [ErrStatTNm,SetErrStatTNm]=React.useState(false);
  const [ErrStatTDesc,SetErrStatTDesc]=React.useState(false);
 
  const handleNmChange = (e) => {
    
    let isValid=TmpltNmRegex.test(e.target.value);
    if(e.target.value==="")
    {
      SetErrStatTNm(true);
    }
    else
    {
      if(!isValid)
        SetErrStatTNm(true);
      else
        SetErrStatTNm(false);
    }
  };
  const handleDescChange = (e) => {
    if(e.target.value==="")
    {
      SetErrStatTDesc(true);
    }
    else
    {
      SetErrStatTDesc(false);
    }
  };
  return (
    <Dialog title={`Edit Template`} onClose={props.cancelEdit}>
      <Form
        onSubmit={props.onSubmit}
        initialValues={props.item}
        render={(formRenderProps) => (
          <FormElement
            style={{
              width: 500,
              height: 300,
            }}
          >
            <fieldset className={"k-form-fieldset"}>
              <div className="mb-3">
                <Label>Template Name</Label>
                <Field
                  name={"templateNm"}
                  component={TmpltNmInput}
                  validator={TmpltNmValidator}
                  onChange={(e) => handleNmChange(e)}
                />
              </div>
              <div className="mb-3">
              <Label>Template Desc</Label>
                <Field
                  name={"templateDesc"} 
                  component={TmpltDescInput}
                  validator={TmpltDescValidator}
                  onChange={(e) => handleDescChange(e)}
                />
              </div>
            </fieldset>
            <div className="k-form-buttons">
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary"
                disabled={!ErrStatTNm  && !ErrStatTDesc ? false : true }
                  //? !formRenderProps.allowSubmit : formRenderProps.allowSubmit}
              >
                Update
              </button>
              <button
                type={"submit"}
                className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                onClick={props.cancelEdit}
              >
                Cancel
              </button>
            </div>
          </FormElement>
        )}
      />
    </Dialog>
  );
};
export default EditCstmRprtForm;