import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import Loading from './loading';
import Header  from './header';
import SctrReturnPerformanceGrid from './sctrReturnPerfrmnceGrid'
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';


const SctrReturnPerformanceRpt = () => {
    const [SctrReturnPerformanceRptData, populateSctrReturnPerformanceRptData] = useState([]);
    const [reportData,setReportData] = useState([]);
    const [AstGrwthRptData, populateAstGrwthRptData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [flag, setFlag]=useState(0);
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [session,setSession]=useState("");
    const [enableCombo,setEnableCombo]=useState(false);
    const navigate = useNavigate();
    useEffect(() => {
      
        const fetchData = async () => {
             setLoading(true);
             setEnableCombo(true);
            try {
                //let data = location.state;
    
                let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
               
                //setEmail(email);
                GetSctrReturnPerformanceData();
              
                //  console.log(data);
            } catch (error) {
                console.error(error.message);
            }
    
        }
        fetchData();
    }, []);
    const refreshToken = async () => {
      let token = JSON.parse(localStorage.getItem('token'));
      let AccessToken = token.token;
      let RefreshToken = token.refreshToken;
      const postData = { AccessToken, RefreshToken };
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      await axios.post('/token/Refresh',
        postData
  
      )
        .then((response) => {
          
          tempToken = response.data;
          localStorage.setItem('token', JSON.stringify(response.data));
  
          GetSctrReturnPerformanceData();
  
  
        })
        .catch((error) => {
          // 
          if(error.code==="ERR_BAD_REQUEST"){
            setSession("Session Expired");
          }
          console.log("my error is " + error);
        })
  
    }

    const GetSctrReturnPerformanceData = async () => {
        //
        setLoading(true);
        setEnableCombo(true);
      
        // let token = JSON.parse(localStorage.getItem('token'));
        let token =  tempToken;
         let AsOfId = JSON.parse(localStorage.getItem('userId'));
         let lsSelectedAcct=JSON.parse(localStorage.getItem("AcctSelected"));
         let acctIds = 0;
         if(lsSelectedAcct!== null)
         {
            acctIds=lsSelectedAcct.acctId;
         }
         let PageId = 1;
         const postData = {AsOfId, acctIds, PageId};
         const config = {
            headers: {
              'authorization': `Bearer ${token.token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
          
      };
        await axios.post('/RTSectorRetPerfrmnce',
            postData,
           config
        )
            .then(response => {
              
                //  console.log(response);
      //
                const rowData = response.data;
                populateSctrReturnPerformanceRptData(rowData.sRP1);
                setReportData(rowData);
                setSelAcctData(rowData.sRP2);
                populateAstGrwthRptData(rowData.sRP3);
                if(lsSelectedAcct!== null)
                {
                    SetselAcct(lsSelectedAcct);
                    // setSelectedAcct(lsSelectedAcct.acctId);                    
                }
                else
                {
                    SetselAcct(rowData.sRP2[0]);
                    // setSelectedAcct((rowData.sRP2[0]).acctId);
                    //setSelectedAcct(rowData.fIM1[0].acctId);
                }
                setLoading(false);
                setEnableCombo(false);
                setFlag(1);      
            })
            .catch((error) => {
              if (error.response.status === 401) {
                refreshToken();
      
      
              }
               // return error;
            });
      
      }
      const handleChange = (event) => {
        
                if (event.target.value === null) {
                  //SetselAcct('');
                  SetselAcct(selAcct);
                 // GetAcctProfileData();
                  // GetUpdatedAccountProfile(0);
                }
                else {
                  SetselAcct(event.target.value);
                  localStorage.setItem("AcctSelected",JSON.stringify(event.target.value));
                  GetSctrReturnPerformanceData(event.target.value);
                  //GetUpdatedAccountProfile(event.target.value.acctId);
                }
            
            
            
              };

              const signOut = () => {
    
                navigate("/");
                let token = JSON.parse(localStorage.getItem('token'));
                const postData = {};
                const config = {
                  headers: {
                    'authorization': `Bearer ${token.token}`,
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                  }
              
                };
                axios.post('/token/revoke',
                postData,
                config
                )
                  .then((response) => {
                  // 
                  // localStorage.setItem('token', '');
                   //console.log(response);
                  // navigate("/");
                      
                    // if (response.statusText === '') {
                     
              
              
                    // }
              
                   
                  })
                  .catch((error) => {
                    // 
                    console.log("my error is " + error);
                  })
              
                  // let tokenNew={token:''};
                  // localStorage.setItem('token',JSON.stringify(tokenNew));
                  // localStorage.setItem("AcctSelected",null);
                  localStorage.removeItem('token');
                  localStorage.clear();
                //firebaseApp.auth.signOut();
              
              }
      if (loading) {
        return(
            <>
                {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
              <Header></Header>
            <Loading />
            </div>}</>
          )
      }
      
  return (
    <div style={{overflow:"hidden"}}>
       <Header></Header>
       <div  className='my-1'>
      {/* <div className="rounded"></div> */}
       <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
       <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
       <span className='px-2'>Account(s)</span>
          <ComboBox
            style={{
              width: "350px",
            }}
            data={selAcctData}
            textField="extrnlAcctId"
            dataItemKey="acctId"
            filterable={true}
            value={selAcct}
            onChange={handleChange}
            disabled={enableCombo}
            // onFilterChange={filterChange}
          />
          </div><div className='subheader text-end col-md-2'>Processing Date : {localStorage.getItem("processingDate")}</div>
          </div>
          </div>
       {flag===1?
        <SctrReturnPerformanceGrid  data={SctrReturnPerformanceRptData} AstGrwth = {AstGrwthRptData} reportData={reportData}/>
        :<></>
       }
    </div>
  )
}

export default SctrReturnPerformanceRpt


