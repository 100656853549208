import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Loading from './loading';
import Header from './header';
import AssetVsModelGrid from './assetVsModelGrid';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatNumber, parseNumber } from '@telerik/kendo-intl';

const AssetAllocModelRptMain = () => {
    const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [selectedAcct, setSelectedAcct] = useState(0);
    const [loading, setLoading] = useState(true);
    const [modelData, setModelData] = useState([]);
    const [modelId, setModelId] = useState(-1);
   
    const [isColumnSave, setIsColumnSave] = useState(false);
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [session,setSession]=useState("");
    const [enableCombo,setEnableCombo]=useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            setEnableCombo(true);
            try {
                //let data = location.state;

                let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
                getModelInfo();
                getColumnStateDb();
            } catch (error) {
                console.error(error.message);
            }

        }
        fetchData();
    }, []);

    const getModelInfo = async () => {

        setLoading(true);
        setEnableCombo(true);

        let token = JSON.parse(localStorage.getItem('token'));
        let UserId = JSON.parse(localStorage.getItem('userId'));
       

        let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
        let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
        setSelectedAcct(JSON.parse(localStorage.getItem('AcctSelected')).acctId);
        let invMix = 0;
        let NumOfRows = 10;

      
        const postData1 = { AcctId };
        // const postData = { UserId, Accounts, modelId, invMix, NumOfRows, AcctId };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/GetModelInfo',
            postData1,
            config
        ).then((response => {
           
            const rowData = response.data;
            
            localStorage.setItem("modelId", JSON.stringify(response.data.acctMdl[0].modelId));
            setModelData(response.data.model);
            setModelId(JSON.parse(localStorage.getItem("modelId")));
          //  getColumnStateDb();
            setLoading(false);
            setEnableCombo(false);
            //postData.modelId = response.data.acctMdl[0].modelId;
            // console.log(postData.modelId)
        })
        )

            .catch((error) => {

                if (error.response.status === 401) {
                    refreshToken();


                }


                // return error;
            });
          //  let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
            GetAllocModelData();
    }

    const getColumnStateDb = async () =>{
        //Storing column settings in DB
        ;
          let token = JSON.parse(localStorage.getItem('token'));
          let UserId = JSON.parse(localStorage.getItem('userId'));
          let GridId = 26;//let 1 for Portfoliholdings Grid
          
          const postData = {UserId, GridId};
          const config = {
            headers: {
              'authorization': `Bearer ${token.token}`,
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            }
        
          };
          await axios.post('/RTGetGridColumn/Index',
            postData,
            config
          )
            .then(response => {
              ;
              console.log('save in db');
              console.log(response);
                const rowData = response.data;
                if(rowData!==""){
                  setIsColumnSave(true);
                  localStorage.setItem('gridColumns', rowData);
                }
                else{
                  setIsColumnSave(false);
                }
            })
            .catch((error) => {
              console.log('error in save db '+error);
                return error;
            });
        }
    const refreshToken = async () => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/token/Refresh',
            postData

        )
            .then((response) => {
               
                tempToken = response.data;
                localStorage.setItem('token', JSON.stringify(response.data));

                getModelInfo();


            })
            .catch((error) => {
                // ;
                if(error.code==="ERR_BAD_REQUEST"){
                    setSession("Session Expired");
                  }
                console.log("my error is " + error);
            })

    }

    const GetAllocModelData = async () => {
       
        setLoading(true);
       setEnableCombo(true);

        let token = tempToken;
        let AsOfId = JSON.parse(localStorage.getItem('userId'));
        
        let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
        let AcctId = lsSelectedAcct.acctId;
        let PageId = 1;
        let ModelId = JSON.parse(localStorage.getItem("modelId"));
        let invMix = 0;
        // if(ModelId==-1)
        // invMix=1;
        // if (lsSelectedAcct !== null) {
        //     AcctId = lsSelectedAcct.acctId;
        // }
        const postData = { AsOfId, AcctId, ModelId, PageId,invMix };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/RTAssetAllocationVsModel',
            postData,
            config
        )
            .then(response => {
              
                //  console.log(response);
              
                const rowData = response.data;

                // for(let i=0;i<rowData.t1.length;i++){
                //     rowData.t1[i].mdlwegh = parseNumber(formatNumber(rowData.t1[i].mdlwegh,"n2"));
                // }

                setAssetAllocModelRptData(rowData);
                getColumnStateDb();
             
                if (lsSelectedAcct !== null) {
                    SetselAcct(lsSelectedAcct);
                }
                else {
                    //SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
                }
               // getColumnStateDb();
                setLoading(false);
                setEnableCombo(false);
               
            })
            .catch((error) => {
             
                if (error.response.status === 401 )

                {
                  refreshToken();


                }
                return error;
            });

    }





    const handleChange = (event) => {
        
        if (event.target.value === null||loading) {
            //SetselAcct('');
            SetselAcct(selAcct);
            // GetAcctProfileData();
            // GetUpdatedAccountProfile(0);
        }
        else {
            ;
            SetselAcct(event.target.value);
            localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
             getModelInfo();
           // GetAllocModelData(event.target.value);
            //GetUpdatedAccountProfile(event.target.value.acctId);
        }



    };

    const signOut = () => {
    
        navigate("/");
        let token = JSON.parse(localStorage.getItem('token'));
        const postData = {};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        axios.post('/token/revoke',
        postData,
        config
        )
          .then((response) => {
          // ;
          // localStorage.setItem('token', '');
           //console.log(response);
          // navigate("/");
              
            // if (response.statusText === '') {
             
    
    
            // }
    
           
          })
          .catch((error) => {
            // ;
            console.log("my error is " + error);
          })
    
          // let tokenNew={token:''};
          // localStorage.setItem('token',JSON.stringify(tokenNew));
          // localStorage.setItem("AcctSelected",null);
          localStorage.removeItem('token');
          localStorage.clear();
        //firebaseApp.auth.signOut();
    
      }

    if (loading) {
        return (
          <>


       {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
        
            <Header></Header>
            <div className='my-1'>
                {/* <div className="rounded"></div> */}
                <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                        <ComboBox
                            style={{
                                width: "350px",
                            }}
                            data={selAcctData}
                            textField="extrnlAcctId"
                            dataItemKey="acctId"
                            filterable={true}
                            value={selAcct}
                            onChange={handleChange}
                            disabled={enableCombo}
                        // onFilterChange={filterChange}
                        />
                    </div>
                </div>
            </div>
            <Loading />
            </div>}</>
        )
      }
    return (
        <div>
            <Header></Header>
            <div className='my-1'>
                {/* <div className="rounded"></div> */}
                <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                        <ComboBox
                            style={{
                                width: "350px",
                            }}
                            data={selAcctData}
                            textField="extrnlAcctId"
                            dataItemKey="acctId"
                            filterable={true}
                            value={selAcct}
                            onChange={handleChange}
                            disabled={enableCombo}
                        // onFilterChange={filterChange}
                        />
                    </div>
                </div>
            </div>
            
            <AssetVsModelGrid data={assetAllocModelRptData} acct={selAcct} allmodelData={modelData} selModelId={modelId} flag={isColumnSave} />
        </div>
    )
}

export default AssetAllocModelRptMain
