import React from 'react';
import useState from 'react';
import TextField from '@mui/material/TextField';
import {ResponsiveContainer} from 'recharts';
import { Editor, EditorTools,EditorUtils,ProseMirror } from "@progress/kendo-react-editor";

import { InsertImage } from "./insertImageTool";
import { insertImagePlugin } from "./insertImagePlugin";
import { insertImageFiles } from "./utils";
const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
    ForeColor,
    BackColor
  } = EditorTools;

const CoverPageWiget = ({param}) => {
  
  
  const [value, setValue] = React.useState(
    EditorUtils.createDocument(
      new ProseMirror.Schema({
        nodes: EditorUtils.nodes,
        marks: EditorUtils.marks,
      }),
      localStorage.getItem('txtCoverPage')
    )
  );

  const onChange = (event) => {
    if(param!==1){
      localStorage.setItem('txtCoverPage',event.html);
      setValue(event.value);
    }
    
  };
      return(<>
       {/* <div className="h5 bg-dark text-white p-2">Text Field</div> */}
      
  
        
          {/* <ResponsiveContainer>
          <textarea onChange={handleChange}></textarea>
       </ResponsiveContainer> */}
          <ResponsiveContainer >
           <div>
              <h5 className='text-fitek p-1'>Cover Page</h5>


            
          <Editor 
      tools={param===1?[]:[
        [Bold, Italic, Underline, Strikethrough],
        [Subscript, Superscript],
        ForeColor,
        BackColor,
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        FontSize,
        FontName,
        FormatBlock,
        [Undo, Redo],
        [InsertImage],
        [Link, Unlink , ViewHtml],
        [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
        [DeleteRow, DeleteColumn, DeleteTable],
        [MergeCells, SplitCell],
      ]}
      contentStyle={{
        height: 'auto',
      }}
      value={value}
      onChange={onChange}
      defaultEditMode="div"
    /></div>
              </ResponsiveContainer>
  
        
      
  </>
  );
}

export default CoverPageWiget
