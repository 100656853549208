import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Loading from './loading';
import Header from './header';
import TopHoldingsGrid from './topHoldingsGrid';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

const TopHoldingsReport = () => {

    const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [selectedAcct, setSelectedAcct] = useState(0);
    const [loading, setLoading] = useState(true);
    const [isColumnSave, setIsColumnSave] = useState(false);
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [session,setSession]=useState("");
    const [enableCombo,setEnableCombo]=useState(false);
    const navigate = useNavigate();

    useEffect(() => {

        const fetchData = async () => {
            setLoading(true);
            setEnableCombo(true);
            try {
                //let data = location.state;

                let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
                getTopHoldingData();
                getColumnStateDb();
            } catch (error) {
                console.error(error.message);
            }

        }
        fetchData();
    }, []);

    const  getTopHoldingData=async()=>{
        setLoading(true);
        setEnableCombo(true);
        
        let token = tempToken;
        let AsOfId = JSON.parse(localStorage.getItem('userId'));

        let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
        let PageId=1;
        let rdoOption=0;
        let valueOrPercent=10;
       
        const postData = { AsOfId, AcctId,  PageId, rdoOption,valueOrPercent };
        const config = {
            headers: {
                'authorization': `Bearer ${token.token}`,
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }

        };
        await axios.post('/RTTopHoldingsReport',
        postData,
            config
        ).then((response => {
            
            const rowData = response.data;
            setAssetAllocModelRptData(rowData.t1);
            getColumnStateDb();
            setLoading(false);
            setEnableCombo(false);
            //postData.modelId = response.data.acctMdl[0].modelId;
            console.log(rowData)
        })
        )

            .catch((error) => {

                
                if (error.response.status === 401) {
                    refreshToken();


                }


                // return error;
            });

    }



    const handleChange = (event) => {
        //
        if (event.target.value === null||loading) {
            //SetselAcct('');
            SetselAcct(selAcct);
            // GetAcctProfileData();
            // GetUpdatedAccountProfile(0);
        }
        else {
            SetselAcct(event.target.value);
            localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
            getTopHoldingData(event.target.value);
            //GetUpdatedAccountProfile(event.target.value.acctId);
        }



    };

      const getColumnStateDb = async () =>{
            //Storing column settings in DB
            
              let token = JSON.parse(localStorage.getItem('token'));
              let UserId = JSON.parse(localStorage.getItem('userId'));
              let GridId = 25;//let 1 for Portfoliholdings Grid
              
              const postData = {UserId, GridId};
              const config = {
                headers: {
                  'authorization': `Bearer ${token.token}`,
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                }
            
              };
              await axios.post('/RTGetGridColumn/Index',
                postData,
                config
              )
                .then(response => {
                  
                  console.log('save in db');
                  console.log(response);
                    const rowData = response.data;
                    if(rowData!==""){
                      setIsColumnSave(true);
                      localStorage.setItem('gridColumns', rowData);
                    }
                    else{
                      setIsColumnSave(false);
                    }
                })
                .catch((error) => {
                  console.log('error in save db '+error);
                    return error;
                });
            }

    const refreshToken = async () => {
        
        let token = tempToken;
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        await axios.post('/token/Refresh',
          postData
    
        )
          .then((response) => {
            
            tempToken = response.data;
            localStorage.setItem('token', JSON.stringify(response.data));
    
            getTopHoldingData();
    
    
          })
          .catch((error) => {
            // 
            if(error.code==="ERR_BAD_REQUEST"){
              setSession("Session Expired");
            }
            console.log("my error is " + error);
          })
    
      }

      const signOut = () => {
    
        navigate("/");
        let token = JSON.parse(localStorage.getItem('token'));
        const postData = {};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        axios.post('/token/revoke',
        postData,
        config
        )
          .then((response) => {
          // 
          // localStorage.setItem('token', '');
           //console.log(response);
          // navigate("/");
              
            // if (response.statusText === '') {
             
    
    
            // }
    
           
          })
          .catch((error) => {
            // 
            console.log("my error is " + error);
          })
    
          // let tokenNew={token:''};
          // localStorage.setItem('token',JSON.stringify(tokenNew));
          // localStorage.setItem("AcctSelected",null);
          localStorage.removeItem('token');
          localStorage.clear();
        //firebaseApp.auth.signOut();
    
      }

    if (loading) {
        return (
          <>
            {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
            <Header></Header>
            <div className='my-1'>
                {/* <div className="rounded"></div> */}
                <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                        <ComboBox
                            style={{
                                width: "350px",
                            }}
                            data={selAcctData}
                            textField="extrnlAcctId"
                            dataItemKey="acctId"
                            filterable={true}
                            value={selAcct}
                            onChange={handleChange}
                            disabled={enableCombo}
                        // onFilterChange={filterChange}
                        />
                    </div>
                </div>
            </div>
            <Loading />
            </div>}</>
        )
      }
    return (
        <div>
            <Header></Header>
            <div className='my-1'>
                {/* <div className="rounded"></div> */}
                <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
                        <ComboBox
                            style={{
                                width: "350px",
                            }}
                            data={selAcctData}
                            textField="extrnlAcctId"
                            dataItemKey="acctId"
                            filterable={true}
                            value={selAcct}
                            onChange={handleChange}
                            disabled={enableCombo}
                        // onFilterChange={filterChange}
                        />
                    </div>
                </div>
            </div>

        <TopHoldingsGrid data={assetAllocModelRptData} acct={selAcct} flag={isColumnSave}/>
            
        </div>
    )
}

export default TopHoldingsReport
