import React from 'react';
import useState from 'react';
import TextField from '@mui/material/TextField';
import {ResponsiveContainer} from 'recharts';
import { Editor, EditorTools,EditorUtils,ProseMirror } from "@progress/kendo-react-editor";
const {
    Bold,
    Italic,
    Underline,
    Strikethrough,
    Subscript,
    Superscript,
    AlignLeft,
    AlignCenter,
    AlignRight,
    AlignJustify,
    Indent,
    Outdent,
    OrderedList,
    UnorderedList,
    Undo,
    Redo,
    FontSize,
    FontName,
    FormatBlock,
    Link,
    Unlink,
    InsertImage,
    ViewHtml,
    InsertTable,
    AddRowBefore,
    AddRowAfter,
    AddColumnBefore,
    AddColumnAfter,
    DeleteRow,
    DeleteColumn,
    DeleteTable,
    MergeCells,
    SplitCell,
    ForeColor,
    BackColor
  } = EditorTools;

const TextBoxConclusion = ({param}) => {
    // const [value, setValue] = useState('');

    const [value, setValue] = React.useState(
      EditorUtils.createDocument(
        new ProseMirror.Schema({
          nodes: EditorUtils.nodes,
          marks: EditorUtils.marks,
        }),
        localStorage.getItem('txtConclusion')
      )
    );
    const onChange = (event) => {
      if(param!==1){
        localStorage.setItem('txtConclusion',event.html);
        setValue(event.value);
      }
     
    };
   
      return(<>
      <h5 className='text-fitek p-1'>Conclusion


</h5>
          <ResponsiveContainer >
          <Editor
      tools={param===1?[]:[
        [Bold, Italic, Underline, Strikethrough],
        [Subscript, Superscript],
        ForeColor,
        BackColor,
        [AlignLeft, AlignCenter, AlignRight, AlignJustify],
        [Indent, Outdent],
        [OrderedList, UnorderedList],
        FontSize,
        FontName,
        FormatBlock,
        [Undo, Redo],
        [Link, Unlink, InsertImage, ViewHtml],
        
        [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
        [DeleteRow, DeleteColumn, DeleteTable],
        [MergeCells, SplitCell],
      ]}
      contentStyle={{
        height: 'auto',
      }}
      value={value}
      onChange={onChange}
      defaultEditMode="div"
     // defaultContent={content}
    />
              </ResponsiveContainer>
  
        
      
  </>
  );
}

export default TextBoxConclusion
