import React from 'react'
import { useState, useEffect } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import Moment from 'moment';
import {
    Sparkline,
    Chart,
    ChartSeries,
    ChartSeriesItem,
    ChartAxisDefaults,
    ChartCategoryAxis,
    ChartSeriesDefaults,
    ChartCategoryAxisItem,
    ChartTitle,
    ChartLegend,
    LegendItemClickEvent,
    ChartValueAxis,
    ChartValueAxisItem,
    ChartTooltip,
} from "@progress/kendo-react-charts";
import {
    AutoComplete,
    ComboBox,
    MultiColumnComboBox,
    DropDownList,
    MultiSelect,
    DropDownTree,
} from "@progress/kendo-react-dropdowns";
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ColumnMenu, ColumnMenuCheckboxFilter } from "./columnMenu";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import Modal from 'react-bootstrap/Modal';
import Loading from './loading';
import Enumerable from 'linq';
import { orderBy } from "@progress/kendo-data-query";
import BankLogoPage from './bankLogoPage';
import { Card, DropdownButton } from 'react-bootstrap';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import { FaFileExcel, FaFilePdf } from 'react-icons/fa';
import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";

const AccountProfileDetails = ({ chartColor, data, acct, allmodelData, selModelId, dispAssetClass }) => {
    
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [sortOrder, setSortOrder] = React.useState([
        {
            field: "groupId",
            dir: "asc",
        },
    ]);

    // For Preview Active Report Start
    const [show, setShow] = React.useState(false);
    //const [loadingChild, setLoadingChild] = useState(true);
    const viewerRef = React.useRef();
    async function loadReport() {

        // load report definition from the file
        
        const reportResponse = await fetch(
            "Accountprofile.rdlx-json"
        );
        
        const report = await reportResponse.json();
        return report;
    }
    async function openReport() {
        
        const report = await loadReport();
        
        
        const parameters=[
       
            {Name: 'pPriceDate', Value: [localStorage.getItem("processingDate")]},
            {Name: 'pAsofDate', Value: [localStorage.getItem("processingDate")]}
           
            // {Name: 'pEndBalance', Value: [ChkBoxState]}
          ]
          for(let i=0;i<data.length;i++){
            data[i].incptnDt = Moment(data[i].incptnDt).format('MM/DD/YYYY');
          }
        report.DataSources[0].ConnectionProperties.ConnectString =
            "jsondata=" + JSON.stringify(data);
        
       
        //if(loadingChild==false)
        viewerRef.current.Viewer.open(report,{ ReportParams: parameters });
    }
    //For Preview Active Report End
    const [sort, setSort] = React.useState([]);
    

    

    const [selChangeModel, setSelChangeModel] = React.useState([]);


    const accountVsModelFinalData = orderBy(Enumerable.from(data)
        .join(
            chartColor,
            pk => pk.groupId,
            fk => fk.mjrAstTypId,
            (left, right) => ({ ...left, ...right })
        )
        .toArray(), sortOrder);




    const initialDataState = {};
    const initialDataStatePortfolio = {};
    const initialDataStateTopHolding = {};

    let _pdfExport;
    const [dataState, setDataState] = React.useState();
    const [resultState, setResultState] = React.useState(
        process(data, initialDataState)
    );
    const [dataStatePortfolio, setDataStatePortfolio] = React.useState();


    const [dataStateTopHolding, setDataStateTopHolding] = React.useState();

    //const [show, setShow] = React.useState(false);
    const handleClose = () => setShow(false);
    const _export = React.useRef(null);
    const showpreview = async () => {
        setShow(true);
    }
    const excelExport = () => {

        setShow(true);
        openReport();
        // if (_export.current !== null) {
        //     _export.current.save(data);
        // }
    };

    let allPDFExport;
    const printPDF = async () => {

        if (allPDFExport !== null) {
            allPDFExport.save();
        }
    };

    const PageTemplate = (props) => {
        return (
            <div>
                <div
                    style={{
                        position: "absolute",
                        top: "1px",

                        width: "98%",
                        borderBottom: "1px solid #bce8f1"

                    }}
                >
                    <div className='row d-flex mx-3'>
                        <div className='col text-start'>
                            <a className='px-2'  ><BankLogoPage /></a>

                        </div>
                        <div className='col text-end px-5 py-2'>
                            <h2 className='reportheading fs-6 col-md-3 col-lg-3 col-sm-10'  >Account Profile Report </h2>

                        </div>


                    </div>




                    {/* <div className='fw-bold  text-center'>
                        Account Profile</div> */}
                </div>

                <div
                    style={{
                        position: "absolute",
                        bottom: "10px",
                        right: "10px",
                        //  borderTop: "1px solid #bce8f1"
                    }}
                >
                    Page {props.pageNum} of {props.totalPages}
                </div>
            </div>
        );
    };
    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }

    //const labelContent = (e) => `${e.value.toFixed(2)}%`;
    const labelContent = (e) => `${formatNumber(e.value, "##,#.00")}%`;
    const labelContent1 = (props) => {

        let formatedNumber = Number(props.dataItem.marketPercent).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${formatNumber(props.dataItem.marketPercent, "##,#.00")}%`;
    };

    const labelContentAccVMdl = (props) => {

        let formatedNumber = Number(props.dataItem.mdlWegh).toLocaleString(undefined, {
            style: "percent",
            minimumFractionDigits: 2,
        });
        return `${props.category}  ${formatNumber(props.dataItem.mdlWegh, "##,#.00")}%`;
    };

    const defaultTooltipRender = ({ point }) => `${formatNumber(point.value, "##,#.00")}%`;

    const totalSum = (props) => {
        const field = props.field || "";
        const total = data.reduce((acc, current) => acc + current[field], 0);
        return (
            <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
                {formatNumber(total, "##,#.00")}
            </td>
        );
    };
    const totalYield = (props) => {
        
        const field = props.field || "";
        const totalIncome = data.reduce((acc, current) => acc + current["income"], 0);
        const totalMarket = data.reduce((acc, current) => acc + current["market"], 0);
        const totalYeild = (totalIncome / totalMarket) * 100;
        return (
            <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
                {formatNumber(totalYeild, "##,#.00")}
            </td>
        );
    };











    const onDataStateChange = React.useCallback((e) => {

        setDataState(e.dataState);
        // let gridData = JSON.parse(localStorage.getItem('gridData'));
        // const groups = e.dataState.group;

        // if (groups) {
        //   groups.map((group) => (group.aggregates = aggregates));
        // }
        // e.dataState.group = groups;
        setResultState(process(data, e.dataState));
    }, []);



    const menuWithExcelCheck = (props) => {
        
        return (
            <div>

                <ColumnMenuCheckboxFilter
                    {...props}

                    data={data}

                />
            </div>)
    };






    const grid = (
        <Grid style={{ height: "auto" }}
            data={resultState}
            //resizable={true}
            //reorderable={true}
            sortable={true}

            onDataStateChange={onDataStateChange}
            {...dataState}

        >

            <Column field="groupName" menu={true} title="Asset Class" width="400px"  />
            <Column field="txCstAmt" menu={true} title="Total Cost($)" width="auto" footerCell={totalSum}  cell={NumberCell} filter={"numeric"} headerClassName='rightHeader' />
            <Column field="market" menu={true} title="Market Value($)" width="auto" footerCell={totalSum}  cell={NumberCell} filter={"numeric"} headerClassName='rightHeader' />

            <Column field="income" menu={true} title="Income($)" width="auto" footerCell={totalSum}  cell={NumberCell} filter={"numeric"} headerClassName='rightHeader' />

            <Column field="yield" menu={true} title="Yield(%)" width="auto" footerCell={totalYield}  cell={NumberCell} filter={"numeric"} headerClassName='rightHeader' />
            <Column field="marketPercent" title="Market Value(%)" width="auto" footerCell={totalSum}  cell={NumberCell} filter={"numeric"} headerClassName='rightHeader' />

        </Grid>
    );
    
    console.log(data);
    const incptnDt = Moment(data[0].incptnDt).format('MM/DD/YYYY')
    return (
        <div>
            <div >
            <div className='fs-6 row mt-2 mx-2 px-1 reportheading mb-2'>Account Profile Report</div>
                <div className="card-header row d-flex justify-content-between my-2 tableheader">
                    <div className="col text-end">
                        Due to rounding, percentage may not equal 100.


                    </div>
                    <div className="col text-end">
                        {/* <DropdownButton id="dropdown-export-button" title="Export" variant='outline-primary' size='sm'>
                            <DropdownItem onClick={excelExport}> <span className='px-1'><FaFileExcel /></span>Excel</DropdownItem>
                            <DropdownItem onClick={showpreview}><span className='px-1'><FaFilePdf /></span>PDF</DropdownItem>

                        </DropdownButton> */}

<button
                  className='btn btn-outline-primary btn-sm'
                  onClick={excelExport}>Preview</button>
                    </div>


                </div>
                <div className='row d-flex justify-content-center'>
                    <Card style={{ width: 1400, borderRadius: '15px', boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)' }}>
                        <div className='row d-flex justify-content-between'>

                            <div className='col text-start'>

                                <div className='reportheading fs-6' ><p>Administrative Information</p></div>
                                <p><b>Account Title:</b> {data[0].accountName}</p>
                                <p><b>Account Number:</b> {data[0].accountNumber}</p>
                                <p><b>Administrator:</b> {data[0].administrator}</p>
                                <p><b>Capacity:</b> {data[0].capacity}</p>
                                <p><b>Inception Date:</b> {incptnDt}</p>
                                <p><b>YTD Gain/Loss:</b> {data[0].ytdGainLoss}</p>
                            </div>
                            <div className='col text-end'>
                                <div className='reportheading fs-6' ><p>Investment Profile</p></div>
                                <p><b>Investment Officer:</b> {data[0].investmentOfficer}</p>
                                <p><b>Investment Authority:</b> {data[0].invAuthority}</p>
                                <p><b>Investment Objective:</b> {data[0].invObjective}</p>
                                <p><b>As of Date:</b> {localStorage.getItem("processingDate")}</p>
                                <p><b>Price Date:</b> {localStorage.getItem("processingDate")}</p>

                            </div></div></Card></div><p></p>
                <div className="col col-md-12 col-sm-12 py-1">


                    <ExcelExport data={data} ref={_export} fileName={JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId + "_AcctHolding.xlsx"}>

                        <ExcelExportColumnGroup
                            title={"Account Number: " + JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId + "  Processing Date: " + localStorage.getItem("processingDate")}
                            headerCellOptions={{
                                textAlign: "left",
                            }}
                        >
                            {grid}
                            {/*
                                <ExcelExportColumn field="groupName" title="Asset Class" width={170} />
                                <ExcelExportColumn field="txCstAmt" title="Total Cost($)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="market" title="Market Value($)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="income" title="Income($)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="yield" title="Yield(%)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
                                <ExcelExportColumn field="marketPercent" title="Market Value(%)" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
*/}
                        </ExcelExportColumnGroup>
                    </ExcelExport>


                    <Chart style={{ height: "440px" }}>

                        <ChartLegend position="bottom" />

                        <ChartSeries>
                            <ChartSeriesItem
                                type="pie"
                                data={accountVsModelFinalData}
                                colorField='chrtColorId'
                                field="marketPercent"
                                categoryField="groupName"
                                autoFit={true}
                                labels={{
                                    visible: true,
                                    content: labelContent1,
                                }}
                            />
                        </ChartSeries>
                    </Chart>
                    <div className='row d-flex justify-content-center'>Liabilities Excluded From Summary</div>
                    {grid}
                    {/* <div className="row mx-1 my-2 pb-3 mb-5">

                            <div className="col col-md-6 col-sm-10 py-2">
                                <div className="col-md-12 card-header tableheader">Account Holdings  </div>
                                <div className="card rounded h-100">

                                    <div className="w-100">
                                        <Chart style={{ height: "440px" }}>
                                            
                                            <ChartLegend position="bottom" />

                                            <ChartSeries>
                                                <ChartSeriesItem
                                                    type="pie"
                                                    data={data}
                                                    colorField='chartColorCOde'
                                                    field="marketPercent"
                                                    categoryField="groupName"
                                                    autoFit={true}
                                                    labels={{
                                                        visible: true,
                                                        content: labelContent1,
                                                    }}
                                                />
                                            </ChartSeries>
                                        </Chart>

                                    </div>
                                </div>
                            </div>

                            

                        </div>


                        

                        

                        

                       
                    </div>
                </div>*/}


                    {/* <Modal show={show} onHide={handleClose} fullscreen={true} size="xl" >
                    <Modal.Header><div className="row w-100 tableheader">
                        <div className='col'>
                            <Modal.Title>Account Profile Report - Pdf Preview</Modal.Title>
                        </div>
                        <div className='col text-end'>
                            <button
                                className="btn btn-outline-primary btn-sm mx-2"
                                onClick={printPDF}
                            >
                                Print
                            </button>
                            <button className="btn btn-outline-danger  btn-sm" onClick={handleClose}>
                                Close


                            </button>
                        </div>


                    </div>
                    </Modal.Header>
                    <Modal.Body>
                        <PDFExport
                            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
                            pageTemplate={PageTemplate}
                            forcePageBreak=".page-break"
                            paperSize="A4"
                            scale={0.5}
                            allPages={true}

                            landscape={true}
                            repeatHeaders={true}

                            ref={(pdfExport) => (allPDFExport = pdfExport)}>

                            Account Name : {acct.extrnlAcctId}
                            <hr></hr>
                            <div className='row d-flex justify-content-center'>
                     <Card style={{width:1400, borderRadius:'15px', boxShadow:'0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)'}}>
                    <div className='row d-flex justify-content-between'>
                    
                <div className='col text-start'>
                   
                <div className='reportheading fs-6' ><p>Administrative Information</p></div>
<p><b>Account Title:</b> {data[0].accountName}</p>
<p><b>Account Number:</b> {data[0].accountNumber}</p>
<p><b>Administrator:</b> {data[0].administrator}</p>
<p><b>Capacity:</b> {data[0].capacity}</p>
<p><b>Inception Date:</b> { incptnDt}</p>
<p><b>YTD Gain/Loss:</b> {data[0].ytdGainLoss}</p>
</div>
<div className='col text-end'>
<div className='reportheading fs-6' ><p>Investment Profile</p></div>
<p><b>Investment Officer:</b> {data[0].investmentOfficer}</p>
<p><b>Investment Authority:</b> {data[0].invAuthority}</p>
<p><b>Investment Objective:</b> {data[0].invObjective}</p>
<p><b>As of Date:</b> {localStorage.getItem("processingDate")}</p>
<p><b>Price Date:</b> {localStorage.getItem("processingDate")}</p>

</div></div></Card></div><p></p>
                            <Chart style={{ height: "440px" }}>
                                            
                                            <ChartLegend position="bottom" />

                                            <ChartSeries>
                                                <ChartSeriesItem
                                                    type="pie"
                                                    data={accountVsModelFinalData}
                                                    colorField='chrtColorId'
                                                    field="marketPercent"
                                                    categoryField="groupName"
                                                    autoFit={true}
                                                    labels={{
                                                        visible: true,
                                                        content: labelContent1,
                                                    }}
                                                />
                                            </ChartSeries>
                                        </Chart>
                                        <div className='row d-flex justify-content-center'>Liabilities Excluded From Summary</div>
                                        {grid}

                            
                            
                            

                        </PDFExport>

                    </Modal.Body>
                    <Modal.Footer>



                        <button className="btn btn-primary  btn-sm" onClick={handleClose}>
                            Close
                        </button>


                    </Modal.Footer>

                </Modal>  */}
                    <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
                        <Modal.Body>
                            <div id="viewer-host">
                            <Viewer ref={viewerRef}/>
                            </div>
                        </Modal.Body>
                        
                        
                        <Modal.Footer>



                            <button className="btn btn-primary  btn-sm" onClick={handleClose}>
                                Close
                            </button>


                        </Modal.Footer>

                    </Modal>
                </div></div>
        </div>
    )
}

export default AccountProfileDetails
