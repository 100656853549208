import React from 'react'
import { Circles,Oval,BallTriangle,RotatingLines,RevolvingDot } from 'react-loader-spinner';

import logobar from '../Images/loadingbar.gif';

const Loading = () => {
    return (

        
        <div className="loadingDiv d-flex justify-content-center align-items-center p-5">
             <div className="loading-spinner"></div>
             <div>
      {/* <img  src={logobar}></img> */}
      <Circles
  strokeColor="#006fba"
  strokeWidth="5"
  animationDuration="0.95"
  width="80"
  visible={true}
/>
    </div>
        </div>
    )
}

export default Loading