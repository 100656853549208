import React from "react";
import useState from "react";
import TextField from "@mui/material/TextField";
import Enumerable from "linq";
import { ResponsiveContainer } from "recharts";
import {
  Editor,
  EditorTools,
  EditorUtils,
  ProseMirror,
} from "@progress/kendo-react-editor";
const {
  Bold,
  Italic,
  Underline,
  Strikethrough,
  Subscript,
  Superscript,
  AlignLeft,
  AlignCenter,
  AlignRight,
  AlignJustify,
  Indent,
  Outdent,
  OrderedList,
  UnorderedList,
  Undo,
  Redo,
  FontSize,
  FontName,
  FormatBlock,
  Link,
  Unlink,
  InsertImage,
  ViewHtml,
  InsertTable,
  AddRowBefore,
  AddRowAfter,
  AddColumnBefore,
  AddColumnAfter,
  DeleteRow,
  DeleteColumn,
  DeleteTable,
  MergeCells,
  SplitCell,
  ForeColor,
  BackColor,
} = EditorTools;

const TableContentWiget = ({ param, widgets, positions }) => {
  
  var positionCopy = positions;
  var textstr = "";
  var isEmpty = 0;
  var widget;
  var position;
  var min = positions[0].order;
  var prevrowsize = 0;
  var pageNo = 2;
  var colsize = 0;
  var rowsize = 0;
  //widgets=localStorage.getItem("widgetList")===null?widgets:localStorage.getItem("widgetList");
  var addrowsize;
  //positionCopy.sort((a, b) => (a.order > b.order) ? 1 : -1);
  //for(var i=0;i<widgets.length;i++){
  console.log("Tableof Contents", widgets);
  for (var j = 0; j < widgets.length; j++) {
    //if(positionCopy[i].widgetId===widgets[j].id)
    //{
    if (widgets[j].header !== "Table Of Contents") {
      // if(isEmpty!==0){

      //   if( positionCopy[i].col!==(positionCopy[i-1].col)){

      //   }
      // }
      if (widgets[j].header === "Asset Market By Capital Report") {
        widgets[j].header = "Assets by Market Capital Report";
      }
      textstr = textstr + "<li>" + widgets[j].header + "</li>";

      //textstr=textstr+"<li>"+widgets[j].header+"    "+pageNo+"</li>";
      isEmpty = +1;

      //  colsize=positionCopy[i].colSpan+colsize;
      // rowsize=positionCopy[i].rowSpan>rowsize?positionCopy[i].rowSpan:rowsize;

      // //

      //  if(colsize>=6)
      //  {
      //   rowsize=rowsize+prevrowsize;

      //  if(rowsize<4){

      //  }
      //  else{
      //   pageNo=pageNo+1;
      //   prevrowsize=0;
      //   rowsize=0;
      //  }

      //    colsize=0;
      //  }
      // }
    }
  }
  // }
  // if(widgets[j].active===true && widgets[j].header!=="Table Of Contents"){
  //   textstr=textstr+"<li>"+widgets[j].header+"</li>";
  //   isEmpty=+1;
  //     }
  //   }

  // }

  console.log("widgets:");
  console.log(positionCopy);

  if (isEmpty === 0) {
    textstr = "";
  } else {
    textstr = "<ol>" + textstr + "</ol>";
  }
  console.log(textstr);
  const [value, setValue] = React.useState(
    EditorUtils.createDocument(
      new ProseMirror.Schema({
        nodes: EditorUtils.nodes,
        marks: EditorUtils.marks,
      }),
      textstr
      // widgets[0].header

      // localStorage.getItem('txtTableOfContent')
    )
  );

  const onChange = (event) => {
    

    // localStorage.setItem('txtTableOfContent',(event.html));
    // setValue(event.value);
  };
  return (
    <>
      <ResponsiveContainer>
        <div>
          <h5 className="text-fitek p-1">Table of Contents</h5>
          <Editor
            // tools={param===1?[]:[
            //   [Bold, Italic, Underline, Strikethrough],
            //   [Subscript, Superscript],
            //   ForeColor,
            //   BackColor,
            //   [AlignLeft, AlignCenter, AlignRight, AlignJustify],
            //   [Indent, Outdent],
            //   [OrderedList, UnorderedList],
            //   FontSize,
            //   FontName,
            //   FormatBlock,
            //   [Undo, Redo],
            //   [Link, Unlink, InsertImage, ViewHtml],
            //   [InsertTable],
            //   [AddRowBefore, AddRowAfter, AddColumnBefore, AddColumnAfter],
            //   [DeleteRow, DeleteColumn, DeleteTable],
            //   [MergeCells, SplitCell],
            // ]}
            contentStyle={{
              height: "auto",
            }}
            value={textstr}
            onChange={onChange}
            defaultEditMode="div"
          />
        </div>
      </ResponsiveContainer>
    </>
  );
};

export default TableContentWiget;
