import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import Loading from './loading';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import Header from './header';
import TimeWtdRtnRptGrid from './TimeWtdRtnRptGrid'
import { CustomCalendar } from "./customCalendar";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import refreshFunction from './refreshFunc';
import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
const TimeWtdRtnRpt = () => {
  const [TimeWtdRtnRptRptData, populateTimeWtdRtnRptRptData] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
  const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [toDate, setToDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const [frmDate, setFrmDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const minFrmDt = new Date(localStorage.getItem("processingDate"));
  minFrmDt.setFullYear(minFrmDt.getFullYear()-4);
      const maxFrmDt = new Date(localStorage.getItem("processingDate"));
    
      const[isDisabled,setisDisabled]= React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem('token'));
  const [session,setSession]=useState("");
  const navigate = useNavigate();
  const [reportData,setReportData]= useState([]);
  const [enableCombo,setEnableCombo]=useState(false);

  useEffect(() => {

    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;

        //setEmail(email);
        GetTimeWtdRtnRpteData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }

    }
    fetchData();
  }, []);
  function setDate (val){
    
  
    // if(val.language === '1'){
      //  const fDate = new Date("2021-04-22");
        
    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
      if (val == null)
      {
       // const fDate = new Date("2021-01-22");
        setToDate(toDate);
        
      }
       else{ 
        setToDate(val);
       }
    //     const setisDisabled = true;
    // }
    //setToDate(val);
  }
  function setfrmDate (val){
    
  
    // if(val.language === '1'){
      //  const fDate = new Date("2021-04-22");
        
    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
      if (val == null)
      {
       // const fDate = new Date("2021-01-22");
        setFrmDate(frmDate);
        
      }
       else{ 
        setFrmDate(val);
       }
    //     const setisDisabled = true;
    // }
    //setToDate(val);
  }
  const searchClick = () => {
          
    GetTimeWtdRtnRpteData();
  };
  const formSubmit=(event)=>{
    GetTimeWtdRtnRpteData();
  }
  const GetTimeWtdRtnRpteData = async () => {
    //
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let AsOfId = JSON.parse(localStorage.getItem('userId'));
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = 0;
    let PageId = 1;
    let netFeeId = 1;
    let StDate=frmDate
    let ToDate=toDate;
    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }
    let mConsolidationId=0;
    let RltnshpCd="";

    debugger;
    const postData = { AsOfId, AcctId, netFeeId, PageId,StDate,ToDate,mConsolidationId,RltnshpCd };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    debugger;

    await axios.post('/RTTimeWtdRtnOfSlctdPrds',
      postData,
      config
    )
      .then(response => {
        debugger;
          console.log("resonse 12",response);
        
        const rowData = response.data;
        populateTimeWtdRtnRptRptData(rowData.lstRTTimeWtdReturnT1 == null
          ? []
          : rowData.lstRTTimeWtdReturnT1);
        populateAccountListData(rowData.lstAcctTmWtdRtnList == null
          ? []
          : rowData.lstAcctTmWtdRtnList)
        setSelAcctData(selAcctData)
        setReportData(rowData)
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        }
        else {
          SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        }
        getColumnStateDb();
        setLoading(false);
        setFlag(1);
      })
      .catch((error) => {
        
        if (error.response.status === 401) {
          refreshToken();


        }
        // return error;
      });

  }

    debugger;


  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        
        tempToken = response.data;
        localStorage.setItem('token', JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();


      })
      .catch((error) => {
        // 
        if(error.code==="ERR_BAD_REQUEST"){
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      })

  }

  const getColumnStateDb = async () =>{
    //Storing column settings in DB
    
      let token = JSON.parse(localStorage.getItem('token'));
      let UserId = JSON.parse(localStorage.getItem('userId'));
      let GridId = 33;
      
      const postData = {UserId, GridId};
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    
      };
      await axios.post('/RTGetGridColumn/Index',
        postData,
        config
      )
        .then(response => {
          
          console.log('save in db');
          console.log(response);
            const rowData = response.data;
            if(rowData!==""){
              setIsColumnSave(true);
              localStorage.setItem('gridColumns', rowData);
            }
            else{
              setIsColumnSave(false);
            }
        })
        .catch((error) => {
          console.log('error in save db '+error);
            return error;
        });
    }


  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    }
    else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }



  };

  const signOut = () => {
    
    navigate("/");
    let token = JSON.parse(localStorage.getItem('token'));
    const postData = {};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
  
    };
    axios.post('/token/revoke',
    postData,
    config
    )
      .then((response) => {
      // 
      // localStorage.setItem('token', '');
       //console.log(response);
      // navigate("/");
          
        // if (response.statusText === '') {
         
  
  
        // }
  
       
      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      })
  
      // let tokenNew={token:''};
      // localStorage.setItem('token',JSON.stringify(tokenNew));
      // localStorage.setItem("AcctSelected",null);
      localStorage.removeItem('token');
      localStorage.clear();
    //firebaseApp.auth.signOut();
  
  }



  if (loading) {
    return (
      <>
       {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
        <Header></Header>
        <Loading />
        </div>}</>
    )
  }
  return (
    <form onSubmit={formSubmit}  >
    <div>
      <Header></Header>
      
      <div className='mt-1'> 
  <div className='px-2 mx-2 bg-light shadow-sm rounded'>
    <div className="table-responsive">
      <table className="table table-borderless mb-0">
        <tbody>
          <tr>
         
            <td className='text-start' style={{ verticalAlign: 'bottom', minWidth: '240px' }}>
              <span className='px-2 d-block d-md-inline'>Account(s)</span>
              <ComboBox
                style={{ width: "100%", maxWidth: "350px" }}
                data={selAcctData}
                textField="extrnlAcctId"
                dataItemKey="acctId"
                filterable={true}
                value={selAcct}
                onChange={handleChange}
              />
            </td>

         
            <td style={{ verticalAlign: 'bottom', minWidth: '180px' }}>
              <span className='py-1 d-block d-md-inline'>From</span>
              <DatePicker
              style={{ width: "100%", maxWidth: "200px" }}
                id="dpFrm"
                defaultValue={frmDate}
                format="MM/dd/yyyy"
                calendar={CustomCalendar}
                min={minFrmDt}
                formatPlaceholder={{
                  year: "yyyy",
                  month: "mm",
                  day: "dd",
                }}
                max={maxFrmDt}
                disabled={isDisabled}
                onChange={(e) => {
                  setfrmDate(e.value);
                }}
                
              />
            </td>

           
            <td style={{ verticalAlign: 'bottom', minWidth: '180px' }}>
              <span className='py-1 d-block d-md-inline'>To</span>
              <DatePicker
                id="dpTo"
                defaultValue={toDate}
                format="MM/dd/yyyy"
                calendar={CustomCalendar}
                min={minFrmDt}
                formatPlaceholder={{
                  year: "yyyy",
                  month: "mm",
                  day: "dd",
                }}
                max={maxFrmDt}
                disabled={isDisabled}
                onChange={(e) => {
                  setDate(e.value);
                }}
                style={{ width: "100%", maxWidth: "200px" }}
              />
            </td>

           
            <td style={{ verticalAlign: 'bottom' }}>
              <input type="submit" className='btn btn-primary btn-sm' value="Submit" style={{ width: "100%", maxWidth: "100px" }}/>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>

      {flag === 1 ?
        <TimeWtdRtnRptGrid data={TimeWtdRtnRptRptData} flag={isColumnSave} reportData = {reportData} />
        : <></>
      }
      
    </div>
    </form>
  )
}

export default TimeWtdRtnRpt


