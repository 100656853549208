import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import Loading from "./loading";
import Header from "./header";
import { CustomCalendar } from "./customCalendar";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { FaFilePdf } from "react-icons/fa";
import Modal from "react-bootstrap/Modal";
import BankLogoPage from "./bankLogoPage";
// import AcctSectBenchRptGrid from './AccountSectBenchGrid';
import "@progress/kendo-theme-material/dist/all.css";
import { filterBy } from "@progress/kendo-data-query";
import AcctPerfRptGrid from "./AcctPerfRptGrid";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "@telerik/kendo-intl";

const AcctPerfRpt = () => {
  const [AcctPerfRptData1, populateAcctPerfRptData1] = useState([]);
  const [AcctPerfRptData2, populateAcctPerfRptData2] = useState([]);
  const [AcctPerfRptData3, populateAcctPerfRptData3] = useState([]);
  const [ReportData, popReportData] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [enableCombo,setEnableCombo]=useState(false);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  //const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(true);
  // const [frmDate, setfrmDate] = React.useState("");
  var date = new Date(localStorage.getItem("processingDate"));

  //date.setMonth(date.getMonth() - 12);
  const [frmDate, setfrmDate] = React.useState(date);

  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [isDisabled, setisDisabled] = React.useState(false);
  const [flag, setFlag] = React.useState(false);
  const [flagHolding, setflagHolding] = React.useState(false);
  //const value = new Date("2022-04-22");
  const minFrmDt = new Date(2021, 8, 14);
  const maxFrmDt = new Date(2022, 8, 14);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      //
      setLoading(true);
      setEnableCombo(true);

      try {
        let asOfId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        GetAcctPerfRptData();
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctPerfRptData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  let allPDFExport;
  const printPDF = async () => {
    if (allPDFExport !== null) {
      allPDFExport.save();
    }
  };
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);

  function setDate(val) {
    // if(val.language === '1'){
    //     const fDate = new Date("2021-04-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
    //     const fDate = new Date("2021-01-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = true;
    // }
    if (val == null) {
      setfrmDate(frmDate);
    } else {
      setfrmDate(val);
    }
  }

  const searchClick = () => {
    GetAcctPerfRptData();
  };

  const formSubmit = (event) => {
    event.preventDefault();
    GetAcctPerfRptData();
  };

  const GetAcctPerfRptData = async () => {
    setLoading(true);
    setEnableCombo(true);

    //let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let lsSelectedAcct = JSON.parse(localStorage.getItem("ASectSelectedAcct"));
    let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    //let AcctId = 0;
    let PageId = 1;
    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }
    let StartDate = formatDate(frmDate, "MM/dd/yyyy");
    let EndDate = formatDate(toDate, "MM/dd/yyyy");
    const postData = { AsOfId, AcctId, PageId, StartDate, EndDate };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
debugger;
    await axios
      .post("/AccountPerformanceSummary", postData, config)
      .then((response) => {
        //  console.log(response);
        console.log("response", response);
        //
        const rowData = response?.data;

        populateAcctPerfRptData1(
          rowData.lstAccountPerformanceSummaryActivity == null
            ? []
            : rowData.lstAccountPerformanceSummaryActivity
        );
        populateAcctPerfRptData2(
          rowData.lstAccountPerformanceSummaryAllocation == null
            ? []
            : rowData.lstAccountPerformanceSummaryAllocation
        );
        populateAcctPerfRptData3(
          rowData.lstAccountPerformanceSummaryROR == null
            ? []
            : rowData.lstAccountPerformanceSummaryROR
        );
        populateAccountListData(
          rowData.lstAcctPerfAcctList == null ? [] : rowData.lstAcctPerfAcctList
        );
        // setSelAcctData(
        //   rowData.lstAcctPerfAcctList == null ? [] : rowData.lstAcctPerfAcctList
        // );
        setSelAcctData(selAcctData);
        popReportData(rowData);
        // if (lsSelectedAcct !== null) {
        //   SetselAcct(lsSelectedAcct);
        // }
        // else {
        //   SetselAcct(rowData.lstAcctPerfAcctList[0]);
        // }

        debugger;
        if (rowData.lstAccountPerformanceSummaryAllocation != null)
          localStorage.setItem(
            "AcctAllocInitialData",
            JSON.stringify(rowData.lstAccountPerformanceSummaryAllocation)
          );
        setLoading(false);
        setEnableCombo(false);
        setFlag(1);
      })
      .catch((error) => {
        console.log("error", error);

        if (error.response.status === 401) {
          refreshToken();
        }
        //return error;
      });
  };

  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };
  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };

  const handleChange = (event) => {
    //
    if (event.target.value === null || loading) {
      //SetselAcct('');
      SetselAcct(selAcct);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem("AcctSelected", JSON.stringify(selAcct));
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetAcctPerfRptData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Header></Header>
            <div className="my-1">
              {/* <div className="rounded"></div> */}
              <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
                <div className="col-md-4 col-lg-5 col-sm-11 text-start">
                  <span className="col-md-2 px-2">Account(s)</span>
                  <ComboBox
                    style={{
                      width: "350px",
                    }}
                    data={selAcctData}
                    textField="extrnlAcctId"
                    dataItemKey="acctId"
                    filterable={true}
                    value={selAcct}
                    onChange={handleChange}
                    onFilterChange={filterChange}
                    disabled={enableCombo}
                  />
                </div>
              </div>
            </div>
            <form onSubmit={formSubmit}>
              <div className="row d-flex align-items-end justify-content-center py-2 mx-1 border-0 shadow-none">
                <div className="col-md-3 col-lg-3 col-sm-10">
                  <span className="py-1">From</span>
                  <DatePicker
                    id="dpFrm"
                    defaultValue={frmDate}
                    format="MM/dd/yyyy"
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                    //min={minFrmDt}
                    max={
                      new Date(
                        `${frmDate.getFullYear()},${(frmDate.getMonth() + 1)
                          .toString()
                          .padStart(2, "0")},${frmDate
                          .getDate()
                          .toString()
                          .padStart(2, "0")}`
                      )
                    }
                    disabled={isDisabled}
                    onChange={(e) => {
                      setDate(e.value);
                    }}
                  />
                </div>

                <div className="col-md-3 col-lg-3 col-sm-10">
                  <span className="py-1">To</span>
                  <DatePicker
                    disabled={false}
                    formatPlaceholder={{
                      year: "yyyy",
                      month: "mm",
                      day: "dd",
                    }}
                    defaultValue={toDate}
                    format="MM/dd/yyyy"
                  />
                </div>

                <div className="col-md-2 col-lg-2 col-sm-10">
                  <input
                    type="submit"
                    className="btn btn-primary btn-sm"
                    value="Submit"
                  />
                </div>
              </div>
            </form>
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <div style={{ overflow: "hidden" }}>
      <Header></Header>
      <div className="my-1">
        {/* <div className="rounded"></div> */}
        <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-5 col-sm-11 text-start">
            <span className="col-md-2 px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
              disabled={enableCombo}
            />
          </div>
        </div>
      </div>
      <form onSubmit={formSubmit}>
        <div className="row d-flex align-items-end justify-content-center py-2 mx-1 border-0 shadow-none">
          <div className="col-md">
            <p className="reportheading fs-6">
              Account Performance Summary Report
            </p>
          </div>
          <div className="col-md-3 col-lg-3 col-sm-10">
            <span className="py-1">From</span>
            <DatePicker
              id="dpFrm"
              defaultValue={frmDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              //min={minFrmDt}
              max={
                new Date(
                  `${date.getFullYear()},${(date.getMonth() + 1)
                    .toString()
                    .padStart(2, "0")},${date
                    .getDate()
                    .toString()
                    .padStart(2, "0")}`
                )
              }
              disabled={isDisabled}
              onChange={(e) => {
                setDate(e.value);
              }}
            />
          </div>

          <div className="col-md-3 col-lg-3 col-sm-10">
            <span className="py-1">To</span>
            <DatePicker
              //disabled={false}
              defaultValue={toDate}
              max={date}
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              format="MM/dd/yyyy"
              onChange={(e) => {
                setToDate(e.value);
              }}
              calendar={CustomCalendar}
            />
          </div>

          <div className="col-md-2 col-lg-2 col-sm-10">
            <input
              type="submit"
              className="btn btn-primary btn-sm"
              value="Submit"
            />
          </div>
        </div>
      </form>
      {flag === 1 ? (
        <AcctPerfRptGrid
          data={AcctPerfRptData1}
          alloc={AcctPerfRptData2}
          ror={AcctPerfRptData3}
          reportdata={ReportData}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AcctPerfRpt;
