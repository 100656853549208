import React from "react";

import {
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartCategoryAxis,
  ChartCategoryAxisItem,
  ChartLegend,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { formatNumber, formatDate, parseNumber } from "@telerik/kendo-intl";

import { Viewer } from "@grapecity/activereports-react";
import "@grapecity/activereports/pdfexport";
import "@grapecity/activereports/htmlexport";
import "@grapecity/activereports/tabulardataexport";
import Modal from "react-bootstrap/Modal";
import { orderBy } from "@progress/kendo-data-query";

const AssetGrowthAndAllocationChart = ({ assetGrowthAndAllacData }) => {
  const initialSort = [
    {
      field: "yearEnd",
      dir: "desc",
    },
  ];
  const _ = require("lodash");
  const initialT1 = _.uniqWith(assetGrowthAndAllacData?.t1, _.isEqual);
  const t1Data = orderBy(initialT1, initialSort);
  const initialT2 = _.uniqWith(assetGrowthAndAllacData?.t2, _.isEqual);
  const t2Data = orderBy(initialT2, initialSort);

  
  // For Preview Active Report Start
  const [show, setShow] = React.useState(false);
  //const [loadingChild, setLoadingChild] = useState(true);
  const viewerRef = React.useRef();
  async function loadReport() {
    // load report definition from the file
    
    const reportResponse = await fetch("AssetGrowthAndAllocation.rdlx-json");
    
    const report = await reportResponse.json();
    return report;
  }
  async function openReport() {
    
    const report = await loadReport();

    

    for (let i = 0; i < t1Data.length; i++) {
      t1Data[i].marketInMillion = parseNumber(
        formatNumber(t1Data[i].marketInMillion, "n2"))
        t1Data[i].retVal = parseNumber(
          formatNumber(t1Data[i].retVal, "n2")
      );
    }

    // {retVal}

    report.DataSources[0].ConnectionProperties.ConnectString =
      "jsondata=" + JSON.stringify({ t1: t1Data, t2: t2Data });
    

    //if(loadingChild==false)
    viewerRef.current.Viewer.open(report);
  }
  //For Preview Active Report End
  const handleClose = () => setShow(false);
  const _export = React.useRef(null);
  const showpreview = async () => {
    setShow(true);
  };
  const excelExport = () => {
    
    setShow(true);
    openReport();
    // if (_export.current !== null) {
    //     _export.current.save(data);
    // }
  };

  const yearEnds = t1Data?.map((item) => item.yearEnd);
  const marketInMillion = t1Data?.map((item) => item.marketInMillion);

  const returnValue = t1Data.map((item) => item.retVal);

  const groupedByYear = t2Data.reduce((acc, item) => {
    const year = item.yearEnd;

    if (!acc[year]) {
      acc[year] = [];
    }

    acc[year].push({
      perfTmpltCtgryNm: item.perfTmpltCtgryNm,
      endMktVlAmt: item.endMktVlAmt,
    });

    return acc;
  }, {});

  const getUniqueCategoryNames = (data) => {
    const categories = new Set();

    for (const year in data) {
      const yearData = data[year];
      if (Array.isArray(yearData)) {
        yearData.forEach((item) => categories.add(item.perfTmpltCtgryNm));
      }
    }

    return Array.from(categories);
  };

  const categoryNames = getUniqueCategoryNames(groupedByYear);
  const t2Year = Object.keys(groupedByYear).reverse();

  const getCategoryValues = (data, categoryName) => {
    const values = [];
    for (const year in data) {
      const yearData = data[year];
      if (Array.isArray(yearData)) {
        const item = yearData.find(
          (entry) => entry.perfTmpltCtgryNm === categoryName
        );
        if (item) {
          values.push(item.endMktVlAmt);
        } else {
          values.push(0);
        }
      }
    }
    return values.reverse();
  };

  const NumberCell = (props) => {
    return (
      <td style={{ textAlign: "right" }}>
        {formatNumber(props.dataItem[props.field], "##,#.00")}
      </td>
    );
  };

  return (
    <div>
      <div className="text-end">
        <button
          className="btn btn-outline-primary btn-sm"
          onClick={excelExport}
        >
          Preview
        </button>
      </div>

      <>
        <div className="row d-flex justify-content-center align-items-center g-0">
          <div className="col-12">
            <p class="reportheading fs-6 h6">
              Asset Growth And Allocation Report
            </p>
          </div>
          <div className="col-6 wheight d-flex">
            <div className="card card-rounded wheight w-100">
              <div className="card-body">
                <h6 className="card-title">
                  Units Calendar Year Ending Market Values Over Time
                </h6>
                <Chart style={{ width: "100%" }} legendItemHover>
                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      categories={yearEnds}
                      title={{
                        text: "Year",
                      }}
                    />
                  </ChartCategoryAxis>

                  <ChartTooltip format="n2" />
                  <ChartSeries>
                    <ChartSeriesItem type="area" data={marketInMillion} />
                  </ChartSeries>
                  <ChartValueAxis>
                    <ChartValueAxisItem title={{ text: "Millions" }} />
                  </ChartValueAxis>
                </Chart>
              </div>
            </div>
          </div>
          <div className="col-6 wheight d-flex">
            <div className="card card-rounded h-100 w-100">
              <div className="card-body">
                <h6 className="card-title">
                  Total Return % Calendar Year - Gross of Fees
                </h6>

                <Chart
                  // seriesColors={chartDefaultV4Colors}
                  zoomable={false}
                  style={{ width: "100%" }}
                >
                  <ChartLegend position="bottom" />

                  <ChartCategoryAxis>
                    <ChartCategoryAxisItem
                      categories={yearEnds}
                      title={{
                        text: "Year",
                      }}
                    />
                  </ChartCategoryAxis>
                  <ChartTooltip format="N2" />
                  <ChartSeries>
                    <ChartSeriesItem type="column" data={returnValue} />
                  </ChartSeries>
                </Chart>
              </div>
            </div>
          </div>
          <div className="col-12 d-flex">
            <div className="card card-rounded h-100 w-100">
              <div className="card-body">
                <Grid data={t1Data} reorderable={true}>
                  <Column field="yearEnd" title="Year" />
                  <Column
                    field="marketInMillion"
                    title="Market In Million"
                    cell={NumberCell}
                    headerClassName="rightHeader"
                  />
                  <Column
                    field="retVal"
                    title="Return Value"
                    cell={NumberCell}
                    headerClassName="rightHeader"
                  />
                </Grid>
              </div>
            </div>
          </div>

          <div className="col-6 wheight d-flex">
            <div className="card card-rounded w-100 h-100">
              <div className="card-body">
                <h6 className="card-title">
                  Calendar Year - Allocation Over Time
                </h6>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    width: "100%",
                  }}
                >
                  <Chart
                    style={{
                      width: "100%",
                    }}
                  >
                    <ChartLegend position="bottom" orientation="horizontal" />
                    <ChartCategoryAxis>
                      <ChartCategoryAxisItem
                        categories={t2Year}
                        title={{
                          text: "Year",
                        }}
                      />
                    </ChartCategoryAxis>
                    <ChartTooltip format="N2" />
                    <ChartSeries>
                      {categoryNames.map((categoryName) => (
                        <ChartSeriesItem
                          type="column"
                          stack={{
                            type: "100%",
                          }}
                          data={getCategoryValues(groupedByYear, categoryName)}
                          name={categoryName}
                        />
                      ))}
                    </ChartSeries>
                  </Chart>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 wheight d-flex">
            <div className="card card-rounded h-100 w-100">
              <div className="card-body">
                <h6 className="card-title">Allocation Over Time</h6>
                <Grid
                  data={t2Data}
                  style={{ height: "400px" }}
                  reorderable={true}
                >
                  <Column field="yearEnd" title="Year" minWidth="450" />
                  <Column
                    field="perfTmpltCtgryNm"
                    title="Category Name"
                    cell={NumberCell}
                    headerClassName="rightHeader"
                    minWidth="300"
                    locked="false"
                  />
                  <Column
                    field="endMktVlAmt"
                    title="End Market Value"
                    cell={NumberCell}
                    headerClassName="rightHeader"
                    minWidth="300"
                    locked="false"
                  />
                </Grid>
              </div>
            </div>
          </div>
        </div>
      </>

      <Modal show={show} onHide={handleClose} fullscreen={true} size="xl">
        <Modal.Body>
          <div id="viewer-host">
            <Viewer ref={viewerRef} />
          </div>
        </Modal.Body>

        <Modal.Footer>
          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default AssetGrowthAndAllocationChart;
