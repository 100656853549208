import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import { filterBy } from '@progress/kendo-data-query';
import Loading from './loading';
import Header  from './header';
import ProjectedCashFlowGrid from './projectedCashFlowGrid';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import Modal from 'react-bootstrap/Modal';
import BankLogoPage from './bankLogoPage';
import PortfolioOverview1stGrid from './portfolioOverview1stGrid';
import PortfolioOverview2ndGrid from './portfolioOverview2ndGrid';
import PortfolioOverview3rdGrid from './portfolioOverview3rdGrid';
import PortfolioOverview4thGrid from './portfolioOverview4thGrid';
import PortfolioOverview5thGrid from './portfolioOverview5thGrid';
import {  FaFilePdf } from 'react-icons/fa';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from 'linq';

import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

const RTPortfolioOverviewRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem('token'));
  localStorage.setItem("modelId", JSON.stringify({modelDesc:"" ,modelId:-1}));
      
    const [PortfolioOverviewRptData, populatePortfolioOverviewRptData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [isColumnSave, setIsColumnSave] = useState(false);
    const [selectedAcct, setSelectedAcct] = useState(0);
    const [modelId, setModelId] = useState(-1);
    const [modelData, setModelData] = useState([]);
    const [astcls, setAstClass] = useState("0");
    const [session,setSession]=useState("");
    const [enableCombo,setEnableCombo]=useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      
      const fetchData = async () => {
           setLoading(true);
           setEnableCombo(true);
          try {
              //let data = location.state;
  
              let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
             
              //setEmail(email);
              GetPortfolioOverviewData(-1);
              //getColumnStateDb();
              //  console.log(data);
          } catch (error) {
              console.error(error.message);
          }
  
      }
      fetchData();
  }, [])
  
//   const getColumnStateDb = async () =>{
//     //Storing column settings in DB
//     
//       let token = JSON.parse(localStorage.getItem('token'));
//       let UserId = JSON.parse(localStorage.getItem('userId'));
//       let GridId = 1;//let 1 for Portfoliholdings Grid
      
//       const postData = {UserId, GridId};
//       const config = {
//         headers: {
//           'authorization': `Bearer ${token.token}`,
//           'Accept': 'application/json',
//           'Content-Type': 'application/json'
//         }
    
//       };
//       await axios.post('/RTGetGridColumn',
//         postData,
//         config
//       )
//         .then(response => {
//           
//           console.log('save in db');
//           console.log(response);
//             const rowData = response.data;
//             if(rowData!==""){
//               setIsColumnSave(true);
//               localStorage.setItem('gridColumns', rowData);
//             }
//             else{
//               setIsColumnSave(false);
//             }
//         })
//         .catch((error) => {
//           console.log('error in save db '+error);
//             return error;
//         });
//     }
    const refreshToken = async () => {
      let token = JSON.parse(localStorage.getItem('token'));
      let AccessToken = token.token;
      let RefreshToken = token.refreshToken;
      const postData = { AccessToken, RefreshToken };
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      await axios.post('/token/Refresh',
        postData
  
      )
        .then((response) => {
          
          tempToken = response.data;
          localStorage.setItem('token', JSON.stringify(response.data));
  
          GetPortfolioOverviewData(-1);
  
  
        })
        .catch((error) => {
          // 
          if(error.code==="ERR_BAD_REQUEST"){
            setSession("Session Expired");
          }
          console.log("my error is " + error);
        })
  
    }
    const signOut = () => {
    
      navigate("/");
      let token = JSON.parse(localStorage.getItem('token'));
      const postData = {};
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    
      };
      axios.post('/token/revoke',
      postData,
      config
      )
        .then((response) => {
        // 
        // localStorage.setItem('token', '');
         //console.log(response);
        // navigate("/");
            
          // if (response.statusText === '') {
           
    
    
          // }
    
         
        })
        .catch((error) => {
          // 
          console.log("my error is " + error);
        })
    
        // let tokenNew={token:''};
        // localStorage.setItem('token',JSON.stringify(tokenNew));
        // localStorage.setItem("AcctSelected",null);
        localStorage.removeItem('token');
        localStorage.clear();
      //firebaseApp.auth.signOut();
    
    }
    // const GetModelInfo = async () => {

    //   setLoading(true);
  
    //   let token = JSON.parse(localStorage.getItem('token'));
    //   let UserId = JSON.parse(localStorage.getItem('userId'));
    //   
  
    //   let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
    //   let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
    //   setSelectedAcct(JSON.parse(localStorage.getItem('AcctSelected')).acctId);
    //   let invMix = 0;
    //   let NumOfRows = 10;
  
    //   
    //   const postData1 = { AcctId };
    //   const postData = { UserId, Accounts, modelId, invMix, NumOfRows, AcctId };
    //   const config = {
    //     headers: {
    //       'authorization': `Bearer ${token.token}`,
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     }
  
    //   };
    //   await axios.post('/GetModelInfo',
    //     postData1,
    //     config
    //   ).then((response => {
    //     
    //     const rowData = response.data;
    //     localStorage.setItem("modelId", JSON.stringify(response.data.acctMdl[0].modelId));
    //     setModelData(response.data.model);
    //     setModelId(JSON.parse(localStorage.getItem("modelId")));
    //     postData.modelId = response.data.acctMdl[0].modelId;
    //     console.log(postData.modelId)
    //   })
    //   )
  
  
  
  
  
    //     //.then(axios.spread((getmodel,accountprofile) => {
  
    //     //  console.log(response);
  
    //     // const rowData = response.data.ocAcctProfile;
    //     //  setAcctProfileRptData(rowData);
    //     //  populateAcctHoldingRptData(rowData.ocAcctHolding)
  
  
  
  
    //     // setLoading(false);
  
  
    //     //getInfo(modelId);
  
  
  
  
    //     //}))
    //     .catch((error) => {
  
    //       if (error.response.status === 401) {
    //         refreshToken();
  
  
    //       }
  
  
    //       // return error;
    //     });
    //     GetPortfolioBreakDownData();
    // }
    const [selModel,setSelModel]=useState({modelDesc:"" ,modelId:-1});
  const [selBnchmrk,setSelBnchmrk]=useState({modelDesc:"" ,modelId:-1});
  let allPDFExport;
   const printPDF = async () => {
      
       if (allPDFExport !== null) {
           allPDFExport.save();
       }
   };
   const [show, setShow] = React.useState(false);
   const handleClose = () => setShow(false);
   const PageTemplate = (props) => {
    return (
        <div>
            <div
                style={{
                    position: "absolute",
                    top: "1px",

                    width:"98%",
                    borderBottom: "1px solid #bce8f1"
                   
                }}
            >
               <div className='row d-flex mx-3'>
                <div className='col text-start'>
                <a className='px-2'  ><BankLogoPage /></a>

                </div>
                <div className='col text-end px-5 py-2'>
                <h2 className='reportheading fs-6'  >Portfolio Overview Report </h2>

                </div>


               </div>
                
           
             
                
                {/* <div className='fw-bold  text-center'>
                    Account Profile</div> */}
            </div>
                   
            <div
                style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  //  borderTop: "1px solid #bce8f1"
                }}
            >
                Page {props.pageNum} of {props.totalPages}
            </div>
        </div>
    );
};
  const GetPortfolioOverviewData = async (bnchmrkid) => {
    
    setLoading(true);
    setEnableCombo(true);
  
  let token=tempToken;
     //let token = JSON.parse(localStorage.getItem('token'));
     let AsOfId = JSON.parse(localStorage.getItem('userId'));
     let AcctId= JSON.parse(localStorage.getItem('AcctSelected')).acctId;
     let pageId = 1;
     //let ModelId=JSON.parse(localStorage.getItem("modelId")).modelId;
     let BenchMarkId=bnchmrkid;
     const postData = {AsOfId,AcctId,BenchMarkId, pageId};
     const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      
  };
    await axios.post('/RTPortfolioOverview',
        postData,
       config
    )
        .then(response => {
          
            //  console.log(response);
  
            //const rowData = response.data;

            //const rowData = Enumerable.from(response.data.ocPortFolioHoldingsMainOutPut).where(w => w.totMarket !== 0).toArray();
            const rowData = response.data;
            
            
            populatePortfolioOverviewRptData(rowData)
            //getColumnStateDb();
            setLoading(false);
            setEnableCombo(false);
            
            //setSelModel(Enumerable.from(rowData.t1).where(w => w.modelId === rowData.t3[0].modelId).toArray()[0])
            setSelBnchmrk(Enumerable.from(rowData.t11).where(w => w.modelId === rowData.t2[0].benchmarkId).toArray()[0]);
            console.log(selModel);
            localStorage.setItem("bnchmrkId",(Enumerable.from(rowData.t11).where(w => w.modelId === rowData.t2[0].benchmarkId).toArray()[0]).modelDesc)
            console.log(selBnchmrk);
          })
        .catch((error) => {
  
            if (error.response.status === 401) {
          refreshToken();


        }
        });
  
  }
  const showpreview = async () => {
    setShow(true);
}
  const filterData = (filter) => {
   
    // const dataAcct = selAcctData.slice();
     return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
   };
  
   const filterChange = (event) => {
     
     setSelAcctData(filterData(event.filter));
   };
   const filtermodelChange = (event) => {
     
    setSelModel(filterData(event.filter));
  };
//   const filterbnchmrkChange = (event) => {
//     
//     setSelBnchmrk(filterData(event.filter));
//  };
  const handleChange = (event) => {
  
    if (event.target.value === null|| loading) {
      SetselAcct(selAcct);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem('IsAcctSelected', false);
      localStorage.setItem('AcctSelected',JSON.stringify(JSON.parse(localStorage.getItem('acctData'))[0]));
      }
    else {
      SetselAcct(event.target.value);
      
      localStorage.setItem("modelId", JSON.stringify({modelDesc:"" ,modelId:-1}));
      localStorage.setItem("bnchmrkId", selBnchmrk.modelDesc);
      localStorage.setItem('IsAcctSelected', true);
      localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetPortfolioOverviewData(-1);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
      
  };
  
    const bnchmrkChange = (event) => {
      
        if (event.target.value === null) {
          
          setSelBnchmrk(selBnchmrk);
          }
        else {
          
          setSelBnchmrk(event.target.value);
          localStorage.setItem("bnchmrkId", event.target.value.modelDesc);
          GetPortfolioOverviewData(event.target.value.modelId);
          //GetUpdatedAccountProfile(event.target.value.acctId);
        }
          
      };
      const assetClassChange = (e) => {
        
       if (astcls !== e.target.value) {
         setAstClass(e.target.value);
         //GetPrtflioSnpshtComprsnRptDataRdo(e.target.value);
       }
     }
  
  
  console.log(selModel);
  
  if (loading) {
  
    return(
      <>
       {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
    <div>
        <Header></Header>
        <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
        <div className='subheader text-end col-md-1'> &nbsp; <b>Account(s):</b></div>

          <div className='col-md-3 text-start'>
                      <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
              disabled={enableCombo}
            />
          </div>
          <div className='subheader text-end col-md-2'> &nbsp; <b>Model/Benchmark:</b></div>

          <div className='col-md-4 text-start'>

            <ComboBox
              style={{
                width: "350px",
              }}
              data={PortfolioOverviewRptData.t11}
              textField="modelDesc"
              dataItemKey="modelId"
              filterable={true}
              value={selBnchmrk}
              onChange={bnchmrkChange}
            //  onFilterChange={filterbnchmrkChange}
            />


          </div>
          
          {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
          </div>
      <Loading />
    </div>}</>
    )
}
    return (
      <div>
        <Header></Header>
        <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
        <div className='subheader text-end col-md-1'> Account(s): </div>

          <div className='col-md-3 text-start'>
                      <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
              disabled={enableCombo}
            />
          </div>
          {/* <div className='col-md-4 text-start'>
          <div className="btn-group btn-group-sm" role="group" aria-label="Basic radio toggle button group">
                <input type="radio" defaultChecked={astcls === "0"} value="0" className="btn-check form-check-input" name="astcls" id="model" onChange={assetClassChange} />
                <label className="btn btn-outline-primary btn-sm" htmlFor="model">Model</label>

                <input type="radio" value="1" defaultChecked={astcls === "1"} className="btn-check form-check-input" name="astcls" id="bnchmrk" onChange={assetClassChange} />
                <label className="btn btn-outline-primary btn-sm" htmlFor="bnchmrk">Minor Asset</label>
              </div></div> */}
          {<><div className='subheader text-end col-md-2'> &nbsp; Model/Benchmark: </div>

<div className='col-md-4 text-start'>

  <ComboBox
    style={{
      width: "350px",
    }}
    data={PortfolioOverviewRptData.t11}
    textField="modelDesc"
    dataItemKey="modelId"
    filterable={true}
    value={selBnchmrk}
    onChange={bnchmrkChange}
  
  //  onFilterChange={filterbnchmrkChange}
  />


</div></>}
          
          
            <div className='col'>  As Of: {localStorage.getItem("processingDate")}</div>
          {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
          </div>
          <div className='mx-1 px-1 row d-flex justify-content-between align-items-center my-2 py-2'>
          <div className='col'>
            <p className='tableheader h6 text-start col-md-4'>Portfolio Overview Report</p></div>
            <div className='col-md-4 text-start'>
            <div className='subheader col-md text-end'>Due to rounding, percentage may not equal 100.</div>
              {/* <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={showpreview}
                                >
                                    <span className='px-1'><FaFilePdf /></span>PDF Preview
                                </button> */}
          </div>
          
          
          
            {/* <div>Benchmark:</div>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={PortfolioOverviewRptData.t1}
              textField="modelDesc"
              dataItemKey="modelId"
              filterable={true}
              value={selBnchmrk}
              onChange={bnchmrkChange}
            //  onFilterChange={filterbnchmrkChange}
            />
            <div>As Of: {localStorage.getItem("processingDate")}</div> */}
         <PortfolioOverview1stGrid data={PortfolioOverviewRptData.t4} flag={isColumnSave} showExport={true} PortfolioOverviewRptData={PortfolioOverviewRptData} />
         <PortfolioOverview2ndGrid data={PortfolioOverviewRptData.t8} carddata={PortfolioOverviewRptData.t7.length===0?false:PortfolioOverviewRptData.t7} flag={isColumnSave} showExport={true}/>
         <PortfolioOverview3rdGrid data={PortfolioOverviewRptData.t5} flag={isColumnSave} showExport={true}/>
         <PortfolioOverview4thGrid data={PortfolioOverviewRptData.t6} bnchmrk={localStorage.getItem("bnchmrkId")} flag={isColumnSave} showExport={true}/>
         <PortfolioOverview5thGrid data={PortfolioOverviewRptData.t9} flag={isColumnSave} showExport={true}/>
         <Modal show={show} onHide={handleClose} fullscreen={true} size="xl" >
                    <Modal.Header closeButton><div className="row w-100 tableheader">
                        <div className='col'>
                            <Modal.Title>Portfolio Overview Report - Pdf Preview</Modal.Title>
                        </div>
                        <div className='col text-end'>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={printPDF}
                            >
                                Print
                            </button>
                        </div>


                    </div>
                    </Modal.Header>
                    <Modal.Body>
                        <PDFExport
                            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
                            pageTemplate={PageTemplate}
                            forcePageBreak=".page-break"
                            paperSize="A4"
                            scale={0.5}
                            allPages={true}
                          
                            landscape={true}
                            repeatHeaders={true}

                            ref={(pdfExport) => (allPDFExport = pdfExport)}>

                            Account Name : {selAcct.extrnlAcctId}
                            <hr></hr>
                            
          <PortfolioOverview1stGrid data={PortfolioOverviewRptData.t4} flag={isColumnSave} showExport={false}/>
         <PortfolioOverview2ndGrid data={PortfolioOverviewRptData.t8} carddata={PortfolioOverviewRptData.t7.length===0?false:PortfolioOverviewRptData.t7} flag={isColumnSave} showExport={false} />
         <PortfolioOverview3rdGrid data={PortfolioOverviewRptData.t5} flag={isColumnSave} showExport={false}/>
         <PortfolioOverview4thGrid data={PortfolioOverviewRptData.t6} bnchmrk={localStorage.getItem("bnchmrkId")} flag={isColumnSave} showExport={false}/>
         <PortfolioOverview5thGrid data={PortfolioOverviewRptData.t9} flag={isColumnSave} showExport={false}/>
         
                        </PDFExport>

                    </Modal.Body>
                    <Modal.Footer>



                        <button className="btn btn-primary  btn-sm" onClick={handleClose}>
                            Close
                        </button>


                    </Modal.Footer>

                </Modal>
         </div>
         
      </div>
    )
  }
  
  export default RTPortfolioOverviewRpt