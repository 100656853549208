import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import { filterBy } from '@progress/kendo-data-query';
import Loading from './loading';
import Header  from './header';
import PerformanceWidgetGrid from './performanceWidgetGrid';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from 'linq';
const RTPerformanceWidgetRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem('token'));
    const [PerformanceWidgetRptData, populatePerformanceWidgetRptData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
    const [selAcct, SetselAcct] = useState(localStorage.getItem('IsAcctSelected')? JSON.parse(localStorage.getItem('AcctSelected')):JSON.parse(localStorage.getItem('acctData'))[0]);
    const [isColumnSave, setIsColumnSave] = useState(false);
    useEffect(() => {
      
      const fetchData = async () => {
           setLoading(true);
          try {
              //let data = location.state;
  
              let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
             
              //setEmail(email);
              GetPerformanceWidgetData();
              //getColumnStateDb();
              //  console.log(data);
          } catch (error) {
              console.error(error.message);
          }
  
      }
      fetchData();
  }, [])
  
//   const getColumnStateDb = async () =>{
//     //Storing column settings in DB
//     
//       let token = JSON.parse(localStorage.getItem('token'));
//       let UserId = JSON.parse(localStorage.getItem('userId'));
//       let GridId = 1;//let 1 for Portfoliholdings Grid
      
//       const postData = {UserId, GridId};
//       const config = {
//         headers: {
//           'authorization': `Bearer ${token.token}`,
//           'Accept': 'application/json',
//           'Content-Type': 'application/json'
//         }
    
//       };
//       await axios.post('/RTGetGridColumn',
//         postData,
//         config
//       )
//         .then(response => {
//           
//           console.log('save in db');
//           console.log(response);
//             const rowData = response.data;
//             if(rowData!==""){
//               setIsColumnSave(true);
//               localStorage.setItem('gridColumns', rowData);
//             }
//             else{
//               setIsColumnSave(false);
//             }
//         })
//         .catch((error) => {
//           console.log('error in save db '+error);
//             return error;
//         });
//     }
    const refreshToken = async () => {
      let token = JSON.parse(localStorage.getItem('token'));
      let AccessToken = token.token;
      let RefreshToken = token.refreshToken;
      const postData = { AccessToken, RefreshToken };
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      await axios.post('/token/Refresh',
        postData
  
      )
        .then((response) => {
          
          tempToken = response.data;
          localStorage.setItem('token', JSON.stringify(response.data));
  
          GetPerformanceWidgetData();
  
  
        })
        .catch((error) => {
          // 
          console.log("my error is " + error);
        })
  
    }
  const GetPerformanceWidgetData = async () => {
    
    setLoading(true);
  
  let token=tempToken;
     //let token = JSON.parse(localStorage.getItem('token'));
     let AsOfId = JSON.parse(localStorage.getItem('userId'));
     let mAccountId= JSON.parse(localStorage.getItem('AcctSelected')).acctId;
     let pageId = 1;
     const postData = {AsOfId,mAccountId, pageId};
     const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      
  };
    await axios.post('/RTPerformanceWidget',
        postData,
       config
    )
        .then(response => {
          
            //  console.log(response);
  
            //const rowData = response.data;
            // for(var j=0;j<response.data.t2.length;j++){
            //   if(response.data.t2[j].yearMnthNm==="0001-01-01T00:00:00"){
            //     response.data.t2[j].yearMnthNm=null;
            //   }
            // }
            
             //const rowData = response.data;
            const rowData =response.data.t2 ;


            populatePerformanceWidgetRptData(rowData)
            //getColumnStateDb();
            setLoading(false);
  
        })
        .catch((error) => {
  
            if (error.response.status === 401) {
          refreshToken();


        }
        });
  
  }
  const filterData = (filter) => {
   
    // const dataAcct = selAcctData.slice();
     return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
   };
  
   const filterChange = (event) => {
     
     setSelAcctData(filterData(event.filter));
   };
  const handleChange = (event) => {
  
    if (event.target.value === null) {
      SetselAcct(JSON.parse(localStorage.getItem('acctData'))[0]);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem('IsAcctSelected', false);
      localStorage.setItem('AcctSelected',JSON.stringify(JSON.parse(localStorage.getItem('acctData'))[0]));
      }
    else {
      SetselAcct(event.target.value);
      localStorage.setItem('IsAcctSelected', true);
      localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetPerformanceWidgetData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
      
  };
  
  
  
  
  if (loading) {
  
    return(
      <>
        <Header></Header>
        <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className='subheader text-end col-md-1'> &nbsp; Account(s):</div>
          <div className='col-md-4 text-start'>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
            />
          </div>
          {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
          </div>
      <Loading />
   </>
    )
   
 

}
    return (
      <div>
        <Header></Header>
        <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
                    <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                        <span className='px-2'>Account(s)</span>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
            />
          </div><div className='subheader text-end col-md-2'>Processing Date : {localStorage.getItem("processingDate")}</div>
          {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
          </div>
  
         
         <PerformanceWidgetGrid data={PerformanceWidgetRptData} flag={isColumnSave} />
         
         
      </div>
    )
  }
  
  export default RTPerformanceWidgetRpt