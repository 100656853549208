import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import axios from 'axios';
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { formatNumber, formatDate, numberSymbols } from '@telerik/kendo-intl';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import Enumerable from 'linq';
import { Pager } from '@progress/kendo-react-data-tools';
import {
    setGroupIds,
    getGroupIds,
    setExpandedState,
} from '@progress/kendo-react-data-tools';
import {accountHoldingDatavar} from './cstmRptMain';
import { ResponsiveContainer } from 'recharts';
const aggregates = [
    {
        field: "market",
        aggregate: "sum",
    }
];

const processWithGroups = (data, dataState) => {
    const groups = dataState.group;

    if (groups) {
        groups.map((group) => (group.aggregates = aggregates));
    }

    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
        data: newDataState.data,
        group: dataState.group,
    });
    return newDataState;
};

const AcctHoldingRptWidget = () => {
    
    var pcash=accountHoldingDatavar[0]==undefined?formatNumber(0, '##,#.00'):formatNumber(accountHoldingDatavar[0].principalCash, '##,#.00');
    var incmCash=accountHoldingDatavar[0]==undefined?formatNumber(0, '##,#.00'):formatNumber(accountHoldingDatavar[0].incomeCash, '##,#.00');
    var invIncm=accountHoldingDatavar[0]==undefined?formatNumber(0, '##,#.00'):formatNumber(accountHoldingDatavar[0].investedIncome, '##,#.00');
const [data,setData]=useState(accountHoldingDatavar) ;
    const totalSum = (props) => {
        const field = props.field || '';
        const total = data
            .reduce((acc, current) => acc + current[field], 0)
            .toFixed(2);
        return (
            <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
                {formatNumber(total, '##,#.00')}
            </td>
        );
    };
    const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const defaultColumns = [
        // {
        //   title: 'Branch',
        //   field: 'branch',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: true,
        // },
        // {
        //   title: 'Acct. Type',
        //   field: 'accountType',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: true,
        // },
        // {
        //   title: 'Account#',
        //   field: 'accountName',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: true,
        // },
        //  {
        //   title: 'PMR',
        //   field: 'pmrDesc',
        //   minWidth: 150,
        //   show: false,
        //   filter: 'text',
        //   locked: true,
        // },
        {
          title: 'Ticker',
          field: 'tckrSymbl',
          minWidth: "auto",
          show: true,
          filter: 'text',
          locked: false,
        },
        {
          title: 'Cusip',
          field: 'cusip',
          minWidth: "auto",
          show: true,
          filter: 'text',
          locked: false,
        },
        {
          title: 'Asset',
          field: 'asset',
          minWidth: "auto",
          show: true,
          filter: 'text',
          locked: false,
        },
       
    
        {
          title: 'Shares',
          field: 'shares',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          headerClassName: 'rightHeader'
        },
        {
          title: 'Cost($)',
          field: 'cost',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          headerClassName: 'rightHeader'
    
        },
        {
          title: 'Market($)',
          field: 'market',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          //footerCell: totalSum,
          headerClassName: 'rightHeader'
        },
        {
          title: 'Unr Gain Loss($)',
          field: 'unrGainLoss',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          headerClassName: 'rightHeader'
        },
        {
          title: 'Est Ann Inc($)',
          field: 'estAnnInc',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          headerClassName: 'rightHeader'
        },
        {
          title: 'Yield(%)',
          field: 'yield',
          minWidth: "auto",
          show: true,
          filter: 'numeric',
          locked: false,
          headerClassName: 'rightHeader'
        },
        // {
        //   title: 'Acc Int.(%)',
        //   field: 'accruedInterest',
        //   minWidth: "auto",
        //   show: true,
        //   filter: 'numeric',
        //   locked: false,
        //   headerClassName: 'rightHeader'
        //  },
        // {
        //   title: 'Principal($)',
        //   field: 'principalCash',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'numeric',
        //   locked: false,
        //   headerClassName: 'rightHeader'
        // },
        // {
        //   title: 'Income($)',
        //   field: 'incomeCash',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'numeric',
        //   locked: false,
        //   headerClassName: 'rightHeader'
        // },
        // {
        //   title: 'Inv. Income($)',
        //   field: 'investedIncome',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'numeric',
        //   locked: false,
        //   headerClassName: 'rightHeader'
        // },
        // {
        //   title: 'Cost Per Share',
        //   field: 'costPerShare',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'numeric',
        //   locked: false,
        //   headerClassName: 'rightHeader'
        // },
        // {
        //   title: 'Country',
        //   field: 'country',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        // },
        // {
        //   title: 'Location',
        //   field: 'location',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        // },
        // {
        //   title: 'Registration',
        //   field: 'registration',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        // },
        // {
        //   title: 'Holding Date',
        //   field: 'holdingDate',
        //   //field: 'holdingDate',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'date',
        //   locked: false,
          
        // },
        // {
        //   title: 'Industry',
        //   field: 'industry',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        // },
        // {
        //   title: 'IssueType',
        //   field: 'issueType',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        // },
        // {
        //   title: 'Manual Price Source',
        //   field: 'manualPriceSource',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        // },
        // {
        //   title: 'Price',
        //   field: 'price',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'numeric',
        //   locked: false,
        //   headerClassName: 'rightHeader'
        // },
        // {
        //   title: 'Price Date',
        //   field: 'priceDate',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'date',
        //   locked: false,
          
        // },
        // {
        //   title: 'Security Type',
        //   field: 'securityType',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        // },
        // {
        //   title: 'Pricing Source',
        //   field: 'pricingSource',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        // },
        // {
        //   title: 'Pricing Source Desc',
        //   field: 'pricingSourceDesc',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
          
        // },
        
        // {
        //   title: 'Inv. Objective',
        //   field: 'investmentObjective',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
    
        // },
        // {
        //   title: 'Administrator',
        //   field: 'administrator',
        //   minWidth: 180,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
    
        // },
        // {
        //   title: 'Inv. Officer',
        //   field: 'investmentOfficer',
        //   minWidth: 180,
        //   show: true,
        //   filter: 'text',
        //   locked: false,
    
        // },
        // {
        //   title: 'Rate',
        //   field: 'rate',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'numeric',
        //   locked: false,
    
        // },
        // {
        //   title: 'Tax Cost($)',
        //   field: 'txCstAmt',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'numeric',
        //   locked: false,
        //   headerClassName: 'rightHeader'
        // },
        // {
        //   title: 'Yield To Cost(%)',
        //   field: 'yldToCost',
        //   minWidth: 150,
        //   show: true,
        //   filter: 'numeric',
        //   locked: false,
        //   headerClassName: 'rightHeader'
        // },
      ];

      const totalSumYield = (props) => {
        
        var totalIncome=0, totalMarket=0, totYield=0;
        for(var i=0; i<data.length; i++){
          totalIncome = totalIncome + data[i].estAnnInc;
          totalMarket = totalMarket + data[i].market;
        }
        if(totalMarket==0){
          totYield = 0;
        }
        else{
          totYield = (totalIncome*100)/totalMarket;
        }
        
        return (
          <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {formatNumber(totYield, '##,#.00')}
          </td>
        );
      };
    const GridColumns = defaultColumns;

    const createDataState = (dataState) => {
        return {
            result: process(data, dataState),
            dataState: dataState,
        };
    };
debugger
    let initialState = createDataState({
        take: Number.MAX_VALUE,
        skip: 0,
       // group: [{ field: 'mjrAstNm' }]
    });

    const [result, setResult] = React.useState(
        processWithGroups(data, initialState.dataState)
    );
    GridColumns[4].footerCell=totalSum ;
    GridColumns[5].footerCell=totalSum ;
    GridColumns[6].footerCell=totalSum ;
    GridColumns[7].footerCell=totalSum ;
    GridColumns[8].footerCell=totalSumYield ;
    const expandChange = (event) => {
        const isExpanded =
          event.dataItem.expanded === undefined
            ? event.dataItem.aggregates
            : event.dataItem.expanded;
        event.dataItem.expanded = !isExpanded;
        setResult({ ...result });
      };

    const [dataState, setDataState] = React.useState(initialState.dataState);
    const [stateColumns, setStateColumns] = React.useState(GridColumns);

    GridColumns[5].footerCell = totalSum;
    const dataStateChange = (event) => {

        let updatedState = createDataState(event.dataState);

        setResult(processWithGroups(data, updatedState.dataState));

        setDataState(updatedState.dataState);
    };


    const setWidth = (minWidth) => {
        let width = minWidth;
        return width;
    };

    const cellRender = (tdElement, cellProps) => {
        // if (
        //   cellProps.rowType === 'groupHeader' &&
        //   tdElement &&
        //   tdElement.props.role != 'presentation'
        // ) {
        //   //IMPORTANT - You need to add collection with the columns and their field name
        //   //you can define the Grid columns outside of the Grid and reuse them here.
        //   const columns = [
        //     // { field: 'branch' },
        //     // { field: 'accountType' },
        //     // { field: 'accountName'},
        //     { field: 'asset' },
        //     { field: 'tckrSymbl' },
        //     { field: 'cusip' },
        //     { field: 'pmrDesc' },
        //     { field: 'shares' },
    
        //     { field: 'cost' },
        //     { field: 'market', aggregate: 'sum' },
        //     { field: 'unrGainLoss' },
        //     { field: 'estAnnInc' },
        //     { field: 'yield' },
        //     { field: 'accruedInterest' },
        //     { field: 'principalCash' },
        //     { field: 'incomeCash' },
        //     { field: 'investedIncome' },
        //     // { field: 'investmentObjective'},
        //     // { field: 'administrator'},
        //     // { field: 'investmentOfficer'},
    
        //     { field: 'txCstAmt' },
        //     { field: 'yldToCost' },
    
        //   ];
    
        //   return (
        //     <>
        //       <td
        //         {...tdElement.props}
        //         colSpan={tdElement.props.colSpan - columns.length}
        //       ></td>
        //       {getCells(columns, cellProps)}
        //     </>
        //   );
        // }
        if (cellProps.rowType === 'groupFooter') {
    
          if (cellProps.field === "market") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem.aggregates.market.sum, "##,#.00")}
              </td>
            );
          }
    
        }
        if (cellProps.rowType === "data") {
    
          if (cellProps.field === "market") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "unrGainLoss") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "estAnnInc") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "yield") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "yldToCost") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "accruedInterest") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "principalCash") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "incomeCash") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "investedIncome") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "txCstAmt") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "cost") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "shares") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "priceDate") {
            let cdt=new Date(cellProps.dataItem["priceDate"]);
            return (
              <td style={{ textAlign: 'left' }}>
                {(cdt.getMonth()+1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
              </td>
            )
          }
          if (cellProps.field === "holdingDate") {
    
            let cdt=new Date(cellProps.dataItem["holdingDate"]);
            if(!isNaN(cdt.getTime())){
            return (
              <td style={{ textAlign: 'left' }}>
                {(cdt.getMonth()+1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
              </td>
            )}
            else {
              
              return (
                <td style={{ textAlign: 'left' }}>
                  &nbsp;
                   </td>
              )
            }
          }
          if (cellProps.field === "price") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "costPerShare") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "costPerShare") {
    
            return (
              <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
                {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
              </td>
            );
          }
          if (cellProps.field === "priceDate") {
    
            let cdt=new Date(cellProps.dataItem["priceDate"]);
            if(!isNaN(cdt.getTime())){
              return (
                <td style={{ textAlign: 'left' }}>
                  {(cdt.getMonth()+1).toString().padStart(2, '0')}/{cdt.getDate().toString().padStart(2, '0')}/{cdt.getFullYear()}
                </td>
              )}
              else {
                
                return (
                  <td style={{ textAlign: 'left' }}>
                    &nbsp;
                     </td>
                )
              }
          }
        }
    
        return tdElement;
      };

    return (
        <ResponsiveContainer className='page-break'>
            <div className="card-body">
            
            <h5 className='text-fitek p-1'>Account Holdings</h5>
            <div className='row d-flex justify-content-between align-items-center my-1 card-header'>
        
        <div className='subheader col-sm text-start'>Principal Cash: {pcash}</div>
        <div className='subheader col-sm text-start'>Income Cash: {incmCash}</div>
        <div className='subheader col-sm text-start'>Invested Income: {invIncm}</div>
        <div className='subheader col-lg text-end text-muted small'>Due to rounding, percentage may not equal 100.</div>
        </div>
                <div className="mx-1 my-1 py-1">
                    <Grid 
                        style={{ height: "300px" }}
                        data={result}
                        {...dataState}
                        onDataStateChange={dataStateChange}
                       // expandField="expanded"
                        //onExpandChange={expandChange}
                        cellRender={cellRender}
                        //sortable={true}
                        //resizable={true}
                        //pageable={true}
                        //reorderable={true}

                        //pageSize={10}

                        groupable={{
                            footer: 'visible',
                            enabled: false
                        }}
                    >
                        {stateColumns.map(
                            (column, idx) =>
                                column.show && (
                                    <Column
                                        width={setWidth(column.minWidth)}
                                        //  locked={column.locked}
                                        key={idx}
                                        field={column.field}
                                        title={column.title}
                                        filter={column.filter}
                                        footerCell={column.footerCell}
                                        headerClassName={column.headerClassName}
                                        {...column}
                                        //cell={NumberCell}
                                        columnMenu={(props) => (
                                            <CustomColumnMenuNoGrp
                                                {...props}
                                                columns={stateColumns}
                                                onColumnsSubmit={onColumnsSubmit}
                                            />
                                        )}
                                    />
                                )
                        )}
                    </Grid>



                </div>
            </div>
        </ResponsiveContainer>
    )
}

export default AcctHoldingRptWidget
