import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import { filterBy } from '@progress/kendo-data-query';
import Loading from './loading';
import Header  from './header';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import Modal from 'react-bootstrap/Modal';
import BankLogoPage from './bankLogoPage';
import ProjectedCashFlowGrid from './projectedCashFlowGrid';
import PortfolioBreakDown1stGrid from './portfolioBreakDown1stGrid';
import PortfolioBreakDown2ndGrid from './portfolioBreakDown2ndGrid';
import PortfolioBreakDown3rdGrid from './portfolioBreakDown3rdGrid';
import {  FaFilePdf } from 'react-icons/fa';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Enumerable from 'linq';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';

const RTPortfolioBreakDownRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem('token'));
  localStorage.setItem("modelId", JSON.stringify({modelDesc:"" ,modelId:-1}));
      localStorage.setItem("bnchmrkId", JSON.stringify({modelDesc:"" ,modelId:-1}));
    const [PortfolioBreakDownRptData, populatePortfolioBreakDownRptData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    
    const [isColumnSave, setIsColumnSave] = useState(false);
    const [selectedAcct, setSelectedAcct] = useState(0);
    const [modelId, setModelId] = useState(-1);
    const [modelData, setModelData] = useState([]);
  const [dispAssetClass,setdispAssetClass]=useState("");
  const [dispAssetClass2,setdispAssetClass2]=useState("");
  const [session,setSession]=useState("");
  const [enableCombo,setEnableCombo]=useState(false);
  const navigate = useNavigate();

    useEffect(() => {
      
      const fetchData = async () => {
           setLoading(true);
           setEnableCombo(true);
          try {
              //let data = location.state;
  
              let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
             
              //setEmail(email);
              GetPortfolioBreakDownData();
              getColumnStateDb();
              //  console.log(data);
          } catch (error) {
              console.error(error.message);
          }
  
      }
      fetchData();
  }, [])
  
  const getColumnStateDb = async () =>{
    //Storing column settings in DB
    
      let token = JSON.parse(localStorage.getItem('token'));
      let UserId = JSON.parse(localStorage.getItem('userId'));
      let GridId = 1;//let 1 for Portfoliholdings Grid
      
      const postData = {UserId, GridId};
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
    
      };
      await axios.post('/RTGetGridColumn',
        postData,
        config
      )
        .then(response => {
          
          console.log('save in db');
          console.log(response);
            const rowData = response.data;
            if(rowData!==""){
              setIsColumnSave(true);
              localStorage.setItem('gridColumns', rowData);
            }
            else{
              setIsColumnSave(false);
            }
        })
        .catch((error) => {
          console.log('error in save db '+error);
            return error;
        });
    }
    const refreshToken = async () => {
      let token = JSON.parse(localStorage.getItem('token'));
      let AccessToken = token.token;
      let RefreshToken = token.refreshToken;
      const postData = { AccessToken, RefreshToken };
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      await axios.post('/token/Refresh',
        postData
  
      )
        .then((response) => {
          
          tempToken = response.data;
          localStorage.setItem('token', JSON.stringify(response.data));
  
          GetPortfolioBreakDownData();
  
  
        })
        .catch((error) => {
          // 
          if(error.code==="ERR_BAD_REQUEST"){
            setSession("Session Expired");
          }
          console.log("my error is " + error);
        })
  
    }
    // const GetModelInfo = async () => {

    //   setLoading(true);
  
    //   let token = JSON.parse(localStorage.getItem('token'));
    //   let UserId = JSON.parse(localStorage.getItem('userId'));
    //   
  
    //   let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
    //   let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
    //   setSelectedAcct(JSON.parse(localStorage.getItem('AcctSelected')).acctId);
    //   let invMix = 0;
    //   let NumOfRows = 10;
  
    //   
    //   const postData1 = { AcctId };
    //   const postData = { UserId, Accounts, modelId, invMix, NumOfRows, AcctId };
    //   const config = {
    //     headers: {
    //       'authorization': `Bearer ${token.token}`,
    //       'Accept': 'application/json',
    //       'Content-Type': 'application/json'
    //     }
  
    //   };
    //   await axios.post('/GetModelInfo',
    //     postData1,
    //     config
    //   ).then((response => {
    //     
    //     const rowData = response.data;
    //     localStorage.setItem("modelId", JSON.stringify(response.data.acctMdl[0].modelId));
    //     setModelData(response.data.model);
    //     setModelId(JSON.parse(localStorage.getItem("modelId")));
    //     postData.modelId = response.data.acctMdl[0].modelId;
    //     console.log(postData.modelId)
    //   })
    //   )
  
  
  
  
  
    //     //.then(axios.spread((getmodel,accountprofile) => {
  
    //     //  console.log(response);
  
    //     // const rowData = response.data.ocAcctProfile;
    //     //  setAcctProfileRptData(rowData);
    //     //  populateAcctHoldingRptData(rowData.ocAcctHolding)
  
  
  
  
    //     // setLoading(false);
  
  
    //     //getInfo(modelId);
  
  
  
  
    //     //}))
    //     .catch((error) => {
  
    //       if (error.response.status === 401) {
    //         refreshToken();
  
  
    //       }
  
  
    //       // return error;
    //     });
    //     GetPortfolioBreakDownData();
    // }
    const [selModel,setSelModel]=useState({modelDesc:"" ,modelId:-1});
  const [selBnchmrk,setSelBnchmrk]=useState({modelDesc:"" ,modelId:-1});
  const [show, setShow] = React.useState(false);
  const GetPortfolioBreakDownData = async () => {
    
    setLoading(true);
    setEnableCombo(true);
  
  let token=tempToken;
     //let token = JSON.parse(localStorage.getItem('token'));
     let AsOfId = JSON.parse(localStorage.getItem('userId'));
     let AcctId= JSON.parse(localStorage.getItem('AcctSelected')).acctId;
     let pageId = 1;
     let ModelId=JSON.parse(localStorage.getItem("modelId")).modelId;
     let BenchMarkId=localStorage.getItem("bnchmrkId")!=="undefined"?JSON.parse(localStorage.getItem("bnchmrkId")).modelId:-1;
     const postData = {AsOfId,AcctId, pageId,ModelId,BenchMarkId};
     
     const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      
  };
    await axios.post('/RTPortfolioBreakDown',
        postData,
       config
    )
        .then(response => {
          
            //  console.log(response);
  
            //const rowData = response.data;

            //const rowData = Enumerable.from(response.data.ocPortFolioHoldingsMainOutPut).where(w => w.totMarket !== 0).toArray();
            const rowData = response.data;
            
            
            
            // var i=0;
  
for(let i=0;i<rowData.t4.length;i++){
  
  if(rowData.t4[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1"){
    rowData.t4[i].descption="^"+rowData.t4[i].descption;
  }
  if(rowData.t4[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1")
   {setdispAssetClass("^ Asset Class Contains IM Excluded Asset(s)");}
    
}
for(let i=0;i<rowData.t5.length;i++){
  
  if(rowData.t5[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1"){
    rowData.t5[i].descption="^"+rowData.t5[i].descption;
  }
  if(rowData.t5[i].excldInd===1 && localStorage.getItem("ShwHdeExcldAstVerbag")==="1")
   {setdispAssetClass( "^ Asset Class Contains IM Excluded Asset(s)");}
    
}

let accNamenum= JSON.parse(localStorage.getItem('AcctSelected'));
for(let x=0;x<rowData.t7.length;x++){
  
    rowData.t7[x].shrtNm = accNamenum.extrnlAcctId;
    
}


populatePortfolioBreakDownRptData(rowData);
            getColumnStateDb();
            setLoading(false);
            setEnableCombo(false);
            
            setSelModel(Enumerable.from(rowData.t1).where(w => w.modelId === rowData.t3[0].modelId).toArray()[0])
            setSelBnchmrk(Enumerable.from(rowData.t2).where(w => w.modelId === rowData.t3[0].benchmarkId).toArray()[0]);
            console.log(selModel);
            console.log(selBnchmrk);
          })
        .catch((error) => {
  
            if (error.response.status === 401) {
          refreshToken();


        }
        });
  
  }
  const filterData = (filter) => {
   
    // const dataAcct = selAcctData.slice();
     return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
   };
   const showpreview = async () => {
    setShow(true);
}
   let allPDFExport;
   const printPDF = async () => {
      
       if (allPDFExport !== null) {
           allPDFExport.save();
       }
   };
   const filterChange = (event) => {
     
     setSelAcctData(filterData(event.filter));
   };
   const filtermodelChange = (event) => {
     
    setSelModel(filterData(event.filter));
  };
//   const filterbnchmrkChange = (event) => {
//     
//     setSelBnchmrk(filterData(event.filter));
//  };
  const handleChange = (event) => {
  
    if (event.target.value === null) {
      SetselAcct(selAcct);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem('IsAcctSelected', false);
      localStorage.setItem('AcctSelected',JSON.stringify(JSON.parse(localStorage.getItem('acctData'))[0]));
      }
    else {
      SetselAcct(event.target.value);
      
      localStorage.setItem("modelId", JSON.stringify({modelDesc:"" ,modelId:-1}));
      localStorage.setItem("bnchmrkId", JSON.stringify({modelDesc:"" ,modelId:-1}));
      localStorage.setItem('IsAcctSelected', true);
      localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));
      console.log(event.target.value);
      GetPortfolioBreakDownData();
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
      
  };

  const signOut = () => {
    
    navigate("/");
    let token = JSON.parse(localStorage.getItem('token'));
    const postData = {};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    axios.post('/token/revoke',
    postData,
    config
    )
      .then((response) => {
      // 
      // localStorage.setItem('token', '');
       //console.log(response);
      // navigate("/");
          
        // if (response.statusText === '') {
         


        // }

       
      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      })

      // let tokenNew={token:''};
      // localStorage.setItem('token',JSON.stringify(tokenNew));
      // localStorage.setItem("AcctSelected",null);
      localStorage.removeItem('token');
      localStorage.clear();
    //firebaseApp.auth.signOut();

  }
  const PageTemplate = (props) => {
    return (
        <div>
            <div
                style={{
                    position: "absolute",
                    top: "1px",

                    width:"98%",
                    borderBottom: "1px solid #bce8f1"
                   
                }}
            >
               <div className='row d-flex mx-3'>
                <div className='col text-start'>
                <a className='px-2'  ><BankLogoPage /></a>

                </div>
                <div className='col text-end px-5 py-2 mt-1'>
                <h2 className='fw-bold text-fitek'  >Portfolio Breakdown Report </h2>

                </div>


               </div>
                
           
             
                
                {/* <div className='fw-bold  text-center'>
                    Account Profile</div> */}
            </div>
                   
            <div
                style={{
                    position: "absolute",
                    bottom: "10px",
                    right: "10px",
                  //  borderTop: "1px solid #bce8f1"
                }}
            >
                Page {props.pageNum} of {props.totalPages}
            </div>
        </div>
    );
};
const handleClose = () => setShow(false);
  const modelChange = (event) => {
    
      if (event.target.value === null) {
        
        setSelModel(selModel);
        }
      else {
        
        setSelModel(event.target.value);
        localStorage.setItem("modelId", JSON.stringify(event.target.value));
        GetPortfolioBreakDownData();
        //GetUpdatedAccountProfile(event.target.value.acctId);
      }
       
    };
    const bnchmrkChange = (event) => {
      
        if (event.target.value === null) {
          
          setSelBnchmrk(selBnchmrk);
          }
        else {
          
          setSelBnchmrk(event.target.value);
          localStorage.setItem("bnchmrkId", JSON.stringify(event.target.value));
          GetPortfolioBreakDownData();
          //GetUpdatedAccountProfile(event.target.value.acctId);
        }
          
      };
  
  
  console.log(selModel);
  
  if (loading) {
  
    return(
      <>
       {session==="Session Expired"?
      
      <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
    :
      <div>
        <Header></Header>
        <div className='row d-flex justify-content-start align-items-center mx-1 shadow-sm rounded'>
      
      
      
     
      
      <div className='row d-flex justify-content-between align-items-center bg-light'>
      
      <div className='row'>

        <div className='col'>
        <div> Account(s):</div>
        <ComboBox
          style={{
            width: "330px",
          }}
          data={selAcctData}
          textField="extrnlAcctId"
          dataItemKey="acctId"
          filterable={true}
          value={selAcct}
          onChange={handleChange}
          onFilterChange={filterChange}
          disabled={enableCombo}
        />
        </div>
        <div className='col'>
        <div>Model:</div>
      
      <ComboBox
          style={{
            width: "300px",
          }}
          data={PortfolioBreakDownRptData.t1}
          textField="modelDesc"
          dataItemKey="modelId"
          filterable={true}
          value={selModel}
          onChange={modelChange}
          onFilterChange={filtermodelChange}
        />

        </div>
        <div className='col'>
        <div>Benchmark:</div>
        <ComboBox
          style={{
            width: "300px",
          }}
          data={PortfolioBreakDownRptData.t2}
          textField="modelDesc"
          dataItemKey="modelId"
          filterable={true}
          value={selBnchmrk}
          onChange={bnchmrkChange}
          //onFilterChange={filterbnchmrkChange}
        />

        </div>




      </div>
      </div>
      <div className='reportheading fs-6 mx-1 px-1 mt-1'>Portfolio Breakdown Report </div>
      
      </div>
      <Loading />
   </div>
   }</>
    )
}
return (
  <div>
    <Header></Header>
    <div className='row d-flex justify-content-between align-items-center mx-1 shadow-sm rounded'>
      
     
      
      <div className='row d-flex justify-content-between align-items-center my-1 mx-1 px-1 bg-light'>
      
      <div className='row d-flex justify-content-center align-items-center'>

        <div className='col'>
        <div> Account(s):</div>
        <ComboBox
          style={{
            width: "350px",
          }}
          data={selAcctData}
          textField="extrnlAcctId"
          dataItemKey="acctId"
          filterable={true}
          value={selAcct}
          onChange={handleChange}
          onFilterChange={filterChange}
          disabled={enableCombo}
        />
        
        </div>
        <div className='col'>
        <div>Model:</div>
      
      <ComboBox
          style={{
            width: "300px",
          }}
          data={PortfolioBreakDownRptData.t1}
          textField="modelDesc"
          dataItemKey="modelId"
          filterable={true}
          value={selModel}
          onChange={modelChange}
          //onFilterChange={filtermodelChange}
        />

        </div>
        <div className='col'>
        <div>Benchmark:</div>
        <ComboBox
          style={{
            width: "300px",
          }}
          data={PortfolioBreakDownRptData.t2}
          textField="modelDesc"
          dataItemKey="modelId"
          filterable={true}
          value={selBnchmrk}
          onChange={bnchmrkChange}
          
          //onFilterChange={filterbnchmrkChange}
        />
        
        </div>
        <div className='col'>

        
        {/* <button
                                    className="btn btn-outline-primary btn-sm"
                                    onClick={showpreview}
                                >
                                   <span className='px-1'><FaFilePdf /></span>PDF Preview
                                </button> */}

                                </div>



      </div>
      </div>
      <div className='row d-flex justify-content-between align-items-center mx-1 px-1 mt-1'>
      <div className='col-md-4'>
      <div className='reportheading fs-6 h6'>Portfolio Breakdown Report</div>
      
      </div>
      <div className='subheader col-lg text-end'>Due to rounding, percentage may not equal 100.</div>

      </div>
      
      
        
     <PortfolioBreakDown1stGrid data={PortfolioBreakDownRptData.t4} flag={isColumnSave} showExport={true} dispAssetClass={dispAssetClass} reportData = {PortfolioBreakDownRptData}/>
     <PortfolioBreakDown2ndGrid data={PortfolioBreakDownRptData.t5} flag={isColumnSave} showExport={true} dispAssetClass={dispAssetClass2}/>
     <PortfolioBreakDown3rdGrid data={PortfolioBreakDownRptData.t6} flag={isColumnSave} showExport={true}/>
     <Modal show={show} onHide={handleClose} fullscreen={true} size="xl" >
                    <Modal.Header closeButton><div className="row w-100 tableheader">
                        <div className='col'>
                            <Modal.Title>Portfolio Breakdown Report - Pdf Preview</Modal.Title>
                        </div>
                        
                        <div className='col text-end'>
                            <button
                                className="btn btn-outline-primary btn-sm"
                                onClick={printPDF}
                            >
                                Print
                            </button>
                        </div>


                    </div>
                    </Modal.Header>
                    <Modal.Body>
                        <PDFExport
                            margin={{ top: 70, left: 30, right: 30, bottom: 40 }}
                            pageTemplate={PageTemplate}
                            forcePageBreak=".page-break"
                            paperSize="A4"
                            scale={0.5}
                            allPages={true}
                          
                            landscape={true}
                            repeatHeaders={true}

                            ref={(pdfExport) => (allPDFExport = pdfExport)}>

                            Account Name : {selAcct.extrnlAcctId}
                            <hr></hr>
                            
                            <PortfolioBreakDown1stGrid data={PortfolioBreakDownRptData.t4} flag={isColumnSave} showExport={true} dispAssetClass={dispAssetClass} reportData = {PortfolioBreakDownRptData}/>
     <PortfolioBreakDown2ndGrid data={PortfolioBreakDownRptData.t5} flag={isColumnSave} showExport={true} dispAssetClass={dispAssetClass2}/>
     <PortfolioBreakDown3rdGrid data={PortfolioBreakDownRptData.t6} flag={isColumnSave} showExport={false}/>

                        </PDFExport>

                    </Modal.Body>
                    <Modal.Footer>



                        <button className="btn btn-primary  btn-sm" onClick={handleClose}>
                            Close
                        </button>


                    </Modal.Footer>

                </Modal>
     </div>
     
  </div>
)
  }
  
  export default RTPortfolioBreakDownRpt