import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// import validator from 'validator';
import Select, { SelectChangeEvent } from "@mui/material/Select";
//import SelectControl from './selectcontrol';
import Loading from "./loading";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { lastDayOfMonth, isEqual } from "@progress/kendo-date-math";
//import DailyPerformanceGrid from "./dailyPerformanceGrid";
import Header from "./header";
import { Button, Card } from "@mui/material";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import refreshFunction from './refreshFunc';
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
// import { abs } from 'mathjs'
import { CustomCalendar } from "./customCalendar";
import { Dropdown } from "react-bootstrap";
import Enumerable from "linq";

import { FaInfoCircle } from "react-icons/fa";
// import { Popover } from "@progress/kendo-react-tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import PerformanceRiskMetricsGrid from "./performanceRiskMetricsGrid";
function PerformanceRiskMetricsRpt() {
  const [PerformanceRiskMetricsRPTData, populatePerformanceRiskMetricsRPTData] = useState([]);
  const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  const [toDate, setToDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const [frmDate, setFrmDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const minFrmDt = new Date(localStorage.getItem("processingDate"));
  minFrmDt.setFullYear(minFrmDt.getFullYear() - 4);
  const maxFrmDt = new Date(localStorage.getItem("processingDate"));
  
  const [isDisabled, setisDisabled] = React.useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        let ConsolidationId =JSON.parse(localStorage.getItem("pConsId")) == null ? 0 : localStorage.getItem("pConsId");
        
        //setEmail(email);
        GetPerformanceRiskMetricsRPTData();
        //getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);
  function setDate(val) {
    

    // if(val.language === '1'){
    //  const fDate = new Date("2021-04-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
    if (val == null) {
      // const fDate = new Date("2021-01-22");
      setToDate(toDate);
    } else {
      setToDate(val);
    }
    //     const setisDisabled = true;
    // }
    //setToDate(val);
  }
  function setfrmDate(val) {
    

    // if(val.language === '1'){
    //  const fDate = new Date("2021-04-22");

    //     setfrmDate(fDate);
    //     const setisDisabled = false;
    // }
    // if(val.language === '2'){
    if (val == null) {
      // const fDate = new Date("2021-01-22");
      setFrmDate(frmDate);
    } else {
      setFrmDate(val);
    }
    //     const setisDisabled = true;
    // }
    //setToDate(val);
  }
  const searchClick = () => {
    GetPerformanceRiskMetricsRPTData();
  };
  const formSubmit = (event) => {
    GetPerformanceRiskMetricsRPTData();
  };
  const GetPerformanceRiskMetricsRPTData = async () => {
    //
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let UserId = JSON.parse(localStorage.getItem("userId"));
    //let UserId =269406
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = 0;
  
    if (lsSelectedAcct !== null) {
      AcctId = lsSelectedAcct.acctId;
    }
    let ConsolidationId =0;
    let mConsolidationId = 0;
    let RltnshpCd = "";
    //let AcctId= 46140;
    let AccountId =AcctId
    const postData = {
        AccountId,
        UserId,
      ConsolidationId
    };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
    .post("/RTGetPerformanceRiskMetrics", postData, config)
    .then((response) => {
          
        //  console.log(response);
        //
        const rowData = response.data;
        populatePerformanceRiskMetricsRPTData(rowData.t1);
        // populateAccountListData(rowData.lstAcctTmWtdRtnList);
        // setSelAcctData(rowData.lstAcctTmWtdRtnList);
        setReportData(rowData);
        if (lsSelectedAcct !== null) {
          SetselAcct(lsSelectedAcct);
        } else {
          SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        }
        // getColumnStateDb();
        setLoading(false);
        setFlag(1);
        console.log("postData", postData)
        console.log("rowData", rowData)
      })
      .catch((error) => {
        
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetPerformanceRiskMetricsRPTData();
      })
      .catch((error) => {
        // 
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

//   const getColumnStateDb = async () => {
//     //Storing column settings in DB
//     
//     let token = JSON.parse(localStorage.getItem("token"));
//     let UserId = JSON.parse(localStorage.getItem("userId"));
//     let GridId = 33;

//     const postData = { UserId, GridId };
//     const config = {
//       headers: {
//         authorization: `Bearer ${token.token}`,
//         Accept: "application/json",
//         "Content-Type": "application/json",
//       },
//     };
//     await axios
//       .post("/RTGetGridColumn/Index", postData, config)
//       .then((response) => {
//         
//         console.log("save in db");
//         console.log(response);
//         const rowData = response.data;
//         if (rowData !== "") {
//           setIsColumnSave(true);
//           localStorage.setItem("gridColumns", rowData);
//         } else {
//           setIsColumnSave(false);
//         }
//       })
//       .catch((error) => {
//         console.log("error in save db " + error);
//         return error;
//       });
//   };

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetPerformanceRiskMetricsRPTData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        // 
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Header></Header>
            <Loading />
          </div>
        )}
      </>
    );
  }
  return (
    <form onSubmit={formSubmit}>
      <div>
        <Header></Header>

        <div className="mt-1">
          <div className="px-2 mx-2 bg-light shadow-sm rounded">
            <div className="table-responsive">
              <table className="table table-borderless mb-0">
                <tbody>
                  <tr>
                    <td
                      className="text-start"
                      style={{ verticalAlign: "bottom", minWidth: "240px" }}
                    >
                      <span className="px-2 d-block d-md-inline">
                        Account(s)
                      </span>
                      <ComboBox
                        style={{ width: "100%", maxWidth: "350px" }}
                        data={selAcctData}
                        textField="extrnlAcctId"
                        dataItemKey="acctId"
                        filterable={true}
                        value={selAcct}
                        onChange={handleChange}
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        
          <PerformanceRiskMetricsGrid
            data={PerformanceRiskMetricsRPTData}
            reportData={reportData}
          />
      </div>
    </form>
  );
}

export default PerformanceRiskMetricsRpt;
