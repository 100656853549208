import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import SelectControl from './selectcontrol';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Moment from 'react-moment';
import Loading from './loading';
import Header  from './header';

import AssetGrowthAndAllocationChart from './assetGrowthAndAllocationChart' 

import Modal from 'react-bootstrap/Modal';
import { FcExpired} from 'react-icons/fc';
import { useLocation, useNavigate } from 'react-router-dom';
import { formatDate } from '@telerik/kendo-intl';

const AssetGrowthAndAllocationRpt = () => {

  //const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
  const [assetGrowthAndAllacData, setassetGrowthAndAllacData] = useState([]);
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')));
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
  //  const [selectedAcct, setSelectedAcct] = useState(0);
    const [loading, setLoading] = useState(true);
   // const [isColumnSave, setIsColumnSave] = useState(false);
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [session,setSession]=useState("");
    const [enableCombo,setEnableCombo]=useState(false);
    const navigate = useNavigate();

    useEffect(() => {

      const fetchData = async () => {
          setLoading(true);
          setEnableCombo(true);
          try {
              //let data = location.state;

              let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
              getAssetGrowthAndAllocationData();
              //getColumnStateDb();
          } catch (error) {
              console.error(error.message);
          }

      }
      fetchData();
  }, [selAcct]);

  const  getAssetGrowthAndAllocationData=async()=>{
    setLoading(true);
    setEnableCombo(true);
   // 
    let token = tempToken;
    let asOfId = JSON.parse(localStorage.getItem('userId'));

    let acctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
    let pageId=1;
    let consolidationId = 0;
   
    const postData = { asOfId, acctId, pageId, consolidationId };
    const config = {
        headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }

    };
    await axios.post('/RTGetAstGrwthAlctn',
    postData,
        config
    ).then((response => {
        
        const rowData = response.data;
        setassetGrowthAndAllacData(rowData);
       // setAssetAllocModelRptData(rowData.t1);
        //getColumnStateDb();
        setLoading(false);
        setEnableCombo(false);
        //postData.modelId = response.data.acctMdl[0].modelId;
        console.log("Row Data",rowData)
    })
    )

        .catch((error) => {
            
            if (error.response.status === 401) {
                refreshToken();
            }
        });

}

const handleChange = (event) => {
  
  if (event.target.value === null || loading) {
    console.log("Null one has been called");
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
  }
  else {
    console.log("I have been updated");
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
     // getTopHoldingData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
      getAssetGrowthAndAllocationData()
  }



};

const refreshToken = async () => {
  
  let token = tempToken;
  let AccessToken = token.token;
  let RefreshToken = token.refreshToken;
  const postData = { AccessToken, RefreshToken };
  const config = {
    headers: {
      'authorization': `Bearer ${token.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

  };
  await axios.post('/token/Refresh',
    postData

  )
    .then((response) => {
      
      tempToken = response.data;
      localStorage.setItem('token', JSON.stringify(response.data));

     // getTopHoldingData();


    })
    .catch((error) => {
      // 
      if(error.code==="ERR_BAD_REQUEST"){
        setSession("Session Expired");
      }
      console.log("my error is " + error);
    })

}

const signOut = () => {

  navigate("/");
  let token = JSON.parse(localStorage.getItem('token'));
  const postData = {};
  const config = {
    headers: {
      'authorization': `Bearer ${token.token}`,
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }

  };
  axios.post('/token/revoke',
  postData,
  config
  )
    .then((response) => {
    // 
    // localStorage.setItem('token', '');
     //console.log(response);
    // navigate("/");
        
      // if (response.statusText === '') {
       


      // }

     
    })
    .catch((error) => {
      // 
      console.log("my error is " + error);
    })

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem('token');
    localStorage.clear();
  //firebaseApp.auth.signOut();

}

if (loading) {
  return (
    <>
      {session==="Session Expired"?

<Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} onClick={signOut}>OK</a></div></Modal.Body></Modal>
:
<div>
      <Header></Header>
      <div className='my-1'>
          {/* <div className="rounded"></div> */}
          <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
              <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                  <span className='px-2'>Account(s)</span>
                  <ComboBox
                      style={{
                          width: "350px",
                      }}
                      data={selAcctData}
                      textField="extrnlAcctId"
                      dataItemKey="acctId"
                      filterable={true}
                      value={selAcct}
                      onChange={handleChange}
                      disabled={enableCombo}
                  // onFilterChange={filterChange}
                  />
              </div>
          </div>
      </div>
      <Loading />
     {/* <AssetGrowthAndAllocationChart data={assetGrowthAndAllacData} /> */}
      </div>}</>
  )
}
return (
  <div>
      <Header></Header>
      <div className='my-1'>
          {/* <div className="rounded"></div> */}
          <div className='row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
              <div className='col-md-4 col-lg-5 col-sm-11 text-start'>
                  <span className='px-2'>Account(s)</span>
                  <ComboBox
                      style={{
                          width: "350px",
                      }}
                      data={selAcctData}
                      textField="extrnlAcctId"
                      dataItemKey="acctId"
                      filterable={true}
                      value={selAcct}
                      onChange={handleChange}
                      disabled={enableCombo}
                  // onFilterChange={filterChange}
                  />
                 
              </div>
              <AssetGrowthAndAllocationChart assetGrowthAndAllacData={assetGrowthAndAllacData} />
          </div>
          
      </div>
     

 
      
  </div>
)
}

export default AssetGrowthAndAllocationRpt
