import React from 'react'
import logo from '../Images/zions.png';
const BankLogoPage = () => {
const styleLogo ={
width: '180px'

}


  return (
    <div className='d-flex justify-content-around'>
      <div>

      <img style={styleLogo} src={logo}></img>
      </div>
      <div>
        
      </div>
      
    </div>
  )
}

export default BankLogoPage
