import { useState, useEffect, useMemo, useRef } from 'react';
import axios from 'axios';
const CustomActiveReport=()=>{
    var tempToken = JSON.parse(localStorage.getItem('token'));
    const [session,setSession]=useState("");
    useEffect(() => {
        GetCustomReportData();
    }, )
    const refreshToken = async () => {
        let token = JSON.parse(localStorage.getItem('token'));
        let AccessToken = token.token;
        let RefreshToken = token.refreshToken;
        const postData = { AccessToken, RefreshToken };
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        await axios.post('/token/Refresh',
          postData
    
        )
          .then((response) => {
            
            tempToken = response.data;
            localStorage.setItem('token', JSON.stringify(response.data));
    
            GetCustomReportData();
    
          })
          .catch((error) => {
            // 
            if(error.code==="ERR_BAD_REQUEST"){
              setSession("Session Expired");
            }
            console.log("my error is " + error);
          })
    
      }
    const GetCustomReportData = async (roleId, cntctId, acctId) => {
        // setLoading(true);
        
        //let token = JSON.parse(localStorage.getItem('token'));
        let token = tempToken;
        let UserId = JSON.parse(localStorage.getItem('userId'));
        let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;
        let Accounts = "<root> <Account AcctId='" + AcctId + "'/> </root>";
        //SetselAcct(JSON.parse(localStorage.getItem('AcctSelected')).acctId);
        let modelId = JSON.parse(localStorage.getItem("modelId"))//-1;
        let invMix = 0;
        let NumOfRows = 10;
        let PageId = 1;
        let TemplateId = JSON.parse(localStorage.getItem('tmpltIdFrmDshBrd'));
        const postData = { UserId, AcctId, TemplateId };//{UserId,Accounts,modelId,invMix,NumOfRows,AcctId,PageId};
        const config = {
          headers: {
            'authorization': `Bearer ${token.token}`,
            'Accept': 'application/json',
            'Content-Type': 'application/json'
          }
    
        };
        await axios.post('/ActiveReport',
          postData,
          config
        )
          .then(response => {
            
            //  console.log(response);
    
            const rowData = response.data;
            
            // setFlag(true);
            // setLoading(false);
    
          })
          .catch((error) => {
            
            console.log(error);
            if (error.response.status === 401) {
              refreshToken();
    
            }
          });
    
      }


    return(<></>)
}
export default CustomActiveReport