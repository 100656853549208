import * as React from "react";
import * as ReactDOM from "react-dom";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useState, useEffect, useRef } from 'react';
import { ColumnMenu } from "./columnMenu";
import { process } from "@progress/kendo-data-query";
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { ResponsiveContainer } from "recharts";
import {perfMjrAsstSummaryDatavar} from './cstmRptMain';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
// const aggregates = [
//     {
//       field: "market",
//       aggregate: "sum",
//     }
  
//   ];

  const AcctperfSummaryWidget= () => {
    
    const gridRef = useRef();
    //const [portfolioHoldingsdata, setPortfolioHoldingsdata] = useState(JSON.parse(global.localStorage.getItem("portfoliHoldingData")));
    //console.log({cashflowDatavar});
    const totalSum = (props) => {
        const field = props.field || "";
        const total = cashflowdata.reduce((acc, current) => acc + current[field], 0).toFixed(2);;
        return (
          <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
            {formatNumber(total, '##,#.00')}
          </td>
        );
      };
    let defaultColumns = [
            {
              title: 'Acct. Number',
              field: 'extrnlAcctId',
              minWidth: "auto",
              show: true,
              filter: 'text',
              locked: false,
            },
            {
              title: 'Account Name',
              field: 'shrtNm',
              minWidth: "auto",
              show: true,
              filter: 'text',
              locked: false,
            },
            {
              title: 'Start Market',
              field: 'startMarket',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              headerClassName: 'rightHeader',
              footerCell: totalSum,
            },
            {
              title: 'Admin Receipts',
              field: 'receipt',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
              footerCell: totalSum,
            },
            {
              title: 'Disbursement',
              field: 'disbursement',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
              footerCell: totalSum,
            },
            {
              title: 'Earned Income',
              field: 'income',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
              footerCell: totalSum,
            },
            {
              title: 'Realized Gain/Loss',
              field: 'rlGainLoss',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
              footerCell: totalSum,
            },
            {
              title: 'Unrealized Gain/Loss',
              field: 'ulGainLoss',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
              footerCell: totalSum,
            },
            {
              title: 'End Market',
              field: 'endMarket',
              minWidth: "auto",
              show: true,
              filter: 'numeric',
              locked: false,
              headerClassName: 'rightHeader',
              footerCell: totalSum,
            },
        
          ];
    const [cashflowdata, setcashflowdata] = useState(perfMjrAsstSummaryDatavar);
    const [stateColumns, setStateColumns] = useState(defaultColumns);
    const createDataState = (dataState) => {
        return {
          result: process(cashflowdata.slice(0), dataState),
          dataState: dataState,
        };
      };
      let initialState = createDataState({
        take: 20,
        skip: 0,
      });
      const [result, setResult] = React.useState(initialState.result);
      const [dataState, setDataState] = React.useState(initialState.dataState);
      const [sort, setSort] = React.useState([]);
      const dataStateChange = (event) => {
        let updatedState = createDataState(event.dataState);
        setResult(updatedState.result);
        setDataState(updatedState.dataState);
      };

      const onColumnsSubmit = (columnsState) => {
        setStateColumns(columnsState);
    };

    const NumberCell = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.00")}
            </td>
        )
    }
    const NumberCellSixDecimal = (props) => {
        return (
            <td style={{ textAlign: 'right' }}>
                {formatNumber(props.dataItem[props.field], "##,#.000000")}
            </td>
        )
    }
    const cellRender = (tdElement, cellProps) => {
      if (cellProps.rowType === "data") {
        if (cellProps.field === "startMarket") {
    
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "receipt") {
    
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "disbursement") {
    
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "income") {
    
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "rlGainLoss") {
    
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "ulGainLoss") {
    
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
        if (cellProps.field === "endMarket") {
    
          return (
            <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
              {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
            </td>
          );
        }
      }

      return tdElement;
    }
      
    return(
        
                <ResponsiveContainer className='page-break'>   
                <div><h5 className='text-fitek p-1'>Performance Summary Report

</h5>      
            <Grid
              style={{ width: '100%', height: "350px"}}
              id='tileGrid'
              data={result}
              {...dataState}
              onDataStateChange={dataStateChange}
              expandField="expanded"
              //onExpandChange={expandChange}
              cellRender={cellRender}
              sortable={true}
              scrollable={true}
              resizable={true}
              reorderable={true}
              
              //pageable={true}
              //pageSize={10}
              groupable={{
                footer: 'visible',
                enabled:false
              }}
              //
              ref={gridRef.current}
              //onColumnReorder={onColumnReorder}
              //onColumnResize={onColumnResize}
            //
            >
              {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      //width={setWidth(column.minWidth)}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      headerClassName={column.headerClassName}
                      {...column}
                      columnMenu={(props) => (
                        <CustomColumnMenuNoGrp
                          {...props}
                          columns={stateColumns}
                          onColumnsSubmit={onColumnsSubmit}
                        />
                      )}
                    />
                  )
              )}
            </Grid>
            </div>
          </ResponsiveContainer>          
      
                    

                
    )

}
  export default AcctperfSummaryWidget

