import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// import validator from 'validator';
import Select, { SelectChangeEvent } from "@mui/material/Select";
//import SelectControl from './selectcontrol';
import Loading from "./loading";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { lastDayOfMonth, isEqual } from "@progress/kendo-date-math";
import DailyPerformanceGrid from "./dailyPerformanceGrid";
import Header from "./header";
import { Button, Card } from "@mui/material";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import refreshFunction from './refreshFunc';
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
// import { abs } from 'mathjs'
import { CustomCalendar } from "./customCalendar";
import { Dropdown } from "react-bootstrap";
import Enumerable from "linq";

import { FaInfoCircle } from "react-icons/fa";
// import { Popover } from "@progress/kendo-react-tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import SelectedPeriodRORSMAGrid from "./selectedPeriodRORSMAGrid";
import BenchmarkSelPeriodPerGrid from "./benchmarkSelPeriodPerGrid";

const SelectedPeriodRORSMARpt = () => {
  const [selectedPerRORSMARptData, populateSelectedPerRORSMARptData] = useState(
    []
  );
  const [selectedPerRORSMAData, setSelectedPerRORSMAData] = useState([]);
  const [TimeWtdRtnRptRptDataChart, populateTimeWtdRtnRptRptDataChart] =
    useState([]);
  const [reportData, setReportData] = useState([]);
  const [IncepDt, setIncepDt] = useState();
  //const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  var date = new Date(localStorage.getItem("processingDate"));
  date.setMonth(date.getMonth() - 1);
  const [frmDate, setfrmDate] = React.useState(date);
  //const [toDate, setToDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const [asOfDate, setAsOfDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(true);
  const [isChckedBM, setisChckedBM] = useState(false);
  const [openDuration, setOpenDuration] = React.useState(100);
  const [closeDuration, setCloseDuration] = React.useState(300);
  // const minFrmDt = new Date(2021, 8, 14);

  const maxFrmDt = new Date(localStorage.getItem("processingDate"));
  const minFrmDt = new Date(localStorage.getItem("processingDate"));
  minFrmDt.setMonth(minFrmDt.getMonth() - 60);
  const [isDisabled, setisDisabled] = React.useState(false);

  let acctId = 0;
  let AccountId = JSON.parse(localStorage.getItem("AcctSelected"));
  if (AccountId != null) {
    acctId = AccountId.acctId;
  }

  const [monthlyOrDaily, setMonthlyOrDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? "Monthly"
      : "Daily"
  );
  const [isDisabledDaily, setisDisabledDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? true
      : false
  );
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  //const [CardData, populateTimeWtdRtnRptRptCardData] = useState([]);
  const navigate = useNavigate();

  const [dateType, setDateType] = React.useState(1);

  useEffect(() => {
    // debugger;
    const fetchData = async () => {
      setLoading(true);
      try {
        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        GetTimeWtdRtnRpteData();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  const handelChkBM = (e) => {
    setisChckedBM(e.target.checked);
  };

  const handleChangemonthlyOrDaily = (e) => {
    setMonthlyOrDaily(e.target.value);
  };
  const handelSubmit = (event) => {
    // debugger;
    //  var date = new Date(asOfDate);
    //  var month =date.getMonth();
    //  var year =date.getFullYear();
    //  var day=date.getDate();
    //var lDay= lastDayOfMonth(new Date(year,month,day));

    //let date = dtfrm.selAcctData;
    setfrmDate(asOfDate);
    //  if(validator.isDate(asOfDate)&&(asOfDate>=minFrmDt)&&asOfDate<=maxFrmDt){
    //
    GetTimeWtdRtnRpteData();
    //  }
  };

  const formSubmit = (event) => {
    GetTimeWtdRtnRpteData();
  };

  const GetTimeWtdRtnRpteData = async () => {
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let asOfId = JSON.parse(localStorage.getItem("userId")); //3689
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = lsSelectedAcct.acctId;
    let PageId = 1;
    let netFeeId = 1;

    var date = new Date(asOfDate);
    var month = date.getMonth();
    var year = date.getFullYear();
    var day = date.getDate();
    var lDay = lastDayOfMonth(new Date(year, month, day));
    var IsDaily = 0;

    
    var pageId = 1;
    var benchmarkList = "14,2,3,11,6,5,8,7,4,1,9";
    
    var consolidationId = 0;
    if (monthlyOrDaily == "Daily") IsDaily = 0;


    // setAsOfDate(lDay);
    var lDay=lastDayOfMonth(asOfDate);
    if (!isEqual(asOfDate, lDay) && IsDaily == 0) {
      lDay = lastDayOfMonth(new Date(year, month - 1, day));

      setAsOfDate(lDay);
    } else {
      lDay = asOfDate;
      setAsOfDate(lDay);
    }
    debugger;
    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }
   // let AsofDate = lDay;
   debugger;
    let ConsolidationId =
      JSON.parse(localStorage.getItem("pConsId")) == null
        ? 0
        : localStorage.getItem("pConsId");
    AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let ShowBenchmark = 0;
    //  setAsOfDate("09/30/2024");
    let AsOfDt = formatDate(lDay, "MM/dd/yyyy");
    // setAsOfDate(AsOfDt);
    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");
    if (isChckedBM) ShowBenchmark = 1;
    
    const postData = {
      asOfId,
      AcctId,
      consolidationId,
      AsOfDt,
    };
    debugger;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTGetSelectedRORSMA", postData, config)
      .then((response) => {
        debugger;
        const rowData = response.data;
        const rowData1 = {
          ocSelectedRORSMAT1: [
            {
              idn: 1,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 1,
              categoryIdentifier: "Total Portfolio Return Gross of Fees",
              perfTmpltId: 0,
              l1PerfTmpltCtgryId: 0,
              l2PerfTmpltCtgryId: 0,
              categoryNm: "Total Portfolio Return(Gross of Fees)",
              subCategoryNm: "",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 1378933.98,
              retOneMonth: 3.27,
              retThreeMonth: 2.26,
              retYTD: 5.24,
              retOneYear: 7.81,
              retTwoYear: -0.48,
              retThreeYear: 6.63,
              retFourYear: 4.76,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 4.96,
              categoryNmfinal: "Total Portfolio Return(Gross of Fees)",
            },
            {
              idn: 2,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 1,
              categoryIdentifier: "Total Portfolio Return Net of Fees",
              perfTmpltId: 0,
              l1PerfTmpltCtgryId: 0,
              l2PerfTmpltCtgryId: 0,
              categoryNm: "Total Portfolio Return(Net of Fees)",
              subCategoryNm: "",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 1378933.98,
              retOneMonth: 3.2,
              retThreeMonth: 2.05,
              retYTD: 4.81,
              retOneYear: 6.94,
              retTwoYear: -1.29,
              retThreeYear: 5.77,
              retFourYear: 3.92,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 4.12,
              categoryNmfinal: "Total Portfolio Return(Net of Fees)",
            },
            {
              idn: 3,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 1,
              categoryIdentifier:
                "Total Portfolio Return Gross of Fees - Managed",
              perfTmpltId: 0,
              l1PerfTmpltCtgryId: 0,
              l2PerfTmpltCtgryId: 0,
              categoryNm: "Total Portfolio Return - Managed(Gross of Fees)",
              subCategoryNm: "",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 1378933.98,
              retOneMonth: 3.27,
              retThreeMonth: 2.26,
              retYTD: 5.24,
              retOneYear: 7.81,
              retTwoYear: -0.48,
              retThreeYear: 6.63,
              retFourYear: 4.76,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 4.96,
              categoryNmfinal:
                "Total Portfolio Return - Managed(Gross of Fees)",
            },
            {
              idn: 4,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 1,
              categoryIdentifier:
                "Total Portfolio Return Net of Fees - Managed",
              perfTmpltId: 0,
              l1PerfTmpltCtgryId: 0,
              l2PerfTmpltCtgryId: 0,
              categoryNm: "Total Portfolio Return - Managed(Net of Fees)",
              subCategoryNm: "",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 1378933.98,
              retOneMonth: 3.2,
              retThreeMonth: 2.05,
              retYTD: 4.81,
              retOneYear: 6.94,
              retTwoYear: -1.29,
              retThreeYear: 5.77,
              retFourYear: 3.92,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 4.12,
              categoryNmfinal: "Total Portfolio Return - Managed(Net of Fees)",
            },
            {
              idn: 5,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 3,
              categoryIdentifier: "Level 1 Segment",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 97,
              l2PerfTmpltCtgryId: 0,
              categoryNm: "Cash Equivalents",
              subCategoryNm: "",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 52983.63,
              retOneMonth: 0.39,
              retThreeMonth: 1.2,
              retYTD: 2.34,
              retOneYear: 3.74,
              retTwoYear: 1.98,
              retThreeYear: 1.33,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 1.33,
              categoryNmfinal: "Cash Equivalents",
            },
            {
              idn: 6,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 4,
              categoryIdentifier: "Level 1 Segment – Managed",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 97,
              l2PerfTmpltCtgryId: -1,
              categoryNm: "Cash Equivalents",
              subCategoryNm: "Cash Equivalents – Managed",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 52983.63,
              retOneMonth: 0.39,
              retThreeMonth: 1.2,
              retYTD: 2.34,
              retOneYear: 3.74,
              retTwoYear: 1.98,
              retThreeYear: 1.33,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "    Cash Equivalents – Managed",
            },
            {
              idn: 7,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 97,
              l2PerfTmpltCtgryId: 105,
              categoryNm: "Cash Equivalents",
              subCategoryNm: "Cash Equivalent",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 478,
              posNm: "Invesco STIT Treasury",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 49611.34,
              retOneMonth: 0.41,
              retThreeMonth: 1.26,
              retYTD: 2.45,
              retOneYear: 3.93,
              retTwoYear: 2.03,
              retThreeYear: 1.36,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 1.33,
              categoryNmfinal: "",
            },
            {
              idn: 8,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 3,
              categoryIdentifier: "Level 1 Segment",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 0,
              categoryNm: "Equities",
              subCategoryNm: "",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 746872.93,
              retOneMonth: 6.17,
              retThreeMonth: 4.01,
              retYTD: 7.79,
              retOneYear: 12.6,
              retTwoYear: 0.27,
              retThreeYear: 12.16,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 10.25,
              categoryNmfinal: "Equities",
            },
            {
              idn: 9,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 4,
              categoryIdentifier: "Level 1 Segment – Managed",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: -1,
              categoryNm: "Equities",
              subCategoryNm: "Equities – Managed",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 746872.93,
              retOneMonth: 6.17,
              retThreeMonth: 4.01,
              retYTD: 7.79,
              retOneYear: 12.6,
              retTwoYear: 0.27,
              retThreeYear: 12.16,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 10.25,
              categoryNmfinal: "    Equities – Managed",
            },
            {
              idn: 10,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 107,
              categoryNm: "Equities",
              subCategoryNm: "Large Cap Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1399,
              posNm: "Cohen & Steers Instl Realty Fd",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 38419.49,
              retOneMonth: 5.34,
              retThreeMonth: 3.13,
              retYTD: 5.63,
              retOneYear: -2.36,
              retTwoYear: -3.47,
              retThreeYear: 8.21,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 8.85,
              categoryNmfinal: "",
            },
            {
              idn: 11,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 107,
              categoryNm: "Equities",
              subCategoryNm: "Large Cap Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 28256,
              posNm: "FlexShares Glbl Upstream Nat Res ETF",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 19233.6,
              retOneMonth: 5.35,
              retThreeMonth: -4.02,
              retYTD: -6.24,
              retOneYear: 4.84,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 12,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 121,
              categoryNm: "Equities",
              subCategoryNm: "Emerging Market Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1650,
              posNm: "Vanguard Ftse Emerging Markets ETF",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 41493.6,
              retOneMonth: 4.75,
              retThreeMonth: 1.25,
              retYTD: 5.01,
              retOneYear: 0,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 13,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 122,
              categoryNm: "Equities",
              subCategoryNm: "Mid Cap Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 12973,
              posNm: "JHancock Disciplined Value Mid Cap Instl",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 65680.33,
              retOneMonth: 9.46,
              retThreeMonth: 6.06,
              retYTD: 7.54,
              retOneYear: 16.41,
              retTwoYear: 2.84,
              retThreeYear: 17.65,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 17.01,
              categoryNmfinal: "",
            },
            {
              idn: 14,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 122,
              categoryNm: "Equities",
              subCategoryNm: "Mid Cap Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 25765,
              posNm: "T Rowe Price Midcap Growth Fd Instl",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 57997.87,
              retOneMonth: 7.4,
              retThreeMonth: 5.32,
              retYTD: 12.96,
              retOneYear: 17.4,
              retTwoYear: -4.07,
              retThreeYear: 8.6,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 8.94,
              categoryNmfinal: "",
            },
            {
              idn: 15,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 123,
              categoryNm: "Equities",
              subCategoryNm: "Small Cap Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 13536,
              posNm: "Vanguard Sm Cap Growth Adm",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 14449.89,
              retOneMonth: 7.69,
              retThreeMonth: 6.21,
              retYTD: 14.9,
              retOneYear: 17.44,
              retTwoYear: -10.48,
              retThreeYear: 5.31,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 6.19,
              categoryNmfinal: "",
            },
            {
              idn: 16,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 123,
              categoryNm: "Equities",
              subCategoryNm: "Small Cap Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 2065,
              posNm: "Victory Small Co Opp Fund",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 15007.98,
              retOneMonth: 7.03,
              retThreeMonth: 1.07,
              retYTD: 3.67,
              retOneYear: 12.35,
              retTwoYear: 1.2,
              retThreeYear: 16.65,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 16.4,
              categoryNmfinal: "",
            },
            {
              idn: 17,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 133,
              categoryNm: "Equities",
              subCategoryNm: "International Developed Mkt Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1011,
              posNm: "Ishares Msci Eafe ETF",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 34945,
              retOneMonth: 4.49,
              retThreeMonth: 3.25,
              retYTD: 12.49,
              retOneYear: 18.63,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 18,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 134,
              categoryNm: "Equities",
              subCategoryNm: "Global Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 2106,
              posNm: "SPDR S&P Intl Small Cap ETF",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 26177.58,
              retOneMonth: 2.96,
              retThreeMonth: 0.03,
              retYTD: 5.34,
              retOneYear: 0,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 19,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 8,
              posNm: "Abbott Laboratories ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5014.92,
              retOneMonth: 6.88,
              retThreeMonth: 8.19,
              retYTD: 0.24,
              retOneYear: 1.58,
              retTwoYear: -1.68,
              retThreeYear: 7.55,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 6.05,
              categoryNmfinal: "",
            },
            {
              idn: 20,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 14106,
              posNm: "Abbvie Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 7544.88,
              retOneMonth: -2.34,
              retThreeMonth: -14.69,
              retYTD: -15.12,
              retOneYear: -8.7,
              retTwoYear: 13.81,
              retThreeYear: 16,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 17.72,
              categoryNmfinal: "",
            },
            {
              idn: 21,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 9,
              posNm: "Air Products & Chemicals Inc Com ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4492.95,
              retOneMonth: 11.94,
              retThreeMonth: 4.9,
              retYTD: -1.67,
              retOneYear: 27.59,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 22,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1083,
              posNm: "Altria Group Inc Com",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3578.7,
              retOneMonth: 4.16,
              retThreeMonth: 3.7,
              retYTD: 3.41,
              retOneYear: 18.1,
              retTwoYear: 6.08,
              retThreeYear: 13.8,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 14.38,
              categoryNmfinal: "",
            },
            {
              idn: 23,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 10,
              posNm: "American Electric Power Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3283.8,
              retOneMonth: 1.3,
              retThreeMonth: -6.62,
              retYTD: -9.67,
              retOneYear: -9.08,
              retTwoYear: 3.3,
              retThreeYear: 5.45,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 3,
              categoryNmfinal: "",
            },
            {
              idn: 24,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 284,
              posNm: "Amgen Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 2664.24,
              retOneMonth: 0.62,
              retThreeMonth: -7.29,
              retYTD: -13.91,
              retOneYear: -5.71,
              retTwoYear: -1.39,
              retThreeYear: 1.09,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 1.93,
              categoryNmfinal: "",
            },
            {
              idn: 25,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 17,
              posNm: "Apple Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 33944.75,
              retOneMonth: 9.43,
              retThreeMonth: 17.79,
              retYTD: 49.72,
              retOneYear: 42.64,
              retTwoYear: 19.67,
              retThreeYear: 29.37,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 34.33,
              categoryNmfinal: "",
            },
            {
              idn: 26,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 640,
              posNm: "Arthur J Gallagher & Co",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 9441.51,
              retOneMonth: 9.9,
              retThreeMonth: 15.08,
              retYTD: 17.12,
              retOneYear: 36.1,
              retTwoYear: 26.65,
              retThreeYear: 32.78,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 33.4,
              categoryNmfinal: "",
            },
            {
              idn: 27,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1586,
              posNm: "AT & T Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4960.45,
              retOneMonth: 1.4,
              retThreeMonth: -15.97,
              retYTD: -10.86,
              retOneYear: -19.22,
              retTwoYear: -20.45,
              retThreeYear: -13.53,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: -13.77,
              categoryNmfinal: "",
            },
            {
              idn: 28,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 20,
              posNm: "Automatic Data Processing Inc ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4835.38,
              retOneMonth: 5.78,
              retThreeMonth: -0.7,
              retYTD: -6.38,
              retOneYear: 7.43,
              retTwoYear: 7.59,
              retThreeYear: 16.44,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 16.81,
              categoryNmfinal: "",
            },
            {
              idn: 29,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 700,
              posNm: "Bank of America Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 2209.13,
              retOneMonth: 4.06,
              retThreeMonth: 1.11,
              retYTD: -12.12,
              retOneYear: -5.32,
              retTwoYear: -14.52,
              retThreeYear: 9.12,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 8.55,
              categoryNmfinal: "",
            },
            {
              idn: 30,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 456,
              posNm: "Best Buy Company Inc ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3278,
              retOneMonth: 14.12,
              retThreeMonth: 5.96,
              retYTD: 4.65,
              retOneYear: 31.44,
              retTwoYear: -12.16,
              retThreeYear: 1.33,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 5.24,
              categoryNmfinal: "",
            },
            {
              idn: 31,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 39076,
              posNm: "Blackstone Group Inc Com (The)",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 6043.05,
              retOneMonth: 8.56,
              retThreeMonth: 6.81,
              retYTD: 27.65,
              retOneYear: 6.28,
              retTwoYear: 1.82,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 32,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 25,
              posNm: "Bristol-Myers Squibb Co",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 2941.7,
              retOneMonth: -0.76,
              retThreeMonth: -6.99,
              retYTD: -9.69,
              retOneYear: -15.06,
              retTwoYear: 0.85,
              retThreeYear: 6.02,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 5.32,
              categoryNmfinal: "",
            },
            {
              idn: 33,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 32218,
              posNm: "Broadcom Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 14746.31,
              retOneMonth: 7.94,
              retThreeMonth: 35.94,
              retYTD: 57.11,
              retOneYear: 83.88,
              retTwoYear: 38.84,
              retThreeYear: 44.37,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 47.23,
              categoryNmfinal: "",
            },
            {
              idn: 34,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1882,
              posNm: "Celanese Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4284.6,
              retOneMonth: 11.33,
              retThreeMonth: 7.05,
              retYTD: 14.68,
              retOneYear: 1.02,
              retTwoYear: -10.63,
              retThreeYear: 12.74,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 10.91,
              categoryNmfinal: "",
            },
            {
              idn: 35,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 979,
              posNm: "CenterPoint Energy Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3935.25,
              retOneMonth: 3.33,
              retThreeMonth: -0.4,
              retYTD: -1.52,
              retOneYear: 0.94,
              retTwoYear: 11.7,
              retThreeYear: 18.98,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 20.32,
              categoryNmfinal: "",
            },
            {
              idn: 36,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 947,
              posNm: "Chevron Corporation",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5035.2,
              retOneMonth: 4.47,
              retThreeMonth: -2.62,
              retYTD: -10.68,
              retOneYear: 12.58,
              retTwoYear: 27.58,
              retThreeYear: 26.41,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 24.5,
              categoryNmfinal: "",
            },
            {
              idn: 37,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 199,
              posNm: "Cisco Systems Inc ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5432.7,
              retOneMonth: 4.17,
              retThreeMonth: -0.28,
              retYTD: 10.3,
              retOneYear: 25.48,
              retTwoYear: 1.85,
              retThreeYear: 6.78,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 5.73,
              categoryNmfinal: "",
            },
            {
              idn: 38,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 2004,
              posNm: "CME Group Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 12970.3,
              retOneMonth: 4.27,
              retThreeMonth: -2.68,
              retYTD: 11.97,
              retOneYear: -4.55,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 39,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 32,
              posNm: "Coca Cola Co",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5660.68,
              retOneMonth: 1.7,
              retThreeMonth: -2.19,
              retYTD: -3.89,
              retOneYear: -1.43,
              retTwoYear: 8.69,
              retThreeYear: 13.88,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 12.2,
              categoryNmfinal: "",
            },
            {
              idn: 40,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1069,
              posNm: "Comcast Corporation Cl A",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5401.5,
              retOneMonth: 5.59,
              retThreeMonth: 10.44,
              retYTD: 20.64,
              retOneYear: 9.18,
              retTwoYear: -12.46,
              retThreeYear: 4.38,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 3.92,
              categoryNmfinal: "",
            },
            {
              idn: 41,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 35,
              posNm: "Corning Inc ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 7323.36,
              retOneMonth: 13.73,
              retThreeMonth: 0.21,
              retYTD: 11.6,
              retOneYear: 14.93,
              retTwoYear: -4.64,
              retThreeYear: 13.76,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 18.17,
              categoryNmfinal: "",
            },
            {
              idn: 42,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 13661,
              posNm: "Cubesmart REIT",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 2143.68,
              retOneMonth: 0,
              retThreeMonth: 0,
              retYTD: 0,
              retOneYear: 0,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 43,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 618,
              posNm: "Cummins Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5393.52,
              retOneMonth: 19.86,
              retThreeMonth: 3.32,
              retYTD: 2.51,
              retOneYear: 30.03,
              retTwoYear: 2.97,
              retThreeYear: 15.14,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 15.5,
              categoryNmfinal: "",
            },
            {
              idn: 44,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 534,
              posNm: "CVS Health Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3041.72,
              retOneMonth: 0.75,
              retThreeMonth: -7,
              retYTD: -25.33,
              retOneYear: -24,
              retTwoYear: -7.09,
              retThreeYear: 4.52,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 4.09,
              categoryNmfinal: "",
            },
            {
              idn: 45,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 609,
              posNm: "D R Horton Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 7909.85,
              retOneMonth: 13.9,
              retThreeMonth: 24.85,
              retYTD: 37.18,
              retOneYear: 85.84,
              retTwoYear: 17.26,
              retThreeYear: 31.28,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 30.44,
              categoryNmfinal: "",
            },
            {
              idn: 46,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1761,
              posNm: "Digital Realty Trust Inc REIT",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3643.84,
              retOneMonth: 12.42,
              retThreeMonth: 17.17,
              retYTD: 16.2,
              retOneYear: -8.16,
              retTwoYear: -9.49,
              retThreeYear: -3.6,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: -3.57,
              categoryNmfinal: "",
            },
            {
              idn: 47,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 13879,
              posNm: "Eaton Corp PLC",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5630.8,
              retOneMonth: 14.33,
              retThreeMonth: 17.96,
              retYTD: 29.4,
              retOneYear: 62.91,
              retTwoYear: 18.96,
              retThreeYear: 34.93,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 35.15,
              categoryNmfinal: "",
            },
            {
              idn: 48,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 419,
              posNm: "Entergy Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 2823.73,
              retOneMonth: -0.85,
              retThreeMonth: -8.7,
              retYTD: -11.66,
              retOneYear: -10.16,
              retTwoYear: 2.61,
              retThreeYear: 5.09,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 2.2,
              categoryNmfinal: "",
            },
            {
              idn: 49,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 33079,
              posNm: "Evergy Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 2921,
              retOneMonth: 0.99,
              retThreeMonth: -3.43,
              retYTD: -5.23,
              retOneYear: -6.89,
              retTwoYear: 1.99,
              retThreeYear: 3.26,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 1.85,
              categoryNmfinal: "",
            },
            {
              idn: 50,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 748,
              posNm: "Exxon Mobil Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 7078.5,
              retOneMonth: 4.96,
              retThreeMonth: -1.35,
              retYTD: -1.17,
              retOneYear: 29.52,
              retTwoYear: 35.96,
              retThreeYear: 41.09,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 39.03,
              categoryNmfinal: "",
            },
            {
              idn: 51,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 50,
              posNm: "General Mills Inc ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4141.8,
              retOneMonth: -8.89,
              retThreeMonth: -9.71,
              retYTD: -7.39,
              retOneYear: 4.36,
              retTwoYear: 15.48,
              retThreeYear: 10.86,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 9.75,
              categoryNmfinal: "",
            },
            {
              idn: 52,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 188,
              posNm: "Genuine Parts Co",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5923.05,
              retOneMonth: 14.35,
              retThreeMonth: 1.79,
              retYTD: -1.3,
              retOneYear: 30.42,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 53,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 64240,
              posNm: "GSK PLC Spons ADR",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4455,
              retOneMonth: 6.07,
              retThreeMonth: 1.2,
              retYTD: 3.43,
              retOneYear: 0,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 54,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 56,
              posNm: "Home Depot Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 8387.28,
              retOneMonth: 9.59,
              retThreeMonth: 6.04,
              retYTD: -0.22,
              retOneYear: 16.35,
              retTwoYear: 1.12,
              retThreeYear: 9.99,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 10.21,
              categoryNmfinal: "",
            },
            {
              idn: 55,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 750,
              posNm: "Honeywell International Inc ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 6640,
              retOneMonth: 8.3,
              retThreeMonth: 9.15,
              retYTD: -2.14,
              retOneYear: 21.85,
              retTwoYear: -0.81,
              retThreeYear: 15.01,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 14.25,
              categoryNmfinal: "",
            },
            {
              idn: 56,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 58,
              posNm: "Intl Business Machines Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4683.35,
              retOneMonth: 4.06,
              retThreeMonth: 3.47,
              retYTD: -2.54,
              retOneYear: -0.32,
              retTwoYear: 0.44,
              retThreeYear: 8.83,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 7.41,
              categoryNmfinal: "",
            },
            {
              idn: 57,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 60,
              posNm: "Johnson & Johnson Com",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 6289.76,
              retOneMonth: 6.68,
              retThreeMonth: 7.53,
              retYTD: -4.98,
              retOneYear: -4.32,
              retTwoYear: 2.85,
              retThreeYear: 8.35,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 6.16,
              categoryNmfinal: "",
            },
            {
              idn: 58,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 844,
              posNm: "JPMorgan Chase & Co",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5090.4,
              retOneMonth: 7.17,
              retThreeMonth: 12.49,
              retYTD: 10.11,
              retOneYear: 33.49,
              retTwoYear: -0.44,
              retThreeYear: 19.17,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 17.3,
              categoryNmfinal: "",
            },
            {
              idn: 59,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 370,
              posNm: "KLA Corporation",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 6305.26,
              retOneMonth: 9.49,
              retThreeMonth: 21.92,
              retYTD: 29.5,
              retOneYear: 54.06,
              retTwoYear: 23.87,
              retThreeYear: 37.39,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 40.71,
              categoryNmfinal: "",
            },
            {
              idn: 60,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 473,
              posNm: "Lockheed Martin Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4603.8,
              retOneMonth: 3.74,
              retThreeMonth: -1.91,
              retYTD: -4.08,
              retOneYear: 9.93,
              retTwoYear: 13.38,
              retThreeYear: 11.08,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 8.54,
              categoryNmfinal: "",
            },
            {
              idn: 61,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 11670,
              posNm: "Marathon Petroleum Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3847.8,
              retOneMonth: 11.14,
              retThreeMonth: -12.91,
              retYTD: 1.48,
              retOneYear: 45.28,
              retTwoYear: 42.96,
              retThreeYear: 51.46,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 52.8,
              categoryNmfinal: "",
            },
            {
              idn: 62,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 72,
              posNm: "McDonald's Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 6863.43,
              retOneMonth: 5.21,
              retThreeMonth: 7.28,
              retYTD: 14.48,
              retOneYear: 23.56,
              retTwoYear: 16.2,
              retThreeYear: 20.07,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 19.09,
              categoryNmfinal: "",
            },
            {
              idn: 63,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 19544,
              posNm: "Medtronic PLC",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 7136.1,
              retOneMonth: 7.29,
              retThreeMonth: 10.14,
              retYTD: 15.25,
              retOneYear: 1.51,
              retTwoYear: -13.27,
              retThreeYear: 1.54,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: -0.64,
              categoryNmfinal: "",
            },
            {
              idn: 64,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 9817,
              posNm: "Merck & Co Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5769.5,
              retOneMonth: 5.21,
              retThreeMonth: 9.19,
              retYTD: 5.42,
              retOneYear: 30.15,
              retTwoYear: 25.7,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 65,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 203,
              posNm: "Microsoft Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 32010.76,
              retOneMonth: 3.7,
              retThreeMonth: 18.38,
              retYTD: 42.67,
              retOneYear: 33.87,
              retTwoYear: 13.05,
              retThreeYear: 19.76,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 23.3,
              categoryNmfinal: "",
            },
            {
              idn: 66,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 82,
              posNm: "Omnicom Group ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 8658.65,
              retOneMonth: 8.69,
              retThreeMonth: 1.61,
              retYTD: 18.45,
              retOneYear: 54.9,
              retTwoYear: 13.27,
              retThreeYear: 25.1,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 24.68,
              categoryNmfinal: "",
            },
            {
              idn: 67,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 83,
              posNm: "Oracle Corporation",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 13099.9,
              retOneMonth: 12.41,
              retThreeMonth: 28.71,
              retYTD: 46.86,
              retOneYear: 73.46,
              retTwoYear: 25.81,
              retThreeYear: 31.34,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 31.55,
              categoryNmfinal: "",
            },
            {
              idn: 68,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 87,
              posNm: "Pfizer Inc ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3668,
              retOneMonth: -3.52,
              retThreeMonth: -9.12,
              retYTD: -26.87,
              retOneYear: -27.52,
              retTwoYear: 0.16,
              retThreeYear: 7.77,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 2.27,
              categoryNmfinal: "",
            },
            {
              idn: 69,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 7972,
              posNm: "Philip Morris Intl Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5759.58,
              retOneMonth: 9.89,
              retThreeMonth: 1.71,
              retYTD: -0.92,
              retOneYear: 4.34,
              retTwoYear: 4.66,
              retThreeYear: 17.89,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 16.23,
              categoryNmfinal: "",
            },
            {
              idn: 70,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 569,
              posNm: "Pioneer Natural Resources Co",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4765.14,
              retOneMonth: 3.88,
              retThreeMonth: 3.14,
              retYTD: -5.31,
              retOneYear: 2.76,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 71,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 90,
              posNm: "Procter & Gamble Co",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4096.98,
              retOneMonth: 6.45,
              retThreeMonth: 2.65,
              retYTD: 1.35,
              retOneYear: 8.3,
              retTwoYear: 8.65,
              retThreeYear: 10.93,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 11.74,
              categoryNmfinal: "",
            },
            {
              idn: 72,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 381,
              posNm: "Starbucks Corp ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3665.22,
              retOneMonth: 1.45,
              retThreeMonth: -4.39,
              retYTD: 0.86,
              retOneYear: 32.48,
              retTwoYear: -3.9,
              retThreeYear: 12.65,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 10.19,
              categoryNmfinal: "",
            },
            {
              idn: 73,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 762,
              posNm: "Target Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4088.9,
              retOneMonth: 0.74,
              retThreeMonth: -19.82,
              retYTD: -9.84,
              retOneYear: -3.69,
              retTwoYear: -24.44,
              retThreeYear: 5.35,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 4.53,
              categoryNmfinal: "",
            },
            {
              idn: 74,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 106,
              posNm: "Texas Instruments Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 7920.88,
              retOneMonth: 3.53,
              retThreeMonth: -2.5,
              retYTD: 10.56,
              retOneYear: 20.57,
              retTwoYear: -0.59,
              retThreeYear: 15.37,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 17.45,
              categoryNmfinal: "",
            },
            {
              idn: 75,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1925,
              posNm: "Travelers Companies Inc Com ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 7293.72,
              retOneMonth: 3.2,
              retThreeMonth: 1.89,
              retYTD: -6.35,
              retOneYear: 4.92,
              retTwoYear: 10.07,
              retThreeYear: 17.7,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 19.9,
              categoryNmfinal: "",
            },
            {
              idn: 76,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 42503,
              posNm: "Truist Financial Corp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 1578.2,
              retOneMonth: -0.4,
              retThreeMonth: -9.27,
              retYTD: -27.32,
              retOneYear: -32.5,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 77,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 111,
              posNm: "Union Pacific Corp ",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5524.74,
              retOneMonth: 6.29,
              retThreeMonth: 2.35,
              retYTD: 0.09,
              retOneYear: -1.66,
              retTwoYear: -1.34,
              retThreeYear: 8.87,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 8.46,
              categoryNmfinal: "",
            },
            {
              idn: 78,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 743,
              posNm: "United Parcel Service Class B",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5198.25,
              retOneMonth: 7.34,
              retThreeMonth: -6.71,
              retYTD: 5.02,
              retOneYear: 1.65,
              retTwoYear: -4.31,
              retThreeYear: 20.59,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 24.29,
              categoryNmfinal: "",
            },
            {
              idn: 79,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 798,
              posNm: "UnitedHealth Group Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4806.4,
              retOneMonth: -0.96,
              retThreeMonth: 2.11,
              retYTD: -8.65,
              retOneYear: -5.16,
              retTwoYear: 11.05,
              retThreeYear: 19.34,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 17.66,
              categoryNmfinal: "",
            },
            {
              idn: 80,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 881,
              posNm: "US Bancorp",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 5782,
              retOneMonth: 12.12,
              retThreeMonth: -7.01,
              retYTD: -22.32,
              retOneYear: -24.7,
              retTwoYear: -20.67,
              retThreeYear: 0.49,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 1.99,
              categoryNmfinal: "",
            },
            {
              idn: 81,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 811,
              posNm: "Verizon Communications",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 7958.66,
              retOneMonth: 4.38,
              retThreeMonth: -2.79,
              retYTD: -2.54,
              retOneYear: -22.06,
              retTwoYear: -13.98,
              retThreeYear: -7.74,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: -8.73,
              categoryNmfinal: "",
            },
            {
              idn: 82,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 7966,
              posNm: "Visa Inc Cl A",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4037.16,
              retOneMonth: 0,
              retThreeMonth: 0,
              retYTD: 0,
              retOneYear: 0,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 83,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 114,
              posNm: "Walmart Inc",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 3300.78,
              retOneMonth: 7.02,
              retThreeMonth: 7,
              retYTD: 11.73,
              retOneYear: 31.36,
              retTwoYear: 7.23,
              retThreeYear: 11.08,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 9.51,
              categoryNmfinal: "",
            },
            {
              idn: 84,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 65722,
              posNm: "Warner Music Group Corp Cl A",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 2869.9,
              retOneMonth: 6.71,
              retThreeMonth: -21.34,
              retYTD: -24.68,
              retOneYear: 0,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 85,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 139,
              categoryNm: "Equities",
              subCategoryNm: "Stocks",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 13653,
              posNm: "WP Carey Inc REIT",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 4796.76,
              retOneMonth: -1.05,
              retThreeMonth: -11.39,
              retYTD: -10.97,
              retOneYear: -13.58,
              retTwoYear: 0.56,
              retThreeYear: 5.78,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 10.42,
              categoryNmfinal: "",
            },
            {
              idn: 86,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 96,
              l2PerfTmpltCtgryId: 147,
              categoryNm: "Equities",
              subCategoryNm: "Alternative/Commodity/Specialty EQ",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 34511,
              posNm: "Aberdeen Physical Precious Metals Basket ETF",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 12840.48,
              retOneMonth: -3.43,
              retThreeMonth: -4.88,
              retYTD: -2.77,
              retOneYear: 1.68,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 87,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 3,
              categoryIdentifier: "Level 1 Segment",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 98,
              l2PerfTmpltCtgryId: 0,
              categoryNm: "Fixed Income",
              subCategoryNm: "",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 579077.42,
              retOneMonth: -0.02,
              retThreeMonth: 0.15,
              retYTD: 2.36,
              retOneYear: 2.65,
              retTwoYear: -1.88,
              retThreeYear: 1,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0.8,
              categoryNmfinal: "Fixed Income",
            },
            {
              idn: 88,
              cnsldtnId: 2998,
              acctId: 0,
              groupId: 4,
              categoryIdentifier: "Level 1 Segment – Managed",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 98,
              l2PerfTmpltCtgryId: -1,
              categoryNm: "Fixed Income",
              subCategoryNm: "Fixed Income – Managed",
              breakoutInd: "",
              posLabel: "",
              posId: 0,
              posNm: "",
              nodeNm: "",
              acctNo: "",
              market: 579077.42,
              retOneMonth: -0.02,
              retThreeMonth: 0.15,
              retYTD: 2.36,
              retOneYear: 2.65,
              retTwoYear: -1.88,
              retThreeYear: 1,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "    Fixed Income – Managed",
            },
            {
              idn: 89,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 98,
              l2PerfTmpltCtgryId: 135,
              categoryNm: "Fixed Income",
              subCategoryNm: "Taxable Bond Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 36443,
              posNm: "Artisan High Income Fd Instl",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 25399.58,
              retOneMonth: 2.41,
              retThreeMonth: 1.93,
              retYTD: 6.91,
              retOneYear: 8.33,
              retTwoYear: -0.93,
              retThreeYear: 5.43,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 5.95,
              categoryNmfinal: "",
            },
            {
              idn: 90,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 98,
              l2PerfTmpltCtgryId: 135,
              categoryNm: "Fixed Income",
              subCategoryNm: "Taxable Bond Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 38810,
              posNm: "Frost Credit Fund Instl",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 13703.21,
              retOneMonth: 0.64,
              retThreeMonth: 1.94,
              retYTD: 4.8,
              retOneYear: 5.67,
              retTwoYear: -0.87,
              retThreeYear: 3.36,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 4.53,
              categoryNmfinal: "",
            },
            {
              idn: 91,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 98,
              l2PerfTmpltCtgryId: 135,
              categoryNm: "Fixed Income",
              subCategoryNm: "Taxable Bond Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 38801,
              posNm: "Frost Low Duration Bond Fd Instl",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 127178.51,
              retOneMonth: -0.21,
              retThreeMonth: 0.17,
              retYTD: 1.67,
              retOneYear: 1.27,
              retTwoYear: -1.92,
              retThreeYear: -0.61,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: -0.23,
              categoryNmfinal: "",
            },
            {
              idn: 92,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 98,
              l2PerfTmpltCtgryId: 135,
              categoryNm: "Fixed Income",
              subCategoryNm: "Taxable Bond Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 38803,
              posNm: "Frost Total Return Bond Fund Instl",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 216168.25,
              retOneMonth: 0.19,
              retThreeMonth: 0.53,
              retYTD: 2.97,
              retOneYear: 3.05,
              retTwoYear: -0.73,
              retThreeYear: 1.98,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 2.63,
              categoryNmfinal: "",
            },
            {
              idn: 93,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 98,
              l2PerfTmpltCtgryId: 135,
              categoryNm: "Fixed Income",
              subCategoryNm: "Taxable Bond Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 15231,
              posNm: "Hartford World Bond Fd Instl",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 46175.61,
              retOneMonth: -0.94,
              retThreeMonth: -2.21,
              retYTD: 0.22,
              retOneYear: 0.92,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
            {
              idn: 94,
              cnsldtnId: 2998,
              acctId: 4453,
              groupId: 7,
              categoryIdentifier: "Position Level ROR - Asset (SMA Breakout)",
              perfTmpltId: 3,
              l1PerfTmpltCtgryId: 98,
              l2PerfTmpltCtgryId: 135,
              categoryNm: "Fixed Income",
              subCategoryNm: "Taxable Bond Funds",
              breakoutInd: "True",
              posLabel: "FD926",
              posId: 1180,
              posNm: "Vanguard Short-Term Inv Grade Instl",
              nodeNm: "Main Account",
              acctNo: "FD926",
              market: 150452.26,
              retOneMonth: -0.32,
              retThreeMonth: -0.11,
              retYTD: 1.78,
              retOneYear: 0,
              retTwoYear: 0,
              retThreeYear: 0,
              retFourYear: 0,
              retFiveYear: 0,
              retTenYear: 0,
              retInception: 0,
              categoryNmfinal: "",
            },
          ],
          ocSelectedRORSMAT2: [
            {
              blndBnchmrkNm: "",
              bnchMarkAssets: "",
            },
          ],
          ocSelectedRORSMAT3: [
            {
              cnsldtnId: 2998,
              cnsldtnNm: "Amy Judin Best Management Agency_SMA",
              cnsldtnAbrvtn: "FD926_Best_2998",
              mainAcctId: 4453,
              mainExtAcctId: "FD926",
              rptTmpltId: 2,
            },
          ],
          msg: "Account is SMA Consolidation enabled.",
        };

        //         let gridData = Enumerable.from(rowData.lstSelectedPeriodT1)
        //           .where((w) => w.market > 0)
        //           .toArray();

        //         let chartData = Enumerable.from(rowData.lstSelectedPeriodT2)
        //           .where((w) => w.market > 0)
        //           .toArray();

        populateSelectedPerRORSMARptData(rowData?.ocSelectedRORSMAT1 || []);
        setSelectedPerRORSMAData(rowData);
        //         populateTimeWtdRtnRptRptDataChart(rowData.lstSelectedPeriodT2);
        //         setReportData(rowData1.ocSelectedRORSMAT1);
        //         let cdt = new Date(rowData.perfIncptnDt);
        //         let siDate =
        //           (cdt.getMonth() + 1).toString().padStart(2, "0") +
        //           "/" +
        //           cdt.getDate().toString().padStart(2, "0") +
        //           "/" +
        //           cdt.getFullYear();
        //         setIncepDt(siDate);
        //         console.log("Inception DATE:", siDate);
        //         if (lsSelectedAcct !== null) {
        //           SetselAcct(lsSelectedAcct);
        //         }
        //         // else {
        //         //   SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        //         // }
        getColumnStateDb();
        setLoading(false);
        //         setFlag(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 33;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        debugger;
        //console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Header></Header>
            <Loading />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <Header></Header>
      <div className="my-2">
        <div className="rounded"></div>

        <div className="d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-4 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "330px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mx-4">
          <p className="reportheading fs-6">Selected Period ROR SMA Report</p>
        </div>
      </div>

      <div className="container-fluid bg-white">
        <form onSubmit={(e)=>{e.preventDefault();}}>
          <div className="row mt-2 d-flex justify-content-end align-items-center">
            <div className="col">
              {/* <span className="py-1">As Of Date </span> */}
              <label className="form-check-label px-2">As Of Date</label>
              <DatePicker
                id="dpFrm"
                value={asOfDate}
                className="form-control form-control-sm"
                size={"small"}
                width={150}
                format="MM/dd/yyyy"
                // calendar={CustomCalendar}
                // min={minFrmDt}
                max={maxFrmDt}
                disabled={isDisabled}
                onChange={(e) => {
                  setAsOfDate(e.value);
                }}
              />

              <input
                type="button"
                className="btn btn-primary btn-sm mx-3"
                onClick={handelSubmit}
                value="submit"
              />
            </div>
          </div>
        </form>
        <SelectedPeriodRORSMAGrid
          data={selectedPerRORSMARptData}
          reportData={selectedPerRORSMAData}
        />
      </div>
    </div>
  );
};

export default SelectedPeriodRORSMARpt;
