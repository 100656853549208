import React from 'react'
import { useState, useEffect } from 'react';
import axios from 'axios';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import SelectControl from './selectcontrol';
import Dropdown from 'react-bootstrap/Dropdown';
import { Card, Checkbox, FormControlLabel, Switch } from '@mui/material';
import { AiFillSetting } from 'react-icons/ai';
import { filterBy } from '@progress/kendo-data-query';
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import Modal from 'react-bootstrap/Modal';
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate } from 'react-router-dom';

import { FcExpired} from 'react-icons/fc';
import Loading from './loading';
import Header  from './header';
import DateRange  from './dateRange';
import AcctTransactionGrid from './acctTransactionGrid';
import { formatDate,parseDate } from '@telerik/kendo-intl';
import { Button } from 'react-bootstrap';
const moment= require('moment');
// import "@progress/kendo-theme-material/dist/all.css";
//import "@progress/kendo-theme-default/dist/all.css";
const AcctTransactionRpt = () => {
  var tempToken = JSON.parse(localStorage.getItem('token'));
    const [AcctTransactionRptData, populateAcctTransactionRptData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [effectivecDtInd, seteffectiveDtInd] = useState(true);
    const [excludeSuppress, setexcludeSuppress] = useState(false);
    const [excludeSweep, setexcludeSweep] = useState(false);
    const [selAcct, SetselAcct] = useState(JSON.parse(localStorage.getItem('AcctSelected')));
    const [selAcctData, setSelAcctData] = useState(JSON.parse(localStorage.getItem('acctData')).slice());
const [session,setSession]=useState("");
    var date = new Date(localStorage.getItem('processingDate'));
    const navigate = useNavigate();
    date.setMonth(date.getMonth()-12);
    const [frmDate, setfrmDate] = React.useState(date);
    const [isClicked, setIsClicked] = useState(localStorage.getItem('isSearchClicked'));
    const [searchData, setSearchData] = useState(JSON.parse(localStorage.getItem('searchedData')));
    const[firstFlag,setFirstFlag]=useState(0);
    const[isDisabled,setisDisabled]= React.useState(false);
    const [toDate, setToDate] = React.useState(new Date(localStorage.getItem('processingDate')));
    const[flag,setFlag]= React.useState(false);
    var minDate = new Date(localStorage.getItem('processingDate'));
    minDate.setMonth(minDate.getMonth()-60);
    var maxDate = new Date(localStorage.getItem('processingDate'));
    //maxDate.setMonth(maxDate.getMonth());
    const[minFrmDt, setminFrmDt]=React.useState(minDate);
    const[maxFrmDt, setmaxFrmDt]=React.useState(maxDate);
    const [reportData, setReportData] = useState([]);
    // const minFrmDt = new Date(2021,8,13);
    // const maxFrmDt = new Date(2022,8,14);
    const [enableCombo,setEnableCombo]=useState(false);

    useEffect(() => {
      
      const fetchData = async () => {
           setLoading(true);
           setEnableCombo(true);
          try {
              //let data = location.state;
  
              let userId = JSON.parse(localStorage.getItem('userId'));// data.Email;
             
              //setEmail(email);
              GetAcctTransactionData();
            
              //  console.log(data);
          } catch (error) {
              console.error(error.message);
          }
  
      }
      fetchData();
  }, [])

  
  function setDate (val){
    
    if (val == null)
    {
      var newToDate = new Date(frmDate);
      newToDate.setMonth(newToDate.getMonth()+12);
      setfrmDate(frmDate);
      if(newToDate>=new Date(localStorage.getItem('processingDate'))){
        setToDate(new Date(localStorage.getItem('processingDate')));
      }
      else{
        setToDate(newToDate);
      }

    }
    else
    {
      
      var newToDate = new Date(val);
      newToDate.setMonth(val.getMonth()+12);
      setfrmDate(val);
      if(newToDate>=new Date(localStorage.getItem('processingDate'))){
        setToDate(new Date(localStorage.getItem('processingDate')));
    }
    else{
      setToDate(newToDate);
    }
  }
    
}
function setTDate (val){
  if ( val == null)
  {
    
    
    var newStartDate = new Date(toDate);
    newStartDate.setMonth(newStartDate.getMonth()-12);
    setToDate(toDate);
    //setfrmDate(newStartDate);

  }
  else
  {
    
    
    var newStartDate = new Date(val);
    newStartDate.setMonth(val.getMonth()-12);
    setToDate(val);
    setfrmDate(newStartDate);
  }
}
  const refreshToken = async () => {
    
    let token = JSON.parse(localStorage.getItem('token'));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/token/Refresh',
      postData

    )
      .then((response) => {
        
        tempToken = response.data;
        console.log("Token value in refresh token:",response.data)
        localStorage.setItem('token', JSON.stringify(response.data));
        
        GetAcctTransactionData();


      })
      .catch((error) => {
        if(error.code==="ERR_BAD_REQUEST"){
          setSession("Session Expired");
        }
         
        console.log("my error is " + error);
      })

  }
  const formSubmit=(event)=>{
    event.preventDefault();
    GetAcctTransactionData();
  }
  const handleEffectiveDtInd = (e) => {

    seteffectiveDtInd(e.target.checked);

  }
  const handleExcludeSuppress = (e) => {

    setexcludeSuppress(e.target.checked);

  }
  const handleExcludeSweep = (e) => {

    setexcludeSweep(e.target.checked);

  }
  
  const GetAcctTransactionData = async () => {
    
    setLoading(true);
    setEnableCombo(true);
     //let token = JSON.parse(localStorage.getItem('token'));
     let token=tempToken;
     let userId = JSON.parse(localStorage.getItem('userId'));
     let acctId= JSON.parse(localStorage.getItem('AcctSelected')).acctId;
     //let startDate = "09/13/2021";
     //let startDate= (localStorage.getItem('processingDate'));
     let startDate=frmDate;
     let endDate=toDate;
     let EffectiveDtInd=effectivecDtInd;
     let ExcludeSupp=excludeSuppress;



//







let SwpInd=excludeSweep;



let RltnshpCd ="";
let mConsolidationId = 0;
let PageNo=1;


//







//s
// startDate.setDate(startDate.getDate());
// endDate.setDate(endDate.getDate());

startDate =moment(startDate).format('YYYY-MM-DD HH:mm:ss');
endDate = moment(endDate).format('YYYY-MM-DD HH:mm:ss');

const postData = { userId, startDate, endDate, acctId, mConsolidationId, EffectiveDtInd, ExcludeSupp,SwpInd,RltnshpCd,PageNo };

   // const postData = {userId, startDate,endDate,acctId,EffectiveDtInd,ExcludeSupp };
     const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      
  };
  console.log(postData);
  
    await axios.post('/AcctTransactDateRange',
        postData,
       config
    )
        .then(response => {
          
            //  console.log(response);
 
            const rowData = response.data;
            localStorage.setItem('EndingBal', rowData.endingBal);
            localStorage.setItem('acctType', rowData.accountType);
            localStorage.setItem('Administrator', rowData.administrator);
            populateAcctTransactionRptData(rowData.ocAcctTransaction)
            for(let i=0;i<rowData.ocAcctTransaction.length;i++)
            {
              rowData.ocAcctTransaction[i].accountName = rowData.ocAcctTransaction[i].accountName.split(":")[1];
              
            }
            setReportData(rowData);
            setFirstFlag(1);
            setFlag(true);
            setLoading(false);
            setEnableCombo(false);
            for(var i=0;i<rowData.ocAcctTransaction.length;i++){
              // // rowData.ocAcctTransaction[i].prcsDt=formatDate(new Date(rowData.ocAcctTransaction[i].prcsDt),'d');
              //  let cdt = new Date(rowData.ocAcctTransaction[i].prcsDt);
              //   let edt= new Date(rowData.ocAcctTransaction[i].effectiveDate);
              //   let endt= new Date(rowData.ocAcctTransaction[i].entryDate);
              //  // rowData.ocAcctTransaction[i].prcsDt=(cdt.getMonth()+1).toString().padStart(2, '0')/cdt.getDate().toString().padStart(2, '0')/cdt.getFullYear();
              // rowData.ocAcctTransaction[i].prcsDt=new Date(cdt.getFullYear(),cdt.getDate(),cdt.getMonth());
              // rowData.ocAcctTransaction[i].effectiveDate=new Date(edt.getFullYear(),edt.getDate(),edt.getMonth());
              // rowData.ocAcctTransaction[i].entryDate=new Date(endt.getFullYear(),endt.getDate(),endt.getMonth());
              rowData.ocAcctTransaction[i].prcsDt = parseDate(formatDate(rowData.ocAcctTransaction[i].prcsDt,'MM/dd/yyyy'));

              rowData.ocAcctTransaction[i].effectiveDate = parseDate(formatDate(rowData.ocAcctTransaction[i].effectiveDate,'MM/dd/yyyy'));
 
              rowData.ocAcctTransaction[i].entryDate = parseDate(formatDate(rowData.ocAcctTransaction[i].entryDate,'MM/dd/yyyy'));
 
              rowData.ocAcctTransaction[i].tradeDate = parseDate(formatDate(rowData.ocAcctTransaction[i].tradeDate,'MM/dd/yyyy'));
 
            }
            
            localStorage.setItem("filterData",JSON.stringify(rowData.ocAcctTransaction));
            
            localStorage.removeItem("TransactDataState")
            console.log(i);
        })
        .catch((error) => {
          if (error.response.status === 401) {
            refreshToken();
  
  
          }
            //return error;
        });
  
  }
  const filterChange = (event) => {
         
    setSelAcctData(filterData1(event.filter));
  };
  const filterData1 = (filter) => {
   
    // const dataAcct = selAcctData.slice();
     return filterBy(JSON.parse(localStorage.getItem('acctData')).slice(), filter);
   };
   const handleChange = (event) => {
    
      if (event.target.value === null) {
        SetselAcct(selAcct);
        //GetUpdatedAccountProfile(0);
        localStorage.setItem('IsAcctSelected', false);
        
        }
      else {
        
        SetselAcct(event.target.value);
        localStorage.setItem('IsAcctSelected', true);
        localStorage.setItem('AcctSelected', JSON.stringify(event.target.value));
        
        GetAcctTransactionData();
        console.log(selAcct);
        
        
        //GetUpdatedAccountProfile(event.target.value.acctId);
      }
        
    };
    const signOut = () => {
    
      navigate("/");
      let token = JSON.parse(localStorage.getItem('token'));
      const postData = {};
      const config = {
        headers: {
          'authorization': `Bearer ${token.token}`,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
  
      };
      axios.post('/token/revoke',
      postData,
      config
      )
        .then((response) => {
        // 
        // localStorage.setItem('token', '');
         //console.log(response);
        // navigate("/");
            
          // if (response.statusText === '') {
           
  
  
          // }
  
         
        })
        .catch((error) => {
          // 
          console.log("my error is " + error);
        })
  
        // let tokenNew={token:''};
        // localStorage.setItem('token',JSON.stringify(tokenNew));
        // localStorage.setItem("AcctSelected",null);
        localStorage.removeItem('token');
        localStorage.clear();
      //firebaseApp.auth.signOut();
  
    }
  if (loading) {
    return(<>
      {session==="Session Expired"?
      
        <Modal show={true}  fullscreen={false} size="sm" ><Modal.Body><div className='row d-flex justify-content-center align-items-center'><><FcExpired size={30}></FcExpired>Your session has expired !</><div className='row d-flex justify-content-center align-items-center'>Please login again.</div><p></p><a className='row d-flex justify-content-center align-items-center btn btn-primary btn-sm' style={{outlineColor:"black",borderBlockColor:"black", backgroundColor:"#0099ff", width:"50px"}} >OK</a></div></Modal.Body></Modal>
      :
      <div>
       
        
       <Header></Header>
        <div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className='subheader text-end col-md-1'> &nbsp; Account(s):</div>
          <div className='col-md-4 text-start'>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
              disabled={enableCombo}
            />
          </div>
          {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
          </div>

          <div className='row d-flex justify-content-between align-items-center mx-2 px-1 py-2'>
          <div className='reportheading fs-6 col-md-3 col-lg-3 col-sm-10'>Transactions Report</div>
        <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              value={frmDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);             
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //defaultValue={toDate}
              value={toDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div>
        <div className=' col'>
        <Dropdown autoClose='outside' id='ddrMenu'>
              <Dropdown.Toggle variant='outline-secondary' size='sm'>
                <AiFillSetting size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <FormControlLabel control={<Switch checked={effectivecDtInd} onClick={handleEffectiveDtInd} name='chkCtr'/>} label="Effective Date Indicator" />
                <hr/>
                <FormControlLabel control={<Switch checked={excludeSuppress} onClick={handleExcludeSuppress} name='chkCtr'/>} label="Exclude Suppress Transactions" />
                <hr/>
                <FormControlLabel control={<Switch checked={excludeSweep} onClick={handleExcludeSweep} name='chkCtr'/>} label="Exclude Sweep Transactions" />
              </Dropdown.Menu>
            </Dropdown>
            </div>
        <div className='col-md-2 col-lg-2 col-sm-10'>

        <input type="submit"  className='btn btn-primary btn-sm' value="Submit"/> 

        </div>

      </div>
    
      <Loading />
            
   </div>}</>
    )
  }
  
    return (
<div>
  <Header />
  <form onSubmit={formSubmit}>
  {/* <table className="table no-spacing">
          <tbody>
            <tr>
              <td className="reportheading fs-6 align-top" style={{verticalAlign: 'bottom', width:'250px'}}>Transactions Report</td>
              <td className="align-bottom" colspan="6">
                <ComboBox
                  style={{ width: "500px" }} 
                  data={selAcctData}
                  textField="extrnlAcctId"
                  dataItemKey="acctId"
                  filterable={true}
                  value={selAcct}
                  onChange={handleChange}
                  onFilterChange={filterChange}
                />
              </td>
            </tr>
            </tbody>
            </table> */}
<div className='row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded'>
          <div className='subheader text-end col-md-1'> &nbsp; Account(s):</div>
          <div className='col-md-4 text-start'>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
              disabled={enableCombo}
            />
          </div>
          {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
          </div>
            {/* <table className="table no-spacing">
      <tbody>
        <tr className='bg-light'>
        <td className='align-bottom'>
            <span>From</span>
            <DatePicker
              id="dpFrm"
              value={frmDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e) => setDate(e.value)}
              style={{ width: "300px" }}
            />
          </td>
          <td className='align-bottom'>
            <span>To</span>
            <DatePicker
              value={toDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e) => setTDate(e.value)}
              style={{ width: "300px" }}
            />
          </td>
          <td className='align-bottom'>
            <Dropdown autoClose='outside' id='ddrMenu'>
              <Dropdown.Toggle variant='outline-secondary' size='sm'>
                <AiFillSetting size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <FormControlLabel control={<Switch checked={effectivecDtInd} onClick={handleEffectiveDtInd} name='chkCtr'/>} label="Effective Date Indicator" />
                <hr/>
                <FormControlLabel control={<Switch checked={excludeSuppress} onClick={handleExcludeSuppress} name='chkCtr'/>} label="Exclude Suppress Transactions" />
              </Dropdown.Menu>
            </Dropdown>
          </td>
          <td className='align-bottom'>
            <input type="submit" className='btn btn-primary' value="Submit" />
          </td>
        </tr>
      </tbody>
    </table> */}
      <div className='row d-flex justify-content-between align-items-center mx-2 px-1 py-2'>
          <div className='reportheading fs-6 col-md-3 col-lg-3 col-sm-10'>Transactions Report</div>
        <div className='col-md-3 col-lg-3 col-sm-10'>
          <span className='py-1'>From</span>
        <DatePicker id="dpFrm"
              value={frmDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={isDisabled}
              onChange={(e)=>{
                setDate(e.value);
                
              }}
            
            />

        </div>

        <div className='col-md-3 col-lg-3 col-sm-10'>
        <span className='py-1'>To</span>
        <DatePicker
              //defaultValue={toDate}
              value={toDate}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              min={minFrmDt}
              max={maxFrmDt}
              disabled={false}
              onChange={(e)=>{
                setTDate(e.value);
                
              }}
            />

        </div>
        <div className=' col'>
        <Dropdown autoClose='outside' id='ddrMenu'>
              <Dropdown.Toggle variant='outline-secondary' size='sm'>
                <AiFillSetting size={20} />
              </Dropdown.Toggle>
              <Dropdown.Menu>
                <FormControlLabel control={<Switch checked={effectivecDtInd} onClick={handleEffectiveDtInd} name='chkCtr'/>} label="Effective Date Indicator" />
                <hr/>
                <FormControlLabel control={<Switch checked={excludeSuppress} onClick={handleExcludeSuppress} name='chkCtr'/>} label="Exclude Suppress Transactions" />
                <hr/>
                <FormControlLabel control={<Switch checked={excludeSweep} onClick={handleExcludeSweep} name='chkCtr'/>} label="Exclude Sweep Transactions" />
              </Dropdown.Menu>
            </Dropdown>
            </div>
        <div className='col-md-2 col-lg-2 col-sm-10'>

        <input type="submit"  className='btn btn-primary btn-sm' value="Submit"/> 

        </div>

      </div>
  </form>
  {flag ? <AcctTransactionGrid data={AcctTransactionRptData} reportData={reportData} /> : <></>}
</div>

    
    
    )
  }
  
  export default AcctTransactionRpt