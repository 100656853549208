import React from 'react';
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { process } from "@progress/kendo-data-query";
import { ExcelExport } from '@progress/kendo-react-excel-export';
import Moment from 'react-moment';
import { formatNumber, formatDate  } from '@telerik/kendo-intl';
import { ColumnMenu } from "./columnMenu";
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from "@progress/kendo-react-data-tools";
import { CustomColumnMenu } from './customColumnMenu';


const aggregates = [
    {
      field: "shares",
      aggregate: "sum",
    },
    {
        field: "market",
        aggregate: "sum",
    },
    {
        field: "income",
        aggregate: "sum",
    },
    {
        field: "marketPercent",
        aggregate: "sum",
    },
    {
        field: "yield",
        aggregate: "average",
    }
  
  ];
  const initialGroup = [
    {
      field: "callOrPutYr",
    }  
  ];

  const initialDataState = {
    skip: 0,
  take: 4000,
  };

const FixdIncmMaturityLadrCallDetls = ({data, chkState, shwCstmColMnu}) => {
  
  const _export1 = React.useRef(null);
  const _grid = React.useRef();
  const excelExport = () => {
    // 
    if (_export1.current !== null) {
      _export1.current.save(data);
    }
  };
   //
  const totalSum = (props) => {
    const field = props.field || "";
    const total = data.reduce((acc, current) => acc + current[field], 0);
    return (
      <td colSpan={props.colSpan} style={{textAlign:"right"}}>
       { formatNumber(total, "##,#.00")}
        
      </td>
    );
  };
  const totalPercent = (props) => {
    const field = props.field || "";
    const total = data.reduce((acc, current) => acc + current[field], 0)*100;
    return (
      <td colSpan={props.colSpan} style={{textAlign:"right"}}>
       { formatNumber(total, "##,#.00")}
        
      </td>
    );
  };
  const avgYield = (props) => {
    
    const field = props.field;
    const len=data.length;
    const average = data.reduce((acc, current) => acc + current[field],0)/len;
    return (
      <td colSpan={props.colSpan} style={{textAlign:"right"}}>
        Avg: { formatNumber(average, "##,#.00")}
      </td>
    );
  }; 

  const totalSumYield = (props) => {
    
    var totalIncome=0, totalMarket=0, totYield=0;
    for(var i=0; i<data.length; i++){
      totalIncome = totalIncome + data[i].income;
      totalMarket = totalMarket + data[i].market;
    }
    if(totalMarket==0){
      totYield = 0;
    }
    else{
      totYield = (totalIncome*100)/totalMarket;
    }
    
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(totYield, '##,#.00')}
      </td>
    );
  };
  const RightNameHeader = (props) => {
    return (
        <a className="k-link" style={{
            float: "right",
        }} onClick={props.onClick}>
            {/* <span className="k-icon k-i-cart" /> */}
            <span
                style={{
                    // color: "#53d2fa",
                }}
            >
                {props.title}
            </span>
            {props.children}
        </a>
    );
};
  const columns = [
    {
      title: 'Based On First Call',
      field: 'callOrPutYr',
      minWidth: 250,
      show: true,
      filter: 'text',
    },
    {
      title: 'Par Value',
      field: 'shares',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      footerCell:totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: 'Market Value',
      field: 'market',
      minWidth: 300,
      show: true,
      filter: 'text',
      locked: false,
      footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: 'Income',
      field: 'income',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
       footerCell: totalSum,
      headerCell: RightNameHeader,
    },
    {
      title: 'Yield%',
      field: 'yield',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
       footerCell: totalSumYield,
      headerCell: RightNameHeader,
    },
    {
      title: 'Percent',
      field: 'marketPercent',
      minWidth: 150,
      show: true,
      filter: 'numeric',
      locked: false,
       footerCell: totalSum,
      headerCell: RightNameHeader,
    }
  ];

  const [stateColumns, setStateColumns] = React.useState(columns);
  
  const [row, setRow] = useState(data);
  const [dataState, setDataState] = React.useState();
  const [resultState, setResultState] = React.useState(initialDataState);
  //setResultState(process({data}, initialDataState))
  
  const [page, setPage] = React.useState(initialDataState);
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  
  const onDataStateChange = React.useCallback((e) => { 
   setResultState(e.resultState);
   setDataState(e.dataState);
  }, []);

const NumberCell = (props) => {
    return (
        <td style={{ textAlign: 'right' }}>
            {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
    )
}

const ftrText = (props) => {
    
    return (
      <td  style={{textAlign:"left"}}>
        Total Fixed Income Portfolio
      </td>
    );
  };



  const cellRender = (tdElement, cellProps) => {        
    
    if (cellProps.rowType === "data")
    {

    if(cellProps.field==="shares")
      {
        return (
         
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
      );
      }
      if(cellProps.field==="market")
      {
        return (
          
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>          
      );
      }
      if (cellProps.field === "income") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if(cellProps.field==="marketPercent")
      {
        //
        return (
          
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>          
      );
      }
      if (cellProps.field === "yield") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    
    if(cellProps.field==="callOrPutYr")
    {
      return (
        <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          Callable In&nbsp;{ formatNumber(cellProps.dataItem[cellProps.field], "###")}
        </td>
    );
    }
  }

    if (cellProps.rowType === "groupFooter") {

      if (cellProps.field === "shares") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem.aggregates.shares.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "market") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem.aggregates.market.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "income") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }}  role={"gridcell"}>
            { formatNumber(cellProps.dataItem.aggregates.income.sum, "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "marketPercent") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
            { formatNumber(cellProps.dataItem.aggregates.marketPercent.sum, "##,#.00")}
          </td>
        );
      }
      
      if (cellProps.field === "yield") {

        return (
          <td aria-colindex={cellProps.columnIndex} style={{ textAlign: 'right' }} role={"gridcell"}>
           { formatNumber(cellProps.dataItem.aggregates.yield.average, "##,#.00")}
          </td>
        );
      }
      if(cellProps.field==="callOrPutYr")
    {
      return (
        <td aria-colindex={cellProps.columnIndex} role={"gridcell"}>
          Total Fixed Income Portfolio
        </td>
    );
    }
    }

    return tdElement;
  };
  
  const pageChange = (event) => {
    setPage(event.page);
  };

  return (
    
    <div>
       {chkState? 
        
        <div className="container-fluid">
        <div className="row text-center">
          {shwCstmColMnu?
        
       <Grid style={{ height: 'auto' }}
            data={data}
            // groupable={{
            //   footer: "visible",
            // }}
           
            sortable={false}
            // skip={page.skip}
            pageable={false}
            // pageSize={page.take}
            // total={data.length}
           
           // total={total}
           // filterable={true}
          // resizable={false}
          // reorderable={false}
           onDataStateChange={onDataStateChange}
           {...dataState}
          //  onExpandChange={onExpandChange}
          //  expandField="expanded"
            cellRender={cellRender}
          >
           {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={false}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      // filter={column.filter}
                      footerCell={column.footerCell}
                      // cell={NumberCell}
                      headerCell={column.headerCell}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenu
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}          
          </Grid>

:
<ExcelExport data={data} ref={_export1}> 
<Grid style={{ height: "450px" }}
            data={data}
            // groupable={{
            //   footer: "visible",
            // }}
           
            sortable={false}
            skip={page.skip}
            // pageable={{
            //   pageSizes: true,
            // }}
            //pageSize={page.take}
            total={data.length}
           pageable={true}
           // total={total}
           // filterable={true}
          // resizable={true}
          // reorderable={true}
           onDataStateChange={onDataStateChange}
           {...dataState}
          //  onExpandChange={onExpandChange}
          //  expandField="expanded"
            cellRender={cellRender}
          >
           {stateColumns.map(
                (column, idx) =>
                  column.show && (
                    <Column
                      width={setWidth(column.minWidth)}
                      locked={column.locked}
                      key={idx}
                      field={column.field}
                      title={column.title}
                      filter={column.filter}
                      footerCell={column.footerCell}
                      // cell={NumberCell}
                      headerCell={column.headerCell}
                      // columnMenu={(props) => (
                      //   <CustomColumnMenu
                      //     {...props}
                      //     columns={stateColumns}
                      //     onColumnsSubmit={onColumnsSubmit}
                      //   />
                      // )}
                    />
                  )
              )}          
          </Grid>
          </ExcelExport>
                      }
          </div>
          </div>
          :
          <div></div>
        }
    </div>
  )
}

export default FixdIncmMaturityLadrCallDetls