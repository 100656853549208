import React from 'react';
import axios from 'axios';
import { useState, useEffect, useRef } from 'react';
import { Grid, GridColumn as Column, GridToolbar } from "@progress/kendo-react-grid";
import { process } from '@progress/kendo-data-query';
import { ExcelExport } from '@progress/kendo-react-excel-export';
import { formatNumber, formatDate } from '@telerik/kendo-intl';
import { CustomColumnMenuNoGrp } from './customColumnMenuNoGrp';
import { CustomColumnMenuNoGrpChkBox } from './customColumnMenuNoGrpChkBox';
import { FaFileExcel } from 'react-icons/fa';
//import data from './data.json';
import { GridPDFExport, PDFExport } from "@progress/kendo-react-pdf";
import { ExcelExportColumn, ExcelExportColumnGroup } from '@progress/kendo-react-excel-export';
import {
  setGroupIds,
  getGroupIds,
  setExpandedState,
} from '@progress/kendo-react-data-tools';
import {
  Sparkline,
  Chart,
  ChartSeries,
  ChartSeriesItem,
  ChartAxisDefaults,
  ChartCategoryAxis,
  ChartSeriesDefaults,
  ChartCategoryAxisItem,
  ChartTitle,
  ChartLegend,
  LegendItemClickEvent,
  ChartValueAxis,
  ChartValueAxisItem,
  ChartTooltip,
} from "@progress/kendo-react-charts";
import Modal from 'react-bootstrap/Modal';
const aggregates = [

  {
    field: "totMarket",
    aggregate: "sum",
  }

];

const processWithGroups = (data, dataState) => {
  const groups = dataState.group;

  if (groups) {
    groups.map((group) => (group.aggregates = aggregates));
  }

  dataState.group = groups;
  const newDataState = process(data, dataState);
  setGroupIds({
    data: newDataState.data,
    group: dataState.group,
  });
  return newDataState;
};

const PageTemplate = (props) => {
  return (
    <div>
      <div
        style={{
          position: "absolute",
          top: "10px",
          textAlign: "center",
          backgroundColor: "#f4f4f2",
        }}
      >
        <div className='fw-bold page-header table-header'>Demo Bank</div>
      </div>

      <div
        style={{
          position: "absolute",
          bottom: "10px",
          right: "10px",
        }}
      >
        Page {props.pageNum} of {props.totalPages}
      </div>
    </div>
  );
};
const PortfolioOverview3rdGrid = ({ data, flag, showExport }) => {
  
  const menuWithExcelCheck = (props) => {
    
    return (
      <div>

        <CustomColumnMenuNoGrpChkBox
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>)
  };

  const menuWithoutExcelCheck = (props) => {
    
    return (
      <div>

        <CustomColumnMenuNoGrp
          {...props}
          columns={stateColumns}
          data={data}
          onColumnsSubmit={onColumnsSubmit}
        />
      </div>)
  };

  const gridRef = useRef();



  const _export = React.useRef(null);
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(data);
    }
  };
  const [locked, setLocked] = React.useState(false);
  let allPDFExport;
  const columnLocked = () => {
    setLocked(!locked);
  };
  const totalSum = (props) => {
    const field = props.field || '';
    const total = data
      .reduce((acc, current) => acc + current[field], 0)
      .toFixed(2);
    return (
      <td colSpan={props.colSpan} style={{ textAlign: "right" }}>
        {formatNumber(total, '##,#.00')}
      </td>
    );
  };
  //let loadedColumns = localStorage.getItem('gridColumns');
  //const columns = 

  let defaultColumns = [
    {
      title: 'Description',
      field: 'assetShrtNm',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: true,
    },
    {
      title: 'Market Value',
      field: 'market',
      minWidth: "auto",
      show: true,
      filter: 'text',
      locked: false,
      headerClassName: "rightHeader"
    },
    {
      title: 'Asset Class%',
      field: 'mktPct',
      minWidth: "auto",
      show: true,
      filter: 'numeric',
      locked: false,
      headerClassName: "rightHeader"
    },
    // {
    //   title: '% Model Allocation',
    //   field: 'mdlWegh',
    //   minWidth: 150,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName:"rightHeader"
    // },
    // {
    //   title: '% Variance Allocation',
    //   field: 'varitoMdl',
    //   minWidth: 180,
    //   show: true,
    //   filter: 'numeric',
    //   locked: false,
    //   headerClassName:"rightHeader"
    // },

  ];
  //lelocalStorage.setItem('gridColumns', JSON.stringify(defaultColumns));
  let loadedColumns = localStorage.getItem('gridColumns');
  const GridColumns = flag ? JSON.parse(loadedColumns) : defaultColumns;//? JSON.parse(loadedColumns) : defaultColumns;

  const [row, setRow] = useState(data);
  const createDataState = (dataState) => {
    return {
      result: process(data, dataState),
      dataState: dataState,
    };
  };
  let initialState = createDataState({
    take: Number.MAX_VALUE,
    skip: 0,
    //group: [{field: 'descption'}],
  });
  const initialGroup = [
    {
      field: "descption",
    }
  ];
  const processWithGroups = (data, dataState) => {
    // 
    const groups = dataState.group;
    const filesDocArray = [];

    if (groups) {
      groups.map((group) => (group.aggregates = aggregates));
    }

    dataState.group = groups;
    const newDataState = process(data, dataState);
    setGroupIds({
      data: newDataState.data,
      group: dataState.group,
    });
    return newDataState;
  };
  const [result, setResult] = React.useState(
    processWithGroups(data, initialState.dataState)
  );
  //const [result, setResult] = useState(initialState.result);
  const [dataState, setDataState] = useState(initialState.dataState);
  const [stateColumns, setStateColumns] = useState(GridColumns);

  // GridColumns[0].columnMenu = menuWithExcelCheck;
  // GridColumns[1].columnMenu = menuWithoutExcelCheck;
  // GridColumns[2].columnMenu = menuWithoutExcelCheck;

  const dataStateChange = (event) => {
    let updatedState = createDataState(event.dataState);
    setResult(updatedState.result);
    setDataState(updatedState.dataState);
  };

  //GRID REORDER/RESIZE INIT SETTING
  const onColumnReorder = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const onColumnResize = (props) => {
    setStateColumns(addHiddenColumns(props.columns));
  };

  const addHiddenColumns = (columns) => {
    let newColumnsState = defaultColumns.map((col) => {
      let _col = columns.filter((c) => c.field == col.field);
      if (_col.length > 0) {
        return {
          ...col,
          orderIndex: _col[0].orderIndex ? _col[0].orderIndex : -1,
          width: _col[0].width ? _col[0].width : '',
        };
      } else {
        return { ...col, show: false };
      }
    });
    // newColumnsState[0].columnMenu = menuWithExcelCheck;
    // newColumnsState[1].columnMenu = menuWithoutExcelCheck;
    // newColumnsState[2].columnMenu = menuWithoutExcelCheck;

    return newColumnsState;
  };
  const onColumnsSubmit = (columnsState) => {
    setStateColumns(columnsState);
  };
  const expandChange = (event) => {
    const isExpanded =
      event.dataItem.expanded === undefined
        ? event.dataItem.aggregates
        : event.dataItem.expanded;
    event.dataItem.expanded = !isExpanded;
    setResult({ ...result });
  };
  const showpreview = async () => {
    //setDataState({ ...dataState,  skip: 0,take: 1000 });
    //setResult(data);
    setShow(true);
  }

  const printPDF = async () => {


    if (allPDFExport !== null) {

      allPDFExport.save();

    }
  };
  const saveColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem('userId'));
    let GridId = 1;//let 1 for Portfoliholdings Grid
    let ColumnSettings = localStorage.getItem('gridColumns');
    const postData = { UserId, GridId, ColumnSettings };
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    await axios.post('/RTSaveGridColumn',
      postData,
      config
    )
      .then(response => {

        console.log(response);
        const rowData = response.data;
        //populatePortfolioHoldingRptData(rowData.ocPortFolioHoldingsMainOutPut);
        //populatePortfolioHoldingRptDatatab2(rowData.ocPortFolioHoldingsTradeTypeOutPut);
        //setflagPortfolio(true);
        //setLoading(false);

      })
      .catch((error) => {

        return error;
      });
  }
  const saveColumnsState = (columns) => {
    console.log('save called!!');
    //console.log(columns);
    let currentColumnsState = JSON.stringify(columns);
    localStorage.setItem('gridColumns', currentColumnsState);

    saveColumnStateDb();

  };

  useEffect(() => {
    saveColumnsState(stateColumns);
  }, [stateColumns]);

  const [mnrRadioStat, setMnrRadioStat] = useState('checked');
  const defaultTooltipRender = ({ point }) => `${point.value.toFixed(2)}`;
  const labelContent = (e) => `${e.value.toFixed(2)}`;

  const handleClick = () => {
    setLocked(!locked);
  };

  const _grid = React.useRef();

  const [gridChartCheck, setgridChartCheck] = useState('checked');

  const [collapsedState, setCollapsedState] = React.useState([]);

  const chartDefaultV4Colors = [
    "#0275d8",
    "#5bc0de",
    "#5cb85c",
    "#f0ad4e",
    "#e67d4a",
    "#d9534f",
  ];

  const handleSetDataChecked = () => {

    setgridChartCheck('checked');

  }
  const handleSetChartChecked = () => {

    setgridChartCheck('');

  }

  const RightNameHeader = (props) => {
    return (
      <a className="k-link" style={{
        float: "right",
      }} onClick={props.onClick}>
        {/* <span className="k-icon k-i-cart" /> */}
        <span
          style={{
            // color: "#53d2fa",
          }}
        >
          {props.title}
        </span>
        {props.children}
      </a>
    );
  };
  const getCells = (columns, cellProps) => {
    let cells = [];
    columns.forEach((column) => {
      if (column.aggregate) {
        cells.push(
          <td style={{ textAlign: "right" }}>
            {formatNumber(cellProps.dataItem.aggregates[column.field][column.aggregate], '##,#.00')}
          </td>
        );
      } else {
        cells.push(<td>&nbsp;</td>);
      }
    });
    return cells;
  };
  const cellRender = (tdElement, cellProps) => {
    if (
      cellProps.rowType === 'groupHeader' &&
      tdElement &&
      tdElement.props.role != 'presentation'
    ) {
      //IMPORTANT - You need to add collection with the columns and their field name
      //you can define the Grid columns outside of the Grid and reuse them here.
      // const columns = [
      //   { field: 'invstmntObj' },
      //   { field: 'accountType' },
      //   { field: 'account'},
      //   { field: 'tckerCusip'},
      //   { field: 'assetShrtNm'},
      //   { field: 'units'},
      //   { field: 'txcstAmt'},
      //   { field: 'price' },
      //   { field: 'totMarket', aggregate: 'sum' },
      //   { field: 'gainLoss' },
      //   { field: 'income' },
      //   { field: 'yield' },
      //   { field: 'p1CashBlncAmt' },
      //   { field: 'p2P3CashBlncAmt' },
      //   { field: 'unExecCashAmt' },
      //   { field: 'tradeCash'},
      //   { field: 'excldCashAmt'},
      //   { field: 'equityPercent'},
      // ];
      const columns = GridColumns;
      return (
        <>
          <td
            {...tdElement.props}
            colSpan={tdElement.props.colSpan - columns.length}
          ></td>
          {getCells(columns, cellProps)}
        </>
      );
    }
    if (cellProps.rowType === 'groupFooter') {

      if (cellProps.field === 'totMarket') {
        return (
          <td aria-colindex={cellProps.columnIndex} role={'gridcell'}>
            {cellProps.dataItem.aggregates.totMarket.sum}
          </td>
        );
      }

    }
    if (cellProps.rowType === "data") {

      if (cellProps.field === "market") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "mktPct") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "txcstAmt") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "price") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "gainLoss") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "income") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "yield") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p1CashBlncAmt") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "p2P3CashBlncAmt") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "unExecCashAmt") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "tradeCash") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "excldCashAmt") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
      if (cellProps.field === "equityPercent") {

        return (
          <td style={{ textAlign: 'right' }} aria-colindex={cellProps.columnIndex} role={"gridcell"}>
            {formatNumber(cellProps.dataItem[cellProps.field], "##,#.00")}
          </td>
        );
      }
    }
    return tdElement;
  };

  const NumberCell = (props) => {

    if (props.field === 'invstmntObj') {
      return (
        <td style={{ textAlign: 'left' }}>
          {props.dataItem[props.field]}
        </td>
      )
    }
    if (props.field === 'accountType') {
      return (
        <td style={{ textAlign: 'left' }}>
          {props.dataItem[props.field]}
        </td>
      )
    }
    if (props.field === 'account') {
      return (
        <td style={{ textAlign: 'left' }}>
          {props.dataItem[props.field]}
        </td>
      )
    }
    if (props.field === 'tckerCusip') {
      return (
        <td style={{ textAlign: 'left' }}>
          {props.dataItem[props.field]}
        </td>
      )
    }
    if (props.field === 'assetShrtNm') {
      return (
        <td style={{ textAlign: 'left' }}>
          {props.dataItem[props.field]}
        </td>
      )
    }
    if (props.field === 'units') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'txcstAmt') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'price') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'totMarket') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'gainLoss') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'income') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'yield') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'p1CashBlncAmt') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'p2P3CashBlncAmt') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'unExecCashAmt') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'tradeCash') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'excldCashAmt') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }
    if (props.field === 'equityPercent') {
      return (
        <td style={{ textAlign: 'right' }}>
          {formatNumber(props.dataItem[props.field], "##,#.00")}
        </td>
      )
    }

  }

  const labelContent1 = (e) => `${e.value.toFixed(2)}`;
  const FormatLongNumber = ({ value }) => {

    if (value == 0) {
      return 0;
    }
    else {

      // hundreds
      if (value <= 999) {
        return value;
      }
      // thousands
      else if (value >= 1000 && value <= 999999) {
        return (value / 1000).toFixed(2) + 'K';
      }
      // millions
      else if (value >= 1000000 && value <= 999999999) {
        return (value / 1000000).toFixed(2) + 'M';
      }
      // billions
      else if (value >= 1000000000 && value <= 999999999999) {
        return (value / 1000000000).toFixed(2) + 'B';
      }
      else
        return value.toFixed(2);
    }
  };
  const setWidth = (minWidth) => {
    let width = minWidth;
    return width;
  };
  const [show, setShow] = React.useState(false);
  const handleClose = () => setShow(false);
  return (

    <div>
      {

        <div className='card-header row d-flex align-items-center my-2'>

          <div className='col'>
            <p className='tableheader text-start'>Top Holdings</p>
          </div>
          <div className='col'></div>
          <div className='col text-end'>
            {showExport ? <button
              className='btn btn-outline-primary btn-sm'
              onClick={excelExport}><span className='px-1'><FaFileExcel /></span>Export to Excel</button> : <></>}
            &nbsp;
            {/* <button
            className="btn btn-outline-primary btn-sm"
            onClick={showpreview}
          >
          Preview
          </button> */}
          </div>

        </div>

      }

      <div className="card-body">
        <div className="mx-1 my-1 py-1">
          <ExcelExport data={data} ref={_export}>

            <ExcelExportColumnGroup
              title={"Account Number: " + JSON.parse(localStorage.getItem('AcctSelected')).extrnlAcctId + "  Processing Date: " + localStorage.getItem("processingDate")}
              headerCellOptions={{
                textAlign: "left",
              }}
            >
              <ExcelExportColumn field="assetShrtNm" title="Description" width={250} />
              <ExcelExportColumn field="market" title="Market Value" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
              <ExcelExportColumn field="mktPct" title="Asset Class%" width={170} headerCellOptions={{ textAlign: "right", }} cellOptions={{ format: "#,##0.00", }} />
            </ExcelExportColumnGroup>

          </ExcelExport>
          <Grid
            style={{ width: '100%', height: "auto" }}
            data={result}
            {...dataState}
            onDataStateChange={dataStateChange}
            expandField="expanded"
            onExpandChange={expandChange}
            cellRender={cellRender}
            sortable={true}
            scrollable={true}
           // resizable={true}

           // reorderable={true}
            //pageable={true}
            //pageSize={10}
            groupable={{
              footer: 'visible',
              enabled: false,
            }}
            //
            ref={gridRef.current}
         //   onColumnReorder={onColumnReorder}
          //  onColumnResize={onColumnResize}
          //
          >
            {stateColumns.map(
              (column, idx) =>
                column.show && (
                  <Column
                    width={setWidth(column.minWidth)}
                    key={idx}
                    field={column.field}
                    title={column.title}
                    headerClassName={column.headerClassName}
                    {...column}
                    //columnMenu={column.columnMenu}
                  // columnMenu={(props) => (
                  //   <CustomColumnMenuNoGrp
                  //     {...props}
                  //     columns={stateColumns}
                  //     onColumnsSubmit={onColumnsSubmit}
                  //   />
                  // )}
                  />
                )
            )}
          </Grid>
          <div className='row d-flex  w-100 justify-content-around px-2 align-items-center py-1'>"Due to rounding, percentage may not equal 100." </div>
        </div>
      </div>



      {/* <Modal show={show} onHide={handleClose} fullscreen={true} size="xl" >
        <Modal.Header><div className="row w-100 tableheader">
          <div className='col'>
            <Modal.Title> Pdf Preview</Modal.Title>
          </div>
          <div className='col text-end'>
            <button
              className="btn btn-outline-primary btn-sm"
              onClick={printPDF}
            >
              Print
            </button>
          </div>

        </div>
        </Modal.Header>
        <Modal.Body>
<PDFExport 
         margin={{ top: 30, left: 20, right: 20, bottom: 30 }}
         pageTemplate={PageTemplate}
         forcePageBreak=".page-break"
         paperSize="A4"
         scale={0.6}
         //allPages={true} 
         //margin="1cm"
         landscape={true}
        repeatHeaders={true}
      
        ref={(pdfExport) => (allPDFExport = pdfExport)}>
          <div className="card-body">
            <div className="mx-1 my-1 py-1">
            
                <Grid
                  style={{ width: '100%' }}
                  data={result}
                  {...dataState}
                  onDataStateChange={dataStateChange}
                  expandField="expanded"
                  onExpandChange={expandChange}
                  //cellRender={cellRender}
                  sortable={false}
                  resizable={false}
                  reorderable={false}
                  pageable={false}
                  pageSize={Number.MAX_VALUE}
                  // groupable={{
                  //   footer: 'visible',
                  // }}
                  //
                  ref={gridRef.current}
                  onColumnReorder={onColumnReorder}
                  onColumnResize={onColumnResize}
                //
                >
                  {stateColumns.map(
                    (column, idx) =>
                      column.show && (
                        <Column
                          width={"auto"}
                          key={idx}
                          field={column.field}
                          title={column.title}
                          locked={false}
                         // {...column}
                          // columnMenu={(props) => (
                          //   <CustomColumnMenu
                          //     {...props}
                          //     columns={stateColumns}
                          //     onColumnsSubmit={onColumnsSubmit}
                          //   />
                          // )}
                        />
                      )
                  )}
                </Grid>
              {/* </ExcelExport> 
            </div>
          </div>
          
          
          

          </PDFExport>

        </Modal.Body>
        <Modal.Footer>

          <button className="btn btn-primary  btn-sm" onClick={handleClose}>
            Close
          </button>

        </Modal.Footer>

      </Modal> */}

    </div>
  )
}

export default PortfolioOverview3rdGrid
