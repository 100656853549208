import React, { Component } from 'react';
import './App.css';
import Login from './components/login';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from './logo.svg';
import {ResponsiveContainer} from 'recharts';

import ReportViewer from './components/ReportViewer';



const App = () => {
  

  return (
    <>
    
       <Login />
     
     </>
  )
}

export default App

