import React from 'react'
import { useEffect, useRef} from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
//import MenuIcon from '@mui/material/Menu';

import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import LogoPage from './logoPage';
import Sidebar from './sidebar';

import { FaCalendarAlt, FaFileExcel } from 'react-icons/fa';
import {
  ProSidebar,
  
  SubMenu,
  SidebarHeader,
  SidebarFooter,
  SidebarContent
} from "react-pro-sidebar";
import { FaSignOutAlt, FaChalkboard, ImCross,FaListAlt, FaRegChartBar, FaDonate, FaChartLine, FaDice,FaBars, FaUserAlt, FaCogs } from 'react-icons/fa';
import {AiOutlineClose} from 'react-icons/ai';
import'./sidebar.css';
import zIndex from '@mui/material/styles/zIndex';
import { positions } from '@mui/system';
const Header = ({barstate}) => {
  const location = useLocation();
  const state = location.state;
  const navigate = useNavigate();
  const pages = [];
  const settings = ['Dashboard', 'AcctHoldingRpt', 'AcctTransactionRpt', 'PortfolioHolding', 'Logout'];

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [sidebarState, setSidebarState] = React.useState(false);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  let jwtToken = JSON.parse(localStorage.getItem('token'));
  const postRptData = { jwtToken };

  const openDashBoardPage = () => {
    navigate("/dashboard");
  }
  const openAcctHoldingRpt = () => {
    navigate("/acctHoldingRpt");
  }
  const openAcctTransactionRpt = () => {
    navigate("/acctTransactionRpt");
  }
  const openFixdIncmFndmntlsRpt = () => {

    navigate("/fixdIncmFndmntlsRpt");
  }
  const openPortfolioHoldingsRpt = () => {

    navigate("/portfoliHoldingsRpt");
  }
  const openAccountProfilePage = () => {
    navigate("/accountProfile");
  }
  const openFixdIncmMaturityLadderRpt = () => {

    navigate("/fixdIncmMtrtyLadrRpt");
  }
  const openAccountSectBenchRpt = () => {

    navigate("/AcctSectBenchRpt");
  }

  const openReportDesignerRpt = () => {
    navigate("/ReportDesignerRpt");
  }
  const openAcctPerfRpt = () => {

    navigate("/AcctPerfRpt");
  }
  const openFixedIncomePortfolioOverviewRpt = () => {

    navigate("/fixedIncomePortfolioOverviewRpt");
  }
 const openSectorReturnPerformanceRpt = () => {

    navigate("/sctrReturnPerfrmnceRpt");
  }

  const signOut = (e) => {
    e.preventDefault();
    navigate("/");
    let token = JSON.parse(localStorage.getItem('token'));
    const postData = {};
    const config = {
      headers: {
        'authorization': `Bearer ${token.token}`,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }

    };
    axios.post('/token/revoke',
    postData,
    config
    )
      .then((response) => {
      // 
      // localStorage.setItem('token', '');
       //console.log(response);
      // navigate("/");
          
        // if (response.statusText === '') {
         


        // }

localStorage.clear();
      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      })

      let tokenNew={token:''};
      localStorage.setItem('token',JSON.stringify(tokenNew));
    //firebaseApp.auth.signOut();

  }
  const sidebartoggle=()=>{
    
    if(sidebarState){
      setSidebarState(false);
    }
    
    else{
    setSidebarState(true);
    }
  }
  
  
  const ref1= useRef(null);
  useEffect(()=>{
    
    document.addEventListener("click", handleClickOutside, true);
    return()=>{
      document.removeEventListener("click",handleClickOutside, true);
    };
  },[]);

  const handleClickOutside=(e)=>{
    
    if(ref1.current && !ref1.current.contains(e.target)){
      setSidebarState(false);

    }
    else{
     // console.log("insideside click");
      
    }
    
  }
  /*const ref = useDetectClickOutside({ onTriggered: onclickoutside });*/
  

  return (
    <>
    <div className='fixed-top shadow-sm'>
    
      <div >
      {/* <Sidebar style={{height: "100%"}}></Sidebar> */}
      {/* <Aside /> */}
      <nav className=" navbar navbar-expand-md navbar-light bg-white shadow-sm" aria-label="Fourth navbar example">
      
        <div  className="container-fluid mx-2 px-1 ">
        <a style={{cursor:"pointer"}} onClick={sidebartoggle}>{sidebarState?<AiOutlineClose size={25}/>:<FaBars size={25}/>}</a>
        <a className='px-2'  ><LogoPage /></a>
        

          

          <div className="collapse navbar-collapse d-flex align-items-center justify-content-end" id="navbarsExample04">
            
          <div className='mx-2'>
        <p className='subheader'>Processing Date: {localStorage.getItem('processingDate')}</p>
       
        
        </div>
        <div className='mx-2'>
        <p className='subheader'>Business Date: {localStorage.getItem('processingDateOperation')}</p>
       
        
        </div>
        <div className='mx-2'>
        <p className='subheader'><a style={{cursor:"pointer"}} href="#" onClick={signOut}><FaSignOutAlt className='mx-1'></FaSignOutAlt>LogOut</a></p>
       
        
        </div>



          </div>
          
       
        </div>
        
        
      </nav>
      </div>
      <div>


      </div>
    <div ref={ref1}  style={{zIndex:3, position:'absolute'}}>
      { sidebarState ? <Sidebar /> : <></> } 
    </div>
    </div>
    <br></br><br></br><br></br><br></br>
    </>
  )
}

export default Header