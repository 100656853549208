import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
// import validator from 'validator';
import Select, { SelectChangeEvent } from "@mui/material/Select";
//import SelectControl from './selectcontrol';
import Loading from "./loading";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { lastDayOfMonth, isEqual } from "@progress/kendo-date-math";
import DailyPerformanceGrid from "./dailyPerformanceGrid";
import Header from "./header";
import { Button, Card } from "@mui/material";
import { formatNumber, formatDate } from "@telerik/kendo-intl";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import refreshFunction from './refreshFunc';
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
// import { abs } from 'mathjs'
import { CustomCalendar } from "./customCalendar";
import { Dropdown } from "react-bootstrap";
import Enumerable from "linq";

import { FaInfoCircle } from "react-icons/fa";
// import { Popover } from "@progress/kendo-react-tooltip";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";

import BenchmarkSelPeriodPerGrid from "./benchmarkSelPeriodPerGrid";

const BenchmarkSelPeriodPerRpt = () => {
  const [benchmarkSelPerRptData, populateBenchmarkSelPerRptData] = useState([]);
  const [TimeWtdRtnRptRptDataChart, populateTimeWtdRtnRptRptDataChart] =
    useState([]);
  const [reportData, setReportData] = useState([]);
  const [IncepDt, setIncepDt] = useState();
  //const [AcctList, populateAccountListData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(true);
  const [flag, setFlag] = useState(0);
  const [isColumnSave, setIsColumnSave] = useState(false);
  var date = new Date(localStorage.getItem("processingDate"));
  debugger;
  date.setMonth(date.getMonth() - 1);
  const [frmDate, setfrmDate] = React.useState(date);
  //const [toDate, setToDate] = React.useState(new Date(localStorage.getItem("processingDate")));
  const [asOfDate, setAsOfDate] = React.useState(
    new Date(localStorage.getItem("processingDate"))
  );
  const anchor = React.useRef(null);
  const [show, setShow] = React.useState(true);
  const [isChckedBM, setisChckedBM] = useState(false);
  const [openDuration, setOpenDuration] = React.useState(100);
  const [closeDuration, setCloseDuration] = React.useState(300);
  // const minFrmDt = new Date(2021, 8, 14);

  const maxFrmDt = new Date(localStorage.getItem("processingDate"));
  const minFrmDt = new Date(localStorage.getItem("processingDate"));
  minFrmDt.setMonth(minFrmDt.getMonth() - 60);
  const [isDisabled, setisDisabled] = React.useState(false);

  let acctId = 0;
  let AccountId = JSON.parse(localStorage.getItem("AcctSelected"));
  if (AccountId != null) {
    acctId = AccountId.acctId;
  }

  const [monthlyOrDaily, setMonthlyOrDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? "Monthly"
      : "Daily"
  );
  const [isDisabledDaily, setisDisabledDaily] = React.useState(
    acctId.acctId == 0 && JSON.parse(localStorage.getItem("pConsId")) > 0
      ? true
      : false
  );
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  //const [CardData, populateTimeWtdRtnRptRptCardData] = useState([]);
  const navigate = useNavigate();

  const [dateType, setDateType] = React.useState(1);

  useEffect(() => {
    // debugger;
    const fetchData = async () => {
      setLoading(true);
      try {
        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;
        GetTimeWtdRtnRpteData();
        //getColumnStateDb();
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  const handelChkBM = (e) => {
    setisChckedBM(e.target.checked);
  };

  const handleChangemonthlyOrDaily = (e) => {
    setMonthlyOrDaily(e.target.value);
  };
  const handelSubmit = (event) => {
    // debugger;
    //  var date = new Date(asOfDate);
    //  var month =date.getMonth();
    //  var year =date.getFullYear();
    //  var day=date.getDate();
    //var lDay= lastDayOfMonth(new Date(year,month,day));

    //let date = dtfrm.selAcctData;
    setfrmDate(asOfDate);
    //  if(validator.isDate(asOfDate)&&(asOfDate>=minFrmDt)&&asOfDate<=maxFrmDt){
    //
    GetTimeWtdRtnRpteData();
    //  }
  };

  const formSubmit = (event) => {
    GetTimeWtdRtnRpteData();
  };

  const GetTimeWtdRtnRpteData = async () => {
    setLoading(true);

    // let token = JSON.parse(localStorage.getItem('token'));
    let token = tempToken;
    let asOfId = JSON.parse(localStorage.getItem("userId")); //3689
    let lsSelectedAcct = JSON.parse(localStorage.getItem("AcctSelected"));
    let AcctId = lsSelectedAcct.acctId;
    let PageId = 1;
    let netFeeId = 1;

    var date = new Date(asOfDate);
    var month = date.getMonth();
    var year = date.getFullYear();
    var day = date.getDate();
    debugger;
    var lDay = lastDayOfMonth(new Date(year, month, day));
    var IsDaily = 0;

    // asOfId = 6;
    var pageId = 1;
    var benchmarkList =
      "1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,50,51,52,53,54,55,56,57,58,59,60,61,62,63,64,65,66,67,68,69,70,71,72,73,74,75,76,77,78,79,80,81,82,83,84,85,86,87,88,89,90,91,92,93,97,105,118,119,120,122,123,124,125,126,127,128,129,130,131,132,133,134,135,136,137,138,139,140,141,142,143,144,145,146,147,148,149,150,151,152,153,154,155,156,157,158,159,160,161,162,163,164,165,166,167,168,169,170,171,172,173,174,175,176,177,178,179,180,181,182,183,184,185,186,187,188,189,190,191,192,193,194,195,196,197,198,199,200,201,202,203,204,205";
    // ("1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31,32,33,34,35,36,37,38,39,40,41,42,43,44,45,46,47,48,49,");
    var consolidationId = 0;
    // if (monthlyOrDaily == "Daily") IsDaily = 0;
    // debugger;
    // //var lDay=lastDayOfMonth(asOfDate);
    if (!isEqual(asOfDate, lDay) && IsDaily == 0) {
      lDay = lastDayOfMonth(new Date(year, month - 1, day));

      setAsOfDate(lDay);
    } else {
      lDay = asOfDate;
      setAsOfDate(lDay);
    }

    // if (lsSelectedAcct !== null) {
    //   AcctId = lsSelectedAcct.acctId;
    // }
    let AsofDate = lDay;

    let ConsolidationId =
      JSON.parse(localStorage.getItem("pConsId")) == null
        ? 0
        : localStorage.getItem("pConsId");
    AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let ShowBenchmark = 0;
    let RltnshpCd =
      localStorage.getItem("pRltncd") == null ||
      localStorage.getItem("pRltncd") == undefined
        ? ""
        : localStorage.getItem("pRltncd");

    // let AsOfDt = formatDate(lDay, "MM/dd/yyyy"); //"04/30/2024";
    let AsOfDt = formatDate(lDay, "MM/dd/yyyy");
    // setAsOfDate(lDay);
    // setAsOfDate(AsOfDt);
    // AcctId = 31868;
    if (isChckedBM) ShowBenchmark = 1;
    const postData = {
      asOfId,
      AcctId,
      pageId,
      benchmarkList,
      consolidationId,
      AsOfDt,
    };
    debugger;
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    // const rowData1 = {
    //   t1: [
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 14,
    //       astShrtNm: "Balanced - Uniform ...",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.66,
    //       tenYear: 4.53,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 2,
    //       astShrtNm: "Barclays Intermediate Bond Index",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.17,
    //       tenYear: 2.06,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 3,
    //       astShrtNm: "Barclays Intermediate Government Bond Index",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.16,
    //       tenYear: 1.81,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 11,
    //       astShrtNm: "Government Only",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.55,
    //       tenYear: 4.43,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 6,
    //       astShrtNm: "Growth With Income",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.47,
    //       tenYear: 3.54,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 5,
    //       astShrtNm: "Growth With Moderate Income",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.76,
    //       tenYear: 5.24,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 8,
    //       astShrtNm: "Income",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.06,
    //       tenYear: 1.95,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 7,
    //       astShrtNm: "Income With Moderate Growth",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.83,
    //       tenYear: 5.92,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 4,
    //       astShrtNm: "Maximum Growth",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 0,
    //       tenYear: 0,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 1,
    //       astShrtNm: "S&P 500 Index",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.96,
    //       tenYear: 6.88,
    //       rptTmpltId: 1,
    //     },
    //     {
    //       acctId: 1,
    //       extrnlAcctId: "1000",
    //       accountShrtNm: "The Estate of Mary Mathews",
    //       astId: 9,
    //       astShrtNm: "Stable",
    //       monthToDate: 0,
    //       quarterToDate: 0,
    //       halfYearToDate: 0,
    //       yearToDate: 0,
    //       oneYear: 0,
    //       threeYear: 0,
    //       fiveYear: 2.66,
    //       tenYear: 4.53,
    //       rptTmpltId: 1,
    //     },
    //   ],
    // };

    await axios
      .post("/RTGetBenchmarkSelPeriodPerf", postData, config)
      .then((response) => {
        debugger;
        const rowData = response.data?.t1 || [];

        //         let gridData = Enumerable.from(rowData.lstSelectedPeriodT1)
        //           .where((w) => w.market > 0)
        //           .toArray();

        //         let chartData = Enumerable.from(rowData.lstSelectedPeriodT2)
        //           .where((w) => w.market > 0)
        //           .toArray();

        populateBenchmarkSelPerRptData(rowData);
        //         populateTimeWtdRtnRptRptDataChart(rowData.lstSelectedPeriodT2);
        setReportData(rowData);
        //         let cdt = new Date(rowData.perfIncptnDt);
        //         let siDate =
        //           (cdt.getMonth() + 1).toString().padStart(2, "0") +
        //           "/" +
        //           cdt.getDate().toString().padStart(2, "0") +
        //           "/" +
        //           cdt.getFullYear();
        //         setIncepDt(siDate);
        //         console.log("Inception DATE:", siDate);
        //         if (lsSelectedAcct !== null) {
        //           SetselAcct(lsSelectedAcct);
        //         }
        //         // else {
        //         //   SetselAcct(rowData.lstAcctTmWtdRtnList[0]);
        //         // }
        getColumnStateDb();
        setLoading(false);
        //         setFlag(1);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        // return error;
      });
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        debugger;
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetTimeWtdRtnRpteData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 33;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        debugger;
        //console.log(response);
        const rowData = response.data;
        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);
        return error;
      });
  };

  const handleChange = (event) => {
    //
    if (event.target.value === null) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetTimeWtdRtnRpteData(event.target.value);
      //GetUpdatedAccountProfile(event.target.value.acctId);
    }
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Header></Header>
            <Loading />
          </div>
        )}
      </>
    );
  }

  return (
    <div>
      <Header></Header>
      <div className="my-2">
        <div className="rounded"></div>

        <div className="d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-4 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "330px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="mx-4">
          <p className="reportheading fs-6">
            Benchmark Selected Period Performance Report
          </p>
        </div>
      </div>

      <div className="container-fluid bg-white">
        <form onSubmit={(e)=>{e.preventDefault();}}>
          <div className="row mt-2 d-flex justify-content-end align-items-center">
            <div className="col">
              {/* <span className="py-1">As Of Date </span> */}
              <label className="form-check-label px-2">Date</label>
              <DatePicker
                id="dpFrm"
                value={asOfDate}
                className="form-control form-control-sm"
                size={"small"}
                width={150}
                format="MM/dd/yyyy"
                // calendar={CustomCalendar}
                // min={minFrmDt}
                max={maxFrmDt}
                disabled={isDisabled}
                onChange={(e) => {
                  debugger;
                  setAsOfDate(e.value);
                }}
              />

              <input
                type="button"
                className="btn btn-primary btn-sm mx-3"
                onClick={handelSubmit}
                value="submit"
              />
            </div>
          </div>

          {/* <BenchmarkSelPeriodPerGrid data={benchmarkSelPerRptData} /> */}
        </form>
        <BenchmarkSelPeriodPerGrid data={benchmarkSelPerRptData} />
      </div>
    </div>
  );
};

export default BenchmarkSelPeriodPerRpt;
