import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Loading from "./loading";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import Header from "./header";
import { filterBy } from "@progress/kendo-data-query";

import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import HistoricalMonthlyPerformanceValueDetails from "./historicalMonthlyPerformanceValueDetails";

const HistoricalMonthlyPerformanceRpt = () => {
  const [loading, setLoading] = useState(true);
  const [assetAllocModelRptData, setAssetAllocModelRptData] = useState([]);
  const [selectedAcct, setSelectedAcct] = useState(0);
  const [historicalMonthPerfData, setHistoricalMonthPerfData] = useState([]);
  const [modelId, setModelId] = useState(-1);
  const [session, setSession] = useState("");
  const [enableCombo,setEnableCombo]=useState(false);
  
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const navigate = useNavigate();

  var tempToken = JSON.parse(localStorage.getItem("token"));

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        getHistoricalMonthlyPerformanceInfo();
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  const getHistoricalMonthlyPerformanceInfo = async () => {
    setLoading(true);
    setEnableCombo(true);
    console.log("test");
    let token = tempToken;
    console.log(token);
    // let token = JSON.parse(localStorage.getItem('token'));

    // let AsOfId = JSON.parse(localStorage.getItem('userId'));

    //
    let AsOfId = JSON.parse(localStorage.getItem('userId'));

    let AcctId = JSON.parse(localStorage.getItem('AcctSelected')).acctId;

    let AcctIdAndSmtname = JSON.parse(localStorage.getItem("AcctSelected"));

    console.log("AcctIdAndSmtname", AcctIdAndSmtname);
    // let AsofDt =formatDate(asOfDt, "MM/dd/yyyy");

    // if (!Moment(AsofDt, 'MM-dd-yyyy').isValid()  || !Moment(priceDt, 'MM-dd-yyyy').isValid())

    // {

    //   

    // }

    let RltnshpCd = "";

    let mConsolidationId = 0;

    let PageId = 1;

    // let BenchmarkList = "14,2,3,11,6,5,8,7,4,1,9";

    let ConsolidationId = 0;

    //let PriceDt =formatDate(priceDt, "MM/dd/yyyy");

    //let pageId = 1;

    // const postData = {UserId, AcctId, AsofDt, EffectiveDtInd, PriceDt};

    const postData = { AcctId, AsOfId, PageId, ConsolidationId };

    console.log("POSTDATA:", postData);

    // 

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };

    await axios
      .post("/RTGetHistoricalMonthlyPerf", postData, config)

      .then((response) => {
        // console.log("DATA BENCHMARK PERFORMANCE:", response);

        // 

        const rowData = response.data;
        setAssetAllocModelRptData(rowData);

        console.log("Row Data", rowData);

        //getColumnStateDb();

        setLoading(false);
        setEnableCombo(false);

        // if(priceDt===""){

        //   setpriceDt("mm/dd/yyyy");

        // }
      })

      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }

        return error;
      });
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        getHistoricalMonthlyPerformanceInfo();
      })
      .catch((error) => {
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const handleChange = (event) => {
    if (event.target.value === null) {
      console.log("Hii I am here");
      SetselAcct(selAcct);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      console.log("Hii I am here section 2");
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      getHistoricalMonthlyPerformanceInfo();
    }
  };

  const filterData = (filter) => {
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        // 
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        // 
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Header />
            <div className="my-1">
              <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
                <div className="col-md-4 col-lg-5 col-sm-11 text-start">
                  <span className="px-2">Account(s)</span>
                  {console.log("selctacct1", selAcct)}
                  <ComboBox
                    style={{
                      width: "350px",
                    }}
                    data={selAcctData}
                    textField="extrnlAcctId"
                    dataItemKey="acctId"
                    filterable={true}
                    value={selAcct}
                    onChange={handleChange}
                    onFilterChange={filterChange}
                    disabled={enableCombo}
                  />
                </div>
              </div>
            </div>
            <Loading />
          </div>
        )}
      </>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Header />
      <div className="my-1">
        <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded border-0">
          <div className="col-md-4 col-lg-5 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            {console.log("selctacct", selAcct)}

            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              onFilterChange={filterChange}
              disabled={enableCombo}
            />
          </div>
        </div>
      </div>

      {/* <AnnualPortfolioValueDetails
        data={assetAllocModelRptData}
        mergedData={combinedData}
      /> */}

      <HistoricalMonthlyPerformanceValueDetails data={assetAllocModelRptData} />
    </div>
  );
};

export default HistoricalMonthlyPerformanceRpt;
