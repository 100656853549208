import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { CustomCalendar } from "./customCalendar";
import SelectControl from "./selectcontrol";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
import Moment from "react-moment";
import Loading from "./loading";
import Header from "./header";
import AcctHoldingGrid from "./acctHoldingGrid";

import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
import { formatDate } from "@telerik/kendo-intl";

// import "@progress/kendo-theme-material/dist/all.css";
//import "@progress/kendo-theme-default/dist/all.css";
const AcctHoldingRpt = () => {
  var date = new Date(localStorage.getItem("processingDate"));
  var maxFrmDt = date;
  const [asOfDt, setasOfDt] = React.useState(date);
  const [priceDt, setpriceDt] = React.useState(date);
  const [AcctHoldingRptData, populateAcctHoldingRptData] = useState([]);
  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData"))
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );
  const [loading, setLoading] = useState(true);
  const [isColumnSave, setIsColumnSave] = useState(false);

  const [effectivecDtInd, seteffectiveDtInd] = useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  const [reportData, setReportData] = useState([]);
  const [enableCombo, setEnableCombo] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let userId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        GetAcctHoldinData();
        getColumnStateDb();
        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);
  function setDate(val) {
    debugger;
    if (val == null) {
      setasOfDt(asOfDt);
    } else {
      let newdate = new Date(val);
      if (newdate <= new Date(localStorage.getItem("processingDate"))) {
        setasOfDt(val);
        setpriceDt(val);
      } else {
        setasOfDt(new Date(localStorage.getItem("processingDate")));
        setpriceDt(new Date(localStorage.getItem("processingDate")));
      }
    }
  }

  function setPrcDt(val) {
    let newdate = new Date(val);
    if (newdate <= new Date(localStorage.getItem("processingDate")))
      setpriceDt(val);
    else {
      setpriceDt(new Date(localStorage.getItem("processingDate")));
    }
    setpriceDt(val);
  }
  const handleEffectiveDtInd = (e) => {
    seteffectiveDtInd(e.target.checked ? true : false);
  };

  const GetAcctHoldinData = async () => {
    setLoading(true);
    setEnableCombo(true);
    let token = tempToken;

    // let token = JSON.parse(localStorage.getItem('token'));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let AsofDt = formatDate(asOfDt, "MM/dd/yyyy");

    // if (!Moment(AsofDt, 'MM-dd-yyyy').isValid()  || !Moment(priceDt, 'MM-dd-yyyy').isValid())
    // {
    //
    // }

    let RltnshpCd = "";

    let mConsolidationId = 0;

    let EffectiveDtInd = effectivecDtInd; //
    let PriceDt = formatDate(priceDt, "MM/dd/yyyy");

    //let pageId = 1;
    // const postData = {UserId, AcctId, AsofDt, EffectiveDtInd, PriceDt};
    const postData = {
      UserId,
      AcctId,
      AsofDt,
      EffectiveDtInd,
      PriceDt,
      mConsolidationId,
      RltnshpCd,
    };

    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/AcctHolding", postData, config)
      .then((response) => {
        //  console.log(response);

        const rowData = response.data;
        populateAcctHoldingRptData(rowData.ocAcctHolding);
        //localStorage.removeItem("HoldingDataState");
        localStorage.setItem(
          "filterData",
          JSON.stringify(rowData.ocAcctHolding)
        );
        setReportData(rowData);
        getColumnStateDb();
        setLoading(false);
        setEnableCombo(false);
        // if(priceDt===""){
        //   setpriceDt("mm/dd/yyyy");
        // }
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };
  const formSubmit = (event) => {
    GetAcctHoldinData();
  };

  const handleChange = (event) => {
    if (event.target.value === null || loading) {
      //SetselAcct('');
      SetselAcct(selAcct);
      // GetAcctProfileData();
      // GetUpdatedAccountProfile(0);
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      GetAcctHoldinData();
    }
  };

  const getColumnStateDb = async () => {
    //Storing column settings in DB

    let token = JSON.parse(localStorage.getItem("token"));
    let UserId = JSON.parse(localStorage.getItem("userId"));
    let GridId = 22;

    const postData = { UserId, GridId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/RTGetGridColumn/Index", postData, config)
      .then((response) => {
        console.log("save in db");
        console.log(response);
        const rowData = response.data;

        if (rowData !== "") {
          setIsColumnSave(true);
          localStorage.setItem("gridColumns", rowData);
        } else {
          setIsColumnSave(false);
        }
      })
      .catch((error) => {
        console.log("error in save db " + error);

        return error;
      });
  };

  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        GetAcctHoldinData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };

  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Header></Header>
            <div className="my-1">
              {/* <div className="rounded"></div> */}
              <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
                <div className="col-md-4 col-lg-5 col-sm-11 text-start">
                  <span className="px-2">Account(s)</span>
                  <ComboBox
                    style={{
                      width: "350px",
                    }}
                    data={selAcctData}
                    textField="extrnlAcctId"
                    dataItemKey="acctId"
                    filterable={true}
                    value={selAcct}
                    onChange={handleChange}
                    disabled={enableCombo}
                    // onFilterChange={filterChange}
                  />
                </div>
              </div>
            </div>
            <Loading />
          </div>
        )}
      </>
    );
  }
  const datepattern = "mm/dd/yyyy";
  return (
    <div>
      <Header></Header>
      <div className="my-1">
        {/* <div className="rounded"></div> */}
        <div className="row d-flex justify-content-between align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
          <div className="col-md-4 col-lg-5 col-sm-11 text-start">
            <span className="px-2">Account(s)</span>
            <ComboBox
              style={{
                width: "350px",
              }}
              data={selAcctData}
              textField="extrnlAcctId"
              dataItemKey="acctId"
              filterable={true}
              value={selAcct}
              onChange={handleChange}
              disabled={enableCombo}
              // onFilterChange={filterChange}
            />
          </div>
        </div>
      </div>

      <div className="fs-6 row mt-2 mx-2 px-1 reportheading mb-2">
        Holdings Report
      </div>
      <form onSubmit={formSubmit}>
        <div className="row d-flex justify-content-between align-items-center mx-2 px-1 py-2">
          <div className="col-md-2 col-lg-3 col-sm-10">
            <span className="py-1">As Of Date</span>
            <DatePicker
              id="dpAsOfDt"
              defaultValue={asOfDt}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              //  format={{
              //   skeleton: "MMddyyyy",
              // }}
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
              //formatPlaceholder="datepattern"
              //placeholder="mm/dd/yyyy"
              // min={minFrmDt}
              max={maxFrmDt}
              //disabled={isDisabled}
              onChange={(e) => {
                setDate(e.value);
              }}
            />
          </div>

          <div className="col-md-2 col-lg-3 col-sm-10">
            <span className="py-1">Price Date</span>
            <DatePicker
              //disabled={true}
              title="mm/dd/yyyy"
              value={priceDt}
              format="MM/dd/yyyy"
              calendar={CustomCalendar}
              max={maxFrmDt}
              onChange={(e) => {
                setPrcDt(e.value);
              }}
              formatPlaceholder={{
                year: "yyyy",
                month: "mm",
                day: "dd",
              }}
            />
          </div>
          <div className="col-md-2 col-lg-3 col-sm-10">
            <div className="subheader text-end col-md-10">
              <label className="form-check-label">
                Effective Date Indicator
              </label>
              &nbsp;&nbsp;
              <input
                className="form-check-input"
                type="checkbox"
                name="chkInvTrgMix"
                checked={effectivecDtInd}
                onChange={handleEffectiveDtInd}
              ></input>
            </div>
          </div>
          <div className="col-md-2 col-lg-2 col-sm-10">
            <input
              type="submit"
              className="btn btn-primary w-75"
              value="Submit"
            ></input>
          </div>
        </div>
      </form>
      <AcctHoldingGrid
        data={AcctHoldingRptData}
        flag={isColumnSave}
        reportData={reportData}
      />
    </div>
  );
};

export default AcctHoldingRpt;
