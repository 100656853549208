import React from "react";
import { useState, useEffect } from "react";
import axios from "axios";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import SelectControl from "./selectcontrol";
import { filterBy } from "@progress/kendo-data-query";

import Enumerable from "linq";
import Loading from "./loading";
import Header from "./header";
import FixedIncomePortfolioOverviewGrid from "./fixedIncomePortfolioOverviewGrid";
// import "@progress/kendo-theme-material/dist/all.css";
import {
  AutoComplete,
  ComboBox,
  MultiColumnComboBox,
  DropDownList,
  MultiSelect,
  DropDownTree,
} from "@progress/kendo-react-dropdowns";
//import "@progress/kendo-theme-default/dist/all.css";
import data from "./selectcontrol";
import Modal from "react-bootstrap/Modal";
import { FcExpired } from "react-icons/fc";
import { useLocation, useNavigate } from "react-router-dom";
const FixedIncomePortfolioOverviewRpt = () => {
  const [table1, setTableChart1] = useState([]);
  const [table2, setTableChart2] = useState([]);
  const [table3, setTableChart3] = useState([]);
  const [table4, setTableChart4] = useState([]);
  const [table5, setTableChart5] = useState([]);

  const [selAcctData, setSelAcctData] = useState(
    JSON.parse(localStorage.getItem("acctData")).slice()
  );
  const [selAcct, SetselAcct] = useState(
    JSON.parse(localStorage.getItem("AcctSelected"))
  );

  const [
    FixedIncomePortfolioOverviewRptData,
    populateFixedIncomePortfolioOverviewRptData,
  ] = useState([]);
  const [loading, setLoading] = useState(true);
  const [enableCombo, setEnableCombo] = useState(false);
  var tempToken = JSON.parse(localStorage.getItem("token"));
  const [session, setSession] = useState("");
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setEnableCombo(true);
      try {
        //let data = location.state;

        let AsOfId = JSON.parse(localStorage.getItem("userId")); // data.Email;

        //setEmail(email);
        FixedIncomePortfolioOverviewData();

        //  console.log(data);
      } catch (error) {
        console.error(error.message);
      }
    };
    fetchData();
  }, []);

  const filterData = (filter) => {
    // const dataAcct = selAcctData.slice();
    return filterBy(
      JSON.parse(localStorage.getItem("acctData")).slice(),
      filter
    );
  };

  const filterChange = (event) => {
    setSelAcctData(filterData(event.filter));
  };
  const handleChange = (event) => {
    if (event.target.value === null) {
      SetselAcct(JSON.parse(localStorage.getItem("acctData"))[0]);
      //GetUpdatedAccountProfile(0);
      localStorage.setItem("IsAcctSelected", false);
      localStorage.setItem(
        "AcctSelected",
        JSON.stringify(JSON.parse(localStorage.getItem("acctData"))[0])
      );
    } else {
      SetselAcct(event.target.value);
      localStorage.setItem("IsAcctSelected", true);
      localStorage.setItem("AcctSelected", JSON.stringify(event.target.value));
      console.log(event.target.value);

      FixedIncomePortfolioOverviewData(event.target.value.acctId);
    }
  };
  const refreshToken = async () => {
    let token = JSON.parse(localStorage.getItem("token"));
    let AccessToken = token.token;
    let RefreshToken = token.refreshToken;
    const postData = { AccessToken, RefreshToken };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    await axios
      .post("/token/Refresh", postData)
      .then((response) => {
        tempToken = response.data;
        localStorage.setItem("token", JSON.stringify(response.data));

        FixedIncomePortfolioOverviewData();
      })
      .catch((error) => {
        //
        if (error.code === "ERR_BAD_REQUEST") {
          setSession("Session Expired");
        }
        console.log("my error is " + error);
      });
  };
  const signOut = () => {
    navigate("/");
    let token = JSON.parse(localStorage.getItem("token"));
    const postData = {};
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    axios
      .post("/token/revoke", postData, config)
      .then((response) => {
        //
        // localStorage.setItem('token', '');
        //console.log(response);
        // navigate("/");
        // if (response.statusText === '') {
        // }
      })
      .catch((error) => {
        //
        console.log("my error is " + error);
      });

    // let tokenNew={token:''};
    // localStorage.setItem('token',JSON.stringify(tokenNew));
    // localStorage.setItem("AcctSelected",null);
    localStorage.removeItem("token");
    localStorage.clear();
    //firebaseApp.auth.signOut();
  };
  const FixedIncomePortfolioOverviewData = async (acctId) => {
    setLoading(true);
    setEnableCombo(true);

    let token = JSON.parse(localStorage.getItem("token"));
    let AsOfId = JSON.parse(localStorage.getItem("userId"));
    let AcctId = JSON.parse(localStorage.getItem("AcctSelected")).acctId;
    let pageId = 1;
    console.log("AsOfId", AsOfId);
    console.log("AcctId", AcctId);
    console.log("pageId", AsOfId);
    //  AsOfId=1744;
    const postData = { AsOfId, AcctId, pageId };
    const config = {
      headers: {
        authorization: `Bearer ${token.token}`,
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    console.log("authorization", token.token);
    // console.log("Accept", application);
    // console.log("Content-Type", pageId);
    await axios
      .post("/FixedIncomePortfolioOverview", postData, config)
      .then((response) => {
        //  console.log(response);
        debugger;
        console.log("config", config);

        const rowData = response.data;
        populateFixedIncomePortfolioOverviewRptData(rowData);

        setLoading(false);
        setEnableCombo(false);
      })
      .catch((error) => {
        if (error.response.status === 401) {
          refreshToken();
        }
        return error;
      });
  };

  if (loading) {
    return (
      <>
        {session === "Session Expired" ? (
          <Modal show={true} fullscreen={false} size="sm">
            <Modal.Body>
              <div className="row d-flex justify-content-center align-items-center">
                <>
                  <FcExpired size={30}></FcExpired>Your session has expired !
                </>
                <div className="row d-flex justify-content-center align-items-center">
                  Please login again.
                </div>
                <p></p>
                <a
                  className="row d-flex justify-content-center align-items-center btn btn-primary btn-sm"
                  style={{
                    outlineColor: "black",
                    borderBlockColor: "black",
                    backgroundColor: "#0099ff",
                    width: "50px",
                  }}
                  onClick={signOut}
                >
                  OK
                </a>
              </div>
            </Modal.Body>
          </Modal>
        ) : (
          <div>
            <Header></Header>
            <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
              <div className="subheader text-end col-md-1">
                {" "}
                &nbsp; Account(s):
              </div>
              <div className="col-md-4 text-start">
                <ComboBox
                  style={{
                    width: "350px",
                  }}
                  data={selAcctData}
                  textField="extrnlAcctId"
                  dataItemKey="acctId"
                  filterable={true}
                  value={selAcct}
                  onChange={handleChange}
                  onFilterChange={filterChange}
                  disabled={enableCombo}
                />
              </div>
              {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
            </div>
            <Loading />
          </div>
        )}
      </>
    );
  }

  return (
    <div style={{ overflow: "hidden" }}>
      <Header></Header>
      <div className="row d-flex justify-content-start align-items-center py-2 mt-1 px-2 mx-2 bg-light shadow-sm rounded">
        <div className="subheader text-end col-md-1"> &nbsp; Account(s):</div>
        <div className="col-md-4 text-start">
          <ComboBox
            style={{
              width: "350px",
            }}
            data={selAcctData}
            textField="extrnlAcctId"
            dataItemKey="acctId"
            filterable={true}
            value={selAcct}
            onChange={handleChange}
            onFilterChange={filterChange}
            disabled={enableCombo}
          />
        </div>
        {/* <div className='col-md-2'>
            <button className='btn btn-sm btn-outline-secondary px-2' ><FaPrint></FaPrint> &nbsp; Export to PDF</button>
          </div> */}
      </div>

      <FixedIncomePortfolioOverviewGrid
        data={FixedIncomePortfolioOverviewRptData}
      />
    </div>
  );
};
export default FixedIncomePortfolioOverviewRpt;
